import React from "react";
import { FilePlus, Grid, List, PlusSquare, Trash2 } from "react-feather";
import { Link } from "react-router-dom";
import ModelManager from "../../utils/ModelManager";
import { ModelInterface, Models } from "../../utils/Types";
import styles from "./RSelectPage.module.css";
// Images
import logoImage from '../../images/logo.png';
import hammerGuy from '../../images/RBXHammerGuy.png';
import nerfGirl from '../../images/NerfGunGirl.png';
import tridentGuy from '../../images/RBXTridentGuy.png';
import drone from '../../images/Drone.png';
import memoji from '../../images/Memoji.png';
import plunger from '../../images/Plunger.png';
import { WithRouterProps } from "../../utils/WithRouter";

interface RSelectPageProps {
    router: WithRouterProps;
    isAdmin: boolean;
};

interface RSelectPageState {
    /** Is models list loading */
    loading: boolean;
    /** Is there an error */
    error: boolean;
    /** Style of model items */
    isGrid: boolean;
};

export default class RSelectPage extends React.Component<RSelectPageProps, RSelectPageState> {

    state = {
        loading: true,
        error: false,
        isGrid: false
    };

    componentDidMount(){
        const manager = ModelManager.instance();
        manager.on("reload-finish", this.onModelsReloaded);
        manager.on("reload-start", this.onModelsReloading);
        if (!manager.isBusy) { manager.reload(); }
    }
    
    componentWillUnmount(): void {
        const manager = ModelManager.instance();
        manager.off("reload-finish", this.onModelsReloaded);
        manager.off("reload-start", this.onModelsReloading);
    }

    onModelsReloading = () => {
        this.setState({
            loading: true,
            error: false
        });
    };

    onModelsReloaded = () => {
        const manager = ModelManager.instance();
        this.setState({
            loading: false,
            error: manager.isError
        })
    };

    render(){
        return (
            <div className={styles.ModelSelectPage}>
                {this.titleBar()}
                <div className={styles.Content}>
                    {this.banner()}
                    <div className={styles.ModelsPanel}>
                        <div></div>
                        {this.viewSelectButtons()}
                    </div>
                    <div className={styles.Models}>
                        { this.state.isGrid 
                        ? this.modelsGrid()
                        : this.modelsList() }
                    </div>
                </div>
            </div>
        );
    }
    /** Loading message */
    loadingMessage(){
        if (!this.state.loading) { return; }
        return (
            <div className={styles.LoadingMessage}>
                Loading available models...
            </div>
        );
    }
    /** Error message */
    errorMessage(){
        if (!this.state.error) { return; }
        return (
            <div className={styles.ErrorMessage}>
                Failed to load list of models. 
                Check console for more information.
            </div>
        );
    }
    /** Action message */
    actionMessage(models: boolean){
        if (this.state.loading || this.state.error) { return; }
        return (
            <div className={styles.ActionMessage}>
                { models
                ? `Select model to edit:`
                : `No available models found` }
            </div>
        );
    }
    /** Title bar */
    titleBar(){
        const models = ModelManager.instance().models;
        return (
            <div className={styles.TitleBar}>
                <div className={styles.TitleBarLeft}>
                    <div className={styles.TitleBarLogo}>
                        <img 
                            src={logoImage}
                            />
                    </div>
                    <div>{this.adminButtons()}</div>
                </div>
                <div></div>
                {/* {this.loadingMessage()} */}
                {/* {this.errorMessage()} */}
                {/* {this.actionMessage(models.length > 0)} */}
                {/* <div></div> */}
                {/* {this.viewSelectButtons()} */}
            </div>
        );
    }
    banner(){
        return (
            <div className={styles.Banner}>
                <img className={styles.HammerGuy} 
                    src={hammerGuy}
                    />
                <img className={styles.NerfGirl}
                    src={nerfGirl}
                    />
                <img className={styles.TridentGuy}
                    src={tridentGuy}
                    />

                <div className={styles.BannerText}>
                    Create Models for Roblox in <br/>
                    Seconds Your Drag and Drop 3D Editor for Roblox and beyond.
                </div>

                <div className={styles.BannerBack}>
                    <img className={styles.Drone}
                        src={drone}
                        />
                    <img className={styles.Memoji}
                        src={memoji}
                        />
                    <img className={styles.Plunger}
                        src={plunger}
                        />
                </div>
            </div>
        );
    }
    /** Add new model */
    adminButtons(){
        if (!this.props.isAdmin) { return; }
        return (
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <Link to={'/add'} style={{ textDecoration: 'none', color: 'inherit', borderColor: 'inherit' }}>
                    <div className={styles.AddModelButton}>
                        <FilePlus width={30} height={30} />
                        <u>Add model</u>
                    </div>
                </Link>
                <Link to={'/stickers'} style={{ textDecoration: 'none', color: 'inherit', borderColor: 'inherit' }}>
                    <div className={styles.AddModelButton}>
                        <PlusSquare width={30} height={30} />
                        <u>Stickers</u>
                    </div>
                </Link>
            </div>
        );
    }
    /** Change view of models list */
    viewSelectButtons(){
        return (
            <div className={styles.ViewButtons}>
                <div className={`${styles.IconButton} ${this.state.isGrid ? '' : styles.IconButtonSelected}`}
                    onClick={()=>{this.setState({isGrid: false})}}>
                    <List width={20} height={20} />
                </div>
                <div className={`${styles.IconButton} ${this.state.isGrid ? styles.IconButtonSelected : ''}`}
                    onClick={()=>{this.setState({isGrid: true})}}>
                    <Grid width={20} height={20} />
                </div>
            </div>
        );
    }
    /** Models list */
    modelsList(){
        if (this.state.loading || this.state.error) { return; }
        const models = ModelManager.instance().models;
        return (
            <div className={styles.ModelsList}>
                {models.map((model, index) => 
                    <ModelsListItem 
                        key={'model-'+index}
                        model={model}
                        router={this.props.router}
                        canDelete={this.props.isAdmin && model.id !== undefined}
                        canChange={this.props.isAdmin && model.id !== undefined}
                        />
                )}
            </div>
        );
    }
    /** Models grid */
    modelsGrid(){
        if (this.state.loading || this.state.error) { return; }
        const models = ModelManager.instance().models;
        return (
            <div className={styles.ModelsGrid}>
                {models.map((model, index) =>
                    <ModelsGridItem 
                        key={'model-'+index}
                        model={model}
                        router={this.props.router}
                        canDelete={this.props.isAdmin && model.id !== undefined}
                        canChange={this.props.isAdmin && model.id !== undefined}
                        />
                )}
            </div>
        );
    }
}

interface ModelsItemProps {
    /** Model information for item */
    model: ModelInterface;
    router?: WithRouterProps;
    canDelete?: boolean;
    canChange?: boolean;
};

class ModelsListItem extends React.Component<ModelsItemProps> {
    onDelete = async () => {
        const result = window.confirm(`Are you sure you want to delete model "${this.props.model.name}"?`);

        if (result) {
            const manager = ModelManager.instance();
            manager.deleteModel(this.props.model.id!);
        } else {
            console.log("Deletion canceled.");
        }
    };

    render(): React.ReactNode {
        const id = this.props.model.id ?? -1;
        return (
            <div 
                className={styles.ModelsListItem}
                >
                <Link to={`/edit/${id}`} style={{ textDecoration: 'none', color: 'inherit', borderColor: 'inherit' }}>
                    <div className={styles.ListItem}>
                        <div className={styles.ListPreview}>
                            {this.props.model.preview
                            ? <img 
                                className={styles.ListPreviewImage} 
                                src={this.props.model.preview} 
                                alt={'Preview image'} 
                                />
                            : `No preview image`}
                        </div>
                        <div className={styles.ListInfo}>
                            <div className={styles.ListName}>
                                {`${this.props.model.name}`}
                            </div>
                            <div className={styles.ListLikes}>
                                {/* likes and remixes */}
                                { this.props.canChange && 
                                    <div className={styles.ListChangeButton}
                                    onClick={(e)=>{
                                            e.preventDefault();
                                            this.props.router?.navigate(`/change/${id}`);
                                        }}
                                    >
                                    <u>Change</u>
                                    </div>}
                                { this.props.canDelete &&
                                <div className={styles.ListDeleteButton}
                                    onClick={(e)=>{e.preventDefault(); this.onDelete();}}
                                    >
                                    <Trash2 width={16} height={16} />
                                    <u>Delete</u>
                                </div> }
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        );
    }
}

class ModelsGridItem extends React.Component<ModelsItemProps> {
    onDelete = async () => {
        const result = window.confirm(`Are you sure you want to delete model "${this.props.model.name}"?`);

        if (result) {
            const manager = ModelManager.instance();
            manager.deleteModel(this.props.model.id!);
        } else {
            console.log("Deletion canceled.");
        }
    };

    render(){
        const id = this.props.model.id ?? -1;
        return (
            <Link to={`/edit/${id}`} style={{ textDecoration: 'none', color: 'inherit', borderColor: "inherit" }}>
                <div 
                    className={styles.ModelsGridItem}
                    >
                    <div className={styles.GridPreview}>
                        {this.props.model.preview
                        ? <img 
                            className={styles.GridPreviewImage} 
                            src={this.props.model.preview} 
                            alt={'Preview image'} 
                            />
                        : `No preview image`}
                    </div>
                    <div className={styles.GridInfo}>
                        <div className={styles.GridName}>{this.props.model.name}</div>
                        <div className={styles.GridLikes}>
                            {/* likes and remixes */}
                            { this.props.canChange && 
                                <div className={styles.ListChangeButton}
                                onClick={(e)=>{
                                        e.preventDefault();
                                        this.props.router?.navigate(`/change/${id}`);
                                    }}
                                >
                                <u>Change</u>
                                </div>}
                            { this.props.canDelete &&
                            <div className={styles.ListDeleteButton}
                                onClick={(e)=>{e.preventDefault(); this.onDelete();}}
                                >
                                <Trash2 width={16} height={16} />
                                <u>Delete</u>
                            </div> }
                        </div>
                    </div>
                </div>
            </Link>
        );
    }
}