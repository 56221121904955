import React from "react";
import { DrawStyle, drawStyleToString, fonts, FontStyle, stringToDrawStyle, stringToTextAlign, TextAlign, textAlignToString, TextStyleConfig } from "../../2d/TextStyle";
import styles from "./TextConfigurator.module.css";
import { AlignCenter, AlignLeft, AlignRight, Bold, CheckSquare, ChevronDown, Italic, Square } from "react-feather";
import { HexColorPicker } from "react-colorful";

interface TextConfiguratorProps {
    text: string;
    onTextUpdate: (text: string) => void;
    textStyle: TextStyleConfig;
    onTextStyleUpdate: (config: TextStyleConfig) => void;
    onChange?: () => void;
}

interface TextConfiguratorState {
    text: string;
    font: string;
    color: string;
    background: string;
    isBackground: boolean;
    drawStyle: string;
    lineWidth: number;
    size: number;
    isBold: boolean;
    isItalic: boolean;
    textAlign: string;
}

export default class TextConfigurator extends React.Component<TextConfiguratorProps, TextConfiguratorState> {
    static create(props: TextConfiguratorProps){
        return (
            <TextConfigurator 
                text={props.text}
                onTextUpdate={props.onTextUpdate}
                textStyle={props.textStyle}
                onTextStyleUpdate={props.onTextStyleUpdate}
                onChange={props.onChange}
                />
        );
    }

    state = {
        text: this.props.text,
        font: this.props.textStyle.font!,
        color: this.props.textStyle.color!,
        background: this.props.textStyle.background || "#ffffff",
        isBackground: !!this.props.textStyle.background,
        drawStyle: drawStyleToString(this.props.textStyle.drawStyle!),
        lineWidth: this.props.textStyle.lineWidth!,
        size: this.props.textStyle.size!,
        isBold: (this.props.textStyle.fontStyle === FontStyle.bold || this.props.textStyle.fontStyle === FontStyle.bolditalic),
        isItalic: (this.props.textStyle.fontStyle === FontStyle.italic || this.props.textStyle.fontStyle === FontStyle.bolditalic),
        textAlign: textAlignToString(this.props.textStyle.textAlign!),
    };

    componentDidUpdate(prevProps: Readonly<TextConfiguratorProps>, prevState: Readonly<TextConfiguratorState>, snapshot?: any): void {
        if (this.props.textStyle !== prevProps.textStyle) {
            this.state.text = this.props.text;
            this.state.font = this.props.textStyle.font!;
            this.state.color = this.props.textStyle.color!;
            this.state.background = this.props.textStyle.background || prevState.background;
            this.state.isBackground = !!this.props.textStyle.background;
            this.state.drawStyle = drawStyleToString(this.props.textStyle.drawStyle!);
            this.state.lineWidth = this.props.textStyle.lineWidth!;
            this.state.size = this.props.textStyle.size!;
            this.state.isBold = (this.props.textStyle.fontStyle === FontStyle.bold || this.props.textStyle.fontStyle === FontStyle.bolditalic);
            this.state.isItalic = (this.props.textStyle.fontStyle === FontStyle.italic || this.props.textStyle.fontStyle === FontStyle.bolditalic);
            this.state.textAlign = textAlignToString(this.props.textStyle.textAlign!);
            this.forceUpdate();
        } else if (this.props.text !== prevProps.text) {
            this.state.text = this.props.text;
            this.forceUpdate();
        }
        if (this.state.text !== prevState.text) {
            this.props.onTextUpdate(this.state.text);
        } else {
            if (
                prevState.background === this.state.background &&
                prevState.color === this.state.color &&
                prevState.drawStyle === this.state.drawStyle &&
                prevState.font === this.state.font &&
                prevState.isBackground === this.state.isBackground &&
                prevState.isBold === this.state.isBold &&
                prevState.isItalic === this.state.isItalic &&
                prevState.lineWidth === this.state.lineWidth &&
                prevState.size === this.state.size &&
                prevState.textAlign === this.state.textAlign
            ) { return; }
            let fontStyle = FontStyle.normal;
            if (this.state.isBold && this.state.isItalic) {
                fontStyle = FontStyle.bolditalic;
            } else if (this.state.isBold) {
                fontStyle = FontStyle.bold;
            } else if (this.state.isItalic) {
                fontStyle = FontStyle.italic;
            }

            const textStyle: TextStyleConfig = {
                size: this.state.size,
                font: this.state.font,
                color: this.state.color,
                background: this.state.isBackground ? this.state.background : "",
                drawStyle: stringToDrawStyle(this.state.drawStyle),
                lineWidth: this.state.lineWidth,
                fontStyle,
                textAlign: stringToTextAlign(this.state.textAlign)
            };
            this.props.onTextStyleUpdate(textStyle);
        }
    }

    save = () => {
        this.props.onChange?.();
    };

    render(){
        return (
            <div className={styles.Container}>
                <div className={styles.Title}>
                    Text
                </div>
                <div className={styles.Row}>
                    Font:
                    <div className={styles.Group}>
                        {this.fontSelect()}
                    </div>
                </div>
                <div className={styles.Row}>
                    Color:
                </div>
                {this.fontColor()}
                <div className={styles.Row}>
                    Size:
                    {this.fontSize()}
                </div>
                <div className={`${styles.Row} ${styles.Wrap}`}>
                    <div className={styles.Row}>
                        Style: 
                        {this.fontStyle()}
                    </div>
                    <div className={styles.Row}>
                        Align: 
                        <div className={styles.Group}>
                            {this.textAlign()}
                        </div>
                    </div>
                </div>
                <div className={styles.Row}>
                    Draw style:
                    {this.drawStyle()}
                </div>
                {this.state.drawStyle === 'stroke' && this.lineWidth()}
                <div className={styles.Row}>
                    Background:
                    {this.background()}
                </div>
                {this.state.isBackground && this.backgroundColor()}
                <textarea 
                    className={styles.TextArea}
                    rows={7}
                    value={this.state.text}
                    onChange={(e)=>{this.setState({text: e.target.value})}}
                    onBlur={this.save}
                    />
            </div>
        );
    }

    lineWidth(){
        return (
            <div className={styles.Row}>
                <div style={{width: "50%"}}>
                    Line width:
                </div>
                <div className={styles.Slide}>
                    <input
                        className={styles.SlideInput}
                        type="range"
                        value={this.state.lineWidth}
                        min={1}
                        max={Math.ceil(this.state.size / 10)}
                        onChange={(e)=>{this.setState({lineWidth: e.target.valueAsNumber})}}
                        onMouseUp={this.save}
                        />
                </div>
                <input 
                    className={styles.NumberInput}
                    type="number" 
                    value={this.state.lineWidth}
                    min={1}
                    max={Math.ceil(this.state.size / 10)}
                    onChange={(e)=>{this.setState({lineWidth: e.target.valueAsNumber})}}
                    onBlur={this.save}
                    />
            </div>
        );
    }

    drawStyle(){
        return (
            <div className={styles.Select}>
                <ChevronDown className={styles.SelectArrow} width={20} height={20} />
                <select
                    value={this.state.drawStyle}
                    onChange={(e)=>{this.setState({drawStyle: e.target.value}, this.save)}}
                    >
                    <option value={'fill'}>Fill</option>
                    <option value={'stroke'}>Stroke</option>
                </select>
            </div>
        );
    }

    fontSelect(){
        return (
            <div className={styles.Select}>
                <ChevronDown className={styles.SelectArrow} width={20} height={20} />
                <select
                    value={this.state.font}
                    onChange={(e)=>{this.setState({font: e.target.value}, this.save)}}
                    >
                    {fonts.map((name)=><option key={name} value={name}>{name}</option>)}
                </select>
            </div>
        );
    }

    fontSize(){
        return (
            <>
            {/* <div className={styles.Group}> */}
                <div className={styles.Slide}>
                    <input
                        className={styles.SlideInput}
                        type="range"
                        value={this.state.size}
                        min={12}
                        max={1024}
                        onChange={(e)=>{this.setState({size: e.target.valueAsNumber})}}
                        onMouseUp={this.save}
                        />
                </div>
                <input 
                    className={styles.NumberInput}
                    type="number" 
                    value={this.state.size}
                    min={12}
                    max={1024}
                    onChange={(e)=>{this.setState({size: e.target.valueAsNumber})}}
                    onBlur={this.save}
                    />
            {/* </div> */}
            </>
        );
    }

    fontStyle(){
        const selected = styles.IconButtonSelected;

        return (
            <div className={styles.Group}>
                <div className={`${styles.IconButton} ${this.state.isBold ? selected : ''}`}
                    title="Bold"
                    onClick={()=>{this.setState({isBold: !this.state.isBold}, this.save)}}
                    >
                    <Bold width={20} />
                </div>
                <div className={`${styles.IconButton} ${this.state.isItalic ? selected : ''}`}
                    title="Italic"
                    onClick={()=>{this.setState({isItalic: !this.state.isItalic}, this.save)}}
                    >
                    <Italic width={20} />
                </div>
            </div>
        );
    }

    textAlign(){
        const selected = styles.IconButtonSelected;

        return (<>
            <div className={`${styles.IconButton} ${this.state.textAlign === 'left' ? selected: ''}`}
                title="Left"
                onClick={()=>{this.setState({textAlign: 'left'}, this.save)}}
                >
                <AlignLeft width={20} />
            </div>
            <div className={`${styles.IconButton} ${this.state.textAlign === 'center' ? selected: ''}`}
                title="Center"
                onClick={()=>{this.setState({textAlign: 'center'}, this.save)}}
                >
                <AlignCenter width={20} />
            </div>
            <div className={`${styles.IconButton} ${this.state.textAlign === 'right' ? selected: ''}`}
                title="Right"
                onClick={()=>{this.setState({textAlign: 'right'}, this.save)}}
                >
                <AlignRight width={20} />
            </div>
        </>);
    }

    fontColor(){
        return (
            // <input
            //     type="color"
            //     value={this.state.color}
            //     onChange={(e)=>{this.setState({color: e.target.value})}}
            //     onBlur={this.save}
            //     />
            <>
            <HexColorPicker style={{ width: "auto", height: "146px" }}
                color={this.state.color} 
                onChange={(color) => {this.setState({color})}} 
                />
            <div className={styles.Row}>
                <div className={styles.HexName}>
                    HEX
                </div>
                <input className={`${styles.Input} ${styles.HexInput}`} 
                    value={this.state.color}
                    onChange={(e)=>{this.setState({color: e.target.value})}}
                    maxLength={7}
                    />
            </div>
            </>
        );
    }

    background(){
        return (
            <div className={styles.Group}>
                {/* <div className={styles.IconCheckbox}
                    onClick={()=>{this.setState({isBackground: !this.state.isBackground}, this.save)}}
                    >
                    {this.state.isBackground ? <CheckSquare width={20} /> : <Square width={20} />}
                </div> */}
                <label className={styles.Switch}>
                    <input type="checkbox" 
                        checked={this.state.isBackground}
                        onChange={(e) => { this.setState({isBackground: e.target.checked}, this.save); }}
                        />
                    <span className={styles.Slider}></span>
                </label>
                {/* <input
                    type="color"
                    defaultValue={this.state.background}
                    onChange={(e)=>{this.setState({background: e.target.value})}}
                    onBlur={this.save}
                    /> */}
            </div>
        );
    }

    backgroundColor(){
        return (
            <>
            <HexColorPicker style={{ width: "auto", height: "146px" }}
                color={this.state.background} 
                onChange={(color) => {this.setState({background: color})}} 
                />
            <div className={styles.Row}>
                <div className={styles.HexName}>
                    HEX
                </div>
                <input className={`${styles.Input} ${styles.HexInput}`} 
                    value={this.state.background}
                    onChange={(e)=>{this.setState({background: e.target.value})}}
                    maxLength={7}
                    />
            </div>
            </>
        );
    }
}