import TextStyle, { TextStyleConfig } from "../../2d/TextStyle";
import { PointerPoint } from "../../utils/Types";
import DrawableElement from "../DrawableElement";
import EditInstrument from "./EditInstrument";
import TextConfigurator from "./TextConfigurator";

export default class TextInstrument extends EditInstrument {

    onTextStyleChange = (config: TextStyleConfig)=>{
        if (!this.drawable?.isText()) { return; }
        this.drawable.textStyle!.update(config);
        this.drawable.setText(this.drawable.text!);
        this.onDrawableUpdate?.();
    };

    onTextChange = (text: string) => {
        if (!this.drawable?.isText()) { return; }
        this.drawable.setText(text);
        this.onDrawableUpdate?.();
    };

    onPointerDown(point: PointerPoint): void {}
    onPointerMove(point: PointerPoint): void {}
    onPointerUp(point: PointerPoint): void {}
    onPointerEnter(point: PointerPoint): void {}
    onPointerLeave(point: PointerPoint): void {}

    configurator(drawable?: DrawableElement<HTMLElement>): JSX.Element | null {
        if (!drawable || !drawable.isText()) { return null; }

        return TextConfigurator.create({
            text: drawable.text!,
            onTextUpdate: this.onTextChange,
            textStyle: drawable.textStyle!.config(),
            onTextStyleUpdate: this.onTextStyleChange,
            onChange: this.onOperationFinish
        });
    }
}