import React from "react";
import { Link } from "react-router-dom";
import styles from "./ChangePasswdPage.module.css";

interface ChangePasswdPageProps {
    serverUrl: string;
}

interface ChangePasswdPageState {
    login: string;
    password: string;
    newpassword: string;
};


export default class ChangePasswdPage extends React.Component<ChangePasswdPageProps, ChangePasswdPageState> {

    state = {
        login: '',
        password: '',
        newpassword: ''
    };

    onSubmit = async () => {
        const token = localStorage.getItem("token");
        if (!token) { return; }

        try {
            const response = await fetch(this.props.serverUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    username: this.state.login,
                    password: this.state.password,
                    newpassword: this.state.newpassword
                })
            });

            if (!response.ok) {
                const data = await response.text();

                console.log(response.status, data);
                return;
            }

            const data = await response.json();

            localStorage.setItem('token', data.token);

            window.location.href = '/';
        } catch (error) {
            console.log("Error: ", error);
        }
    };

    render(){
        return (
            <div className={styles.Container}>
                <form className={styles.Form}
                    onSubmit={(e)=>{e.preventDefault(); this.onSubmit();}}>
                <div className={styles.FormLogin}>
                    <label htmlFor="login">Login:</label>
                    <input
                        type="text"
                        id="login"
                        value={this.state.login}
                        onChange={(e)=>{this.setState({login: e.target.value})}}
                       />
                </div>
                <div className={styles.FormPassword}>
                    <label htmlFor="password">Password:</label>
                    <input
                    type="password"
                    id="password"
                    value={this.state.password}
                    onChange={(e)=>{this.setState({password: e.target.value})}}
                    />
                </div>
                <div className={styles.FormPassword}>
                    <label htmlFor="newpassword">New password:</label>
                    <input
                    type="password"
                    id="newpassword"
                    value={this.state.newpassword}
                    onChange={(e)=>{this.setState({newpassword: e.target.value})}}
                    />
                </div>
                <div className={styles.FormButtons}>
                    <button type="submit">Change</button>
                    <Link to="/"><button>Cancel</button></Link>
                </div>
                </form>
            </div>
        );
    }
}