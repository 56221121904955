import React from "react";
import { CheckCircle } from "react-feather";
import ClippingCanvas from "../../canvas/ClippingCanvas";
import { TextureInterface } from "../../utils/Types";
import styles from "./TemplateCategory.module.css";

interface TemplateCategoryProps {
    canvas: ClippingCanvas;
    templates: TextureInterface[];
};

interface TemplateCategoryState {
    template?: string;
};

export default class TemplateCategory extends React.Component<TemplateCategoryProps, TemplateCategoryState> {
    state = {
        template: this.props.canvas.getTemplate()
    };
    
    onTemplateSelect = (src?: string) => {
        this.props.canvas.setTemplate(src);
        this.setState({ template: src });
    };

    render(){
        return (
            <div className={styles.Category}>
                <div className={styles.Title}>
                    Select template
                </div>
                <div className={styles.Container}>
                    <TemplateItem 
                        onSelect={this.onTemplateSelect}
                        selected={!this.state.template}
                        />
                    {this.props.templates.map((tmp, index) => 
                        <TemplateItem 
                            key={`template-${index}`}
                            template={tmp}
                            onSelect={this.onTemplateSelect}
                            selected={this.state.template === tmp.url}
                            />
                    )}
                </div>
            </div>
        );
    }
}

class TemplateItem extends React.Component<
{
    template?: TextureInterface
    onSelect: (src?: string) => void;
    selected?: boolean;
}
> {
    render(){
        return (
            <div className={`${styles.Item} ${this.props.selected && styles.Selected}`}
                onClick={()=>{this.props.onSelect(this.props.template?.url)}}
                >
                {/* <div className={styles.ItemName}>
                    {this.props.template?.name || "None"}
                </div>  */}
                <div className={`${styles.Check} ${!this.props.selected && styles.NoCheck}`}>
                    <CheckCircle width={20} height={20} />
                </div>
                {this.props.template && <img 
                    className={styles.ItemImage}
                    src={this.props.template.url}
                    crossOrigin="anonymous"
                    />}
            </div>
        );
    }
}