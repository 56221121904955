import React from "react";
import { CheckSquare, Droplet, Square, XCircle } from "react-feather";
import styles from "./PencilConfigurator.module.css";
import { HexColorPicker } from "react-colorful";
import './ColorPicker.css';

interface PencilConfiguratorPros {
    color: string;
    onColorChange: (color: string) => void;
    size: number;
    onSizeChange: (size: number) => void;
    erase: boolean;
    onEraseChange: (erase: boolean) => void;
    onFill: () => void;
    onClear: () => void;
};

interface PencilConfiguratorState {
    color: string;
    size: number;
    erase: boolean;
};

export default class PencilConfigurator extends React.Component<PencilConfiguratorPros, PencilConfiguratorState> {
    static create(props: PencilConfiguratorPros) {
        return (
            <PencilConfigurator
                color={props.color}
                onColorChange={props.onColorChange}
                size={props.size}
                onSizeChange={props.onSizeChange}
                erase={props.erase}
                onEraseChange={props.onEraseChange}
                onFill={props.onFill}
                onClear={props.onClear}
                />
        );
    }

    state = {
        color: this.props.color,
        size: this.props.size,
        erase: this.props.erase
    };

    onColorChange = (color: string) => {
        this.setState({color});
        this.props.onColorChange(color);
    }

    onSizeChange = (size: number) => {
        this.setState({size});
        this.props.onSizeChange(size);
    }
    
    onEraseChange = (erase: boolean) => {
        this.setState({erase});
        this.props.onEraseChange(erase);
    };

    render(){
        return (
            <div className={styles.PencilConfigurator}>
                <div className={styles.Title}>
                    Pencil
                </div>
                <div className={styles.Row}>
                    Color: 
                    <div className={styles.Group}>
                        <div className={styles.Fill}
                            onClick={this.props.onFill}
                            >
                            Fill
                            <Droplet width={20} />
                        </div>
                        <div className={styles.Clear}
                            onClick={this.props.onClear}
                            >
                            Clear
                            <XCircle width={20} />
                        </div>
                    </div>
                </div>
                    {/* <input
                        type='color'
                        value={this.state.color}
                        onChange={(event) => {this.onColorChange(event.target.value)}}
                        /> */}
                <HexColorPicker style={{ width: "auto", height: "146px" }}
                    color={this.state.color} 
                    onChange={(color) => {this.onColorChange(color)}} 
                    />
                <div className={styles.Row}>
                    <div className={styles.HexName}>
                        HEX
                    </div>
                    <input className={`${styles.Input} ${styles.HexInput}`} 
                        value={this.state.color}
                        onChange={(e)=>{this.onColorChange(e.target.value)}}
                        maxLength={7}
                        />
                </div>
                <div className={styles.Row}>
                    Size: 
                    <div className={styles.Slide}>
                        <input 
                            className={styles.SlideInput}
                            type='range'
                            min={0}
                            max={300}
                            value={this.state.size}
                            onChange={(event) => {this.onSizeChange(event.target.valueAsNumber)}}
                            />
                    </div>
                    <input className={`${styles.Input} ${styles.SizeInput}`}
                        type='number'
                        min={1}
                        max={300}
                        value={this.state.size}
                        onChange={(event) => {this.onSizeChange(event.target.valueAsNumber)}}
                        />
                </div>
                <div className={styles.Row}>
                    Eraser:
                    {/* <div className={styles.IconCheckbox}
                        onClick={()=>{this.onEraseChange(!this.state.erase)}}
                        >
                        {this.state.erase ? <CheckSquare width={20} /> : <Square width={20} />}
                    </div> */}
                    <label className={styles.Switch}>
                        <input type="checkbox" 
                            checked={this.state.erase}
                            onChange={(e) => { this.onEraseChange(e.target.checked); }}
                            />
                        <span className={styles.Slider}></span>
                    </label>
                </div>
                
            </div>
        );
    }
}