export enum DrawStyle {
    fill, stroke
};

export function drawStyleToString(style: DrawStyle){
    switch (style) {
        case DrawStyle.stroke:
            return "stroke";
        case DrawStyle.fill:
        default:
            return "fill";
    }
}

export function stringToDrawStyle(style: string){
    switch (style.toLowerCase()) {
        case "stroke":
            return DrawStyle.stroke;
        case "fill":
        default:
            return DrawStyle.fill;
    }
}

export enum FontStyle {
    normal, bold, italic, bolditalic
};

export enum TextAlign {
    left, center, right
};

export function textAlignToString(align: TextAlign){
    switch (align) {
        case TextAlign.center:
            return 'center';
        case TextAlign.right:
            return 'right';
        case TextAlign.left:
        default:
            return 'left';
    }
}

export function stringToTextAlign(align: string){
    switch (align.toLowerCase()) {
        case 'center':
            return TextAlign.center;
        case 'right':
            return TextAlign.right;
        case 'left':
        default:
            return TextAlign.left;
    }
}

export const fonts = [
    "Arial",
    "Times New Roman",
    "Georgia",
    "Helvetica",
    "Verdana",
    "Tahoma",
    "Courier New",
    "Comic Sans MS",
    "Impact",
];


export interface TextStyleConfig {
    size?: number;
    font?: string;
    color?: string;
    background?: string;
    drawStyle?: DrawStyle;
    lineWidth?: number;
    fontStyle?: FontStyle;
    textAlign?: TextAlign;
};

const DEFAULT: TextStyleConfig = {
    size: 512,
    font: "Arial",
    color: '#000000',
    background: "",
    lineWidth: 10,
    drawStyle: DrawStyle.fill,
    fontStyle: FontStyle.normal,
    textAlign: TextAlign.left
};

class TextStyle {
    size: number;
    font: string;
    color: string;
    background: string;
    drawStyle: DrawStyle;
    lineWidth: number;
    fontStyle: FontStyle;
    textAlign: TextAlign;

    constructor(config: TextStyleConfig = {}) {
        this.size = config.size ?? DEFAULT.size!;
        this.font = config.font ?? DEFAULT.font!;
        this.color = config.color ?? DEFAULT.color!;
        this.background = config.background ?? DEFAULT.background!;
        this.drawStyle = config.drawStyle ?? DEFAULT.drawStyle!;
        this.lineWidth = config.lineWidth ?? DEFAULT.lineWidth!;
        this.fontStyle = config.fontStyle ?? DEFAULT.fontStyle!;
        this.textAlign = config.textAlign ?? DEFAULT.textAlign!;
    }

    update(config: TextStyleConfig) {
        this.size = config.size ?? this.size;
        this.font = config.font ?? this.font;
        this.color = config.color ?? this.color;
        this.background = config.background ?? this.background;
        this.drawStyle = config.drawStyle ?? this.drawStyle;
        this.lineWidth = config.lineWidth ?? DEFAULT.lineWidth!;
        this.fontStyle = config.fontStyle ?? this.fontStyle;
        this.textAlign = config.textAlign ?? this.textAlign;
    }

    config(): TextStyleConfig{
        return {
            size: this.size,
            font: this.font,
            color: this.color,
            background: this.background,
            lineWidth: this.lineWidth,
            drawStyle: this.drawStyle,
            fontStyle: this.fontStyle,
            textAlign: this.textAlign
        };
    }

    setStyleFor(context: CanvasRenderingContext2D) {
        let style = "";
        switch (this.fontStyle) {
            case FontStyle.bold:
                style = "bold ";
                break;
            case FontStyle.italic:
                style = "italic ";
                break;
            case FontStyle.bolditalic:
                style = "italic bold ";
                break;
        }
        context.font = `${style}${this.size}px ${this.font}`;
        context.lineWidth = this.lineWidth;
        switch (this.drawStyle) {
            case DrawStyle.fill:
                context.fillStyle = this.color;
                break;
            case DrawStyle.stroke:
                context.strokeStyle = this.color;
        }
        context.textAlign = textAlignToString(this.textAlign);
        context.textBaseline = "top";
    }
}

export default TextStyle;