import React, { createRef } from "react";
import { Anchor, Archive, ChevronLeft, Download, ExternalLink, Image, Layers, Maximize, Move, PenTool, Plus, RotateCcw, RotateCw, Type, XSquare, ZoomIn, ZoomOut } from "react-feather";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import Drawable from "../../2d/Drawable";
import InstanceView from "../../3d/InstanceView";
import ModelSceneInstance from "../../3d/ModelSceneInstance";
import ClippingCanvas from "../../canvas/ClippingCanvas";
import DrawableElement from "../../canvas/DrawableElement";
import EditInstrument from "../../canvas/instruments/EditInstrument";
import PencilInstrument from "../../canvas/instruments/PencilInstrument";
import TextInstrument from "../../canvas/instruments/TextInstrument";
import ImageCategory from "../../editor/categories/ImageCategory";
import StickersCategory from "../../editor/categories/StickersCategory";
import TemplateCategory from "../../editor/categories/TemplateCategory";
import TextCategory from "../../editor/categories/TextCategory";
import ExportOptions from "../../editor/ExportOptions";
import LayerConfigurator from "../../editor/LayerConfigurator";
import LayersController from "../../editor/LayersController";
import Popup from "../../ui/Popup";
import ModelManager from "../../utils/ModelManager";
import { ModelInterface, PartInterface } from "../../utils/Types";
import UndoRedo from "../../utils/UndoRedo";
import { WithRouterProps } from "../../utils/WithRouter";
import styles from "./REditorPage.module.css";
// Images
import logoImage from '../../images/logo.png';

enum Category {
    NONE,
    TEMPLATE,
    STICKER,
    IMAGE,
    TEXT
}

interface REditorPageProps {
    router: WithRouterProps;
};

interface REditorPageState {
    sceneMessage?: string;

    swap: boolean;

    overlay: boolean;
    transform: boolean;
    moving: boolean;
    
    layer: number;

    instrument?: EditInstrument;

    exporting: boolean;

    category: Category;

    canUndo: boolean;
    canRedo: boolean;
};

export default class REditorPage extends React.Component<REditorPageProps, REditorPageState> {
    id = parseInt(this.props.router.params.id ?? '-1');
    model: ModelInterface | null = null;

    canvas = new ClippingCanvas(4096, 4096);
    parts?: PartInterface[];
    sceneInstance = new ModelSceneInstance();
    instruments: EditInstrument[] = [
        new PencilInstrument(),
        new TextInstrument()
    ];
    undoredo?: UndoRedo;

    layerConfiguratorRef = createRef<LayerConfigurator>();
    drawableRef = createRef<Drawable>();
    
    state = {
        sceneMessage: "Loading...",
        swap: true,
        overlay: true,
        transform: false,
        moving: false,
        instrument: this.instruments[0],

        layer: 0,
        exporting: false,

        category: Category.TEMPLATE,

        canUndo: false,
        canRedo: false
    };

    async componentDidMount(){
        this.model = ModelManager.instance().getModelById(this.id);
        if (!this.model) {
            this.model = await ModelManager.instance().loadById(this.id);
        }
        if (!this.model) { 
            this.setState({
                sceneMessage: "Failed to load model from server"
            });
            return; 
        }

        // this.canvas.setTemplate(this.model.textures[0]?.url);

        this.parts = this.model.parts?.map((part)=>{
            const newPath = [];
            for (let i = 0; i < part.path.length; i += 2) {
                newPath.push(
                    part.path[i] * 4096, // x
                    part.path[i+1] * 4096 // y
                );
            }
            return {
                name: part.name,
                path: newPath
            } as PartInterface;
        });

        this.undoredo = new UndoRedo(this.canvas, this.parts);

        this.sceneInstance.loadModel(this.model)
        .then((success)=>{
            this.sceneInstance.setTexture(this.canvas.getTexture());
            this.setState({
                sceneMessage: success ? undefined : "Failed to load model"
            });
        });

        for (const instrument of this.instruments) {
            instrument.onOperationStart = this.onInstrumentStart;
            instrument.onDrawableUpdate = this.onInstrumentApply;
            instrument.onOperationFinish = this.onInstrumentFinish;
        }

        // FIX
        // this.undoredo.saveContent(this.canvas.getLayer(this.state.layer));
        this.undoredo.save(this.canvas.getLayer(this.state.layer));
        this.undoredo.on("restore", this.onRestore);

        document.addEventListener("keydown", this.onKeyDown);
    }

    componentWillUnmount(): void {
        this.canvas.clear();
        this.sceneInstance.dispose();

        this.undoredo?.off("restore", this.onRestore);

        document.removeEventListener("keydown", this.onKeyDown);
    }

    onKeyDown = (event: KeyboardEvent) => {
        // if (event.target !== document.body) { return; }
        if (event.repeat) { return; }
        const target = event.target as HTMLElement;
        if (target.tagName.toLowerCase() === 'input'
        || target.tagName.toLowerCase() === 'textarea') { 
            return; 
        }
        // if (event.target)
        if (this.state.exporting) { return; }
        event.preventDefault();
        
        // Ctrl
        if (event.ctrlKey || event.metaKey) {
            // + Shift + Z : Redo
            if (event.shiftKey && event.key === 'z') {
                if (!this.state.canRedo) { return; }
                this.undoredo?.redo();
            } 
            // + Z : Undo
            else if (event.key === 'z') {
                if (!this.state.canUndo) { return; }
                this.undoredo?.undo();
            }
            // + Y : Redo
            else if (event.key === 'y') {
                if (!this.state.canRedo) { return; }
                this.undoredo?.redo();
            }
            // + E : Export
            else if (event.key === 'e') {
                this.setState({exporting: true});
            }
        }
    };

    onRestore = ()=>{
        this.setState({
            canUndo: this.undoredo?.canUndo() ?? false, 
            canRedo: this.undoredo?.canRedo() ?? false
        });
        this.layerConfiguratorRef.current?.reload();
        this.canvas.update();
    };

    onInstrumentStart = () => {
        this.canvas.update(false, this.state.layer);
    };

    onInstrumentApply = () => {
        // console.log("instrument apply")
        this.canvas.update();
    };

    onTransformUpdate = () => {
        this.canvas.update();
        // console.log("transform", this.state.layer);
        // FIX
        // this.undoredo?.save(this.canvas.getLayer(this.state.layer));
        this.undoredo?.save();
        this.setState({
            canUndo: this.undoredo?.canUndo() ?? false, 
            canRedo: this.undoredo?.canRedo() ?? false
        });
    }

    onInstrumentFinish = () => {
        this.canvas.update();
        // console.log("operation done ", this.canvas.getLayer(this.state.layer) === this.state.instrument.drawable);
        // FIX
        // this.undoredo?.saveContent(this.canvas.getLayer(this.state.layer));
        this.undoredo?.save(this.canvas.getLayer(this.state.layer));
        this.setState({
            canUndo: this.undoredo?.canUndo() ?? false, 
            canRedo: this.undoredo?.canRedo() ?? false
        });
    };

    onLayerConfigurationChange = () =>{
        // console.log('layer configuration change');
        this.canvas.update();
        // FIX
        // this.undoredo?.save(this.canvas.getLayer(this.state.layer));
        this.undoredo?.save();
        this.setState({
            canUndo: this.undoredo?.canUndo() ?? false, 
            canRedo: this.undoredo?.canRedo() ?? false
        });
    };

    onLayerAdded = (layer: number) => {
        // console.log("Layer added", layer);
        // FIX
        // this.undoredo?.created(this.canvas.getLayer(layer));
        this.undoredo?.save(this.canvas.getLayer(layer));
        this.setState({
            canUndo: this.undoredo?.canUndo() ?? false, canRedo: this.undoredo?.canRedo() ?? false
        });
    };
    
    onLayerRemoved = (layer: number, drawable: DrawableElement<HTMLElement>) => {
        // FIX
        // this.undoredo?.removed(drawable, layer);
        this.undoredo?.save(drawable);
        this.setState({
            canUndo: this.undoredo?.canUndo() ?? false, 
            canRedo: this.undoredo?.canRedo() ?? false
        });
    }

    onLayerSelect = (layer: number) => {
        if (this.canvas.getLayer(layer)?.isCanvas()) {
            this.setState({
                layer,
                instrument: this.instruments[0],
                transform: false
            });
        } else if (this.canvas.getLayer(layer)?.isText()) {
            this.setState({
                layer,
                instrument: this.instruments[1],
                transform: true
            })
        } else {
            this.setState({
                layer,
                instrument: undefined,
                transform: true
            });
        }
    };

    onLayerRenamed = () => {
        // FIX
        // this.undoredo?.save(this.canvas.getLayer(this.state.layer));
        this.undoredo?.save();
        this.setState({
            canUndo: this.undoredo?.canUndo() ?? false, 
            canRedo: this.undoredo?.canRedo() ?? false
        });
    };

    render(){
        return (
            <div className={styles.ModelEditorPage}>
                {this.navigationPanel()}
                <div className={styles.Content}>
                    {this.categoriesPanel()}
                    {this.leftPanel()}
                    {this.centerPanel()}
                    {this.rightPanel()}
                </div>
                <Popup
                    show={this.state.exporting}
                    onClose={()=>{this.setState({exporting: false})}}
                    >
                        <ExportOptions 
                            canvas={this.canvas}
                            model={this.sceneInstance.model}
                            fbxURL={this.model?.url}
                            />
                </Popup>
            </div>
        );
    }

    navigationPanel(){
        const size = window.devicePixelRatio >= 1.5 ? 20*0.75 : 20;
        return (
            <div className={styles.Navigation}>
                <div className={styles.NavigationLeft}>
                    <div className={styles.NavigationLogo}>
                        <img 
                            src={logoImage}
                            />
                    </div>
                    <Link to="/" className={styles.NavigationHome}>
                        <ChevronLeft height={size} />
                        Home
                    </Link>
                </div>
                <div className={styles.NavigationUndoRedo}>
                    <div className={`${styles.Undo} ${this.state.canUndo ? '' : styles.UndoRedoDisabled}`}
                        onClick={()=>{if (this.state.canUndo) this.undoredo?.undo()}}
                        >
                        <RotateCcw height={size} />
                        Undo
                    </div>
                    <div className={`${styles.Redo} ${this.state.canRedo ? '' : styles.UndoRedoDisabled}`}
                        onClick={()=>{if (this.state.canRedo) this.undoredo?.redo()}}
                        >
                        Redo
                        <RotateCw height={size} />
                    </div>
                </div>
                <div className={styles.NavigationExport}
                    onClick={()=>{this.setState({exporting: true})}}>
                    <Plus height={size} />
                    Export
                </div>
            </div>
        );
    }

    categoriesPanel(){
        const size = window.devicePixelRatio >= 1.5 ? 24*0.75 : 24;
        return (
            <div className={styles.Categories}>
                <div className={`${styles.Category} ${this.state.category === Category.TEMPLATE ? styles.CategorySelected : ''}`}
                    onClick={()=>{this.setState({category: Category.TEMPLATE})}}
                    >
                    {/* <Archive width={24} height={24} strokeWidth={this.state.category === Category.TEMPLATE ? 2 : 1}/> */}
                    <PenTool width={size} height={size} strokeWidth={this.state.category === Category.TEMPLATE ? 2 : 1.2}/>
                    Template
                </div>
                <div className={`${styles.Category} ${this.state.category === Category.STICKER ? styles.CategorySelected : ''}`}
                    onClick={()=>{this.setState({category: Category.STICKER})}}
                    >
                    <Image width={size} height={size} strokeWidth={this.state.category === Category.STICKER ? 2 : 1.2}/>
                    Sticker
                </div>
                <div className={`${styles.Category} ${this.state.category === Category.IMAGE ? styles.CategorySelected : ''}`}
                    onClick={()=>{this.setState({category: Category.IMAGE})}}
                    >
                    <Image width={size} height={size} strokeWidth={this.state.category === Category.IMAGE ? 2 : 1.2}/>
                    Image
                </div>
                <div className={`${styles.Category} ${this.state.category === Category.TEXT ? styles.CategorySelected : ''}`}
                    onClick={()=>{this.setState({category: Category.TEXT})}}
                    >
                    <Type width={size} height={size} strokeWidth={this.state.category === Category.TEXT ? 2 : 1.2}/>
                    Text
                </div>
            </div>
        );
    }

    leftPanel(){
        const size = window.devicePixelRatio >= 1.5 ? 24*0.75 : 24;
        return (
            <div className={styles.LeftPanel}>
                {this.state.category === Category.TEMPLATE &&
                <TemplateCategory 
                    canvas={this.canvas}
                    templates={this.model?.textures ?? []}
                    />}
                {this.state.category === Category.STICKER &&
                <StickersCategory
                    canvas={this.canvas}
                    onAdded={this.onLayerAdded}
                    />}
                {this.state.category === Category.IMAGE &&
                <ImageCategory
                    canvas={this.canvas}
                    onAdded={this.onLayerAdded}
                    />}
                {this.state.category === Category.TEXT &&
                <TextCategory 
                    canvas={this.canvas}
                    onAdded={this.onLayerAdded}
                    />}
                <div>
                </div>
                <div>
                    <div className={styles.LeftPanelButtons}>
                        {this.state.swap ?  <div></div> : this.drawableStateButtons()}
                        <div className={styles.IconButton}
                            title="Move to center"
                            onClick={()=>{this.setState({swap: !this.state.swap})}}
                            >
                            <ExternalLink width={size} />
                        </div>
                    </div>
                    <div className={styles.LeftPanelCanvas}>
                        {this.state.swap ? this.modelScene() : this.drawable()}
                    </div>
                </div>
            </div>
        );
    }

    centerPanel(){
        return (
            <div className={styles.CenterPanel}>
                    {this.state.swap 
                    ? <div className={styles.CenterPanelButtons}>{ this.drawableStateButtons() }</div>
                    : null}
                {this.state.swap ? this.drawable() : this.modelScene()}
            </div>
        );
    }

    rightPanel(){
        return (
            <div className={styles.RightPanel}>
                <div className={styles.RightPanelLayers}>
                    <LayersController 
                        canvas={this.canvas}
                        selected={this.state.layer}
                        onSelect={this.onLayerSelect}
                        onAdded={this.onLayerAdded}
                        onRemoved={this.onLayerRemoved}
                        onRenamed={this.onLayerRenamed}
                        />
                </div>
                <div>
                    {this.canvas.getLayer(this.state.layer) && 
                    <LayerConfigurator 
                        ref={this.layerConfiguratorRef}
                        drawable={this.canvas.getLayer(this.state.layer)}
                        parts={this.parts}
                        onChange={this.onLayerConfigurationChange}
                        />}
                </div>
                <div>
                    {this.state.instrument && this.state.instrument.configurator(this.canvas.getLayer(this.state.layer))}
                </div>
            </div>
        );
    }

    drawableStateButtons(){
        return (
            <div className={styles.DrawableState}>
                <div className={`${styles.IconButton} ${this.state.overlay ? styles.IconButtonSelected : ''}`}
                    title={`Turn overlay image ${ this.state.overlay ? "OFF" : "ON"}`}
                    onClick={()=>{this.setState({overlay: !this.state.overlay})}}
                    >
                    <Layers width={20} />
                </div>
                <div className={`${styles.IconButton} ${this.state.transform ? styles.IconButtonSelected : ''}`}
                    title={`Turn layer transform ${this.state.transform ? "OFF" : "ON" }`}
                    onClick={()=>{this.setState({transform: !this.state.transform, moving: false})}}
                    >
                    {/* <Maximize width={20} /> */}
                    <Move width={20} />
                </div>
                <div className={`${styles.IconButton} ${this.state.moving ? styles.IconButtonSelected : ''}`}
                    title={`Turn canvas moving ${this.state.moving ? "OFF" : "ON" }`}
                    onClick={()=>{this.setState({moving: !this.state.moving, transform: false})}}
                    >
                    {/* <Move width={20} /> */}
                    <Anchor width={20} />
                </div>
                <div className={styles.IconButton}
                    title="Zoom In"
                    onClick={()=>{
                        this.drawableRef.current?.onStyleUpdate(0, 0, 1.2);
                    }}>
                    <ZoomIn width={20} />
                </div>
                <div className={styles.IconButton}
                    title="Zoom Out"
                    onClick={()=>{
                        this.drawableRef.current?.onStyleUpdate(0, 0, 0.8);
                    }}>
                    <ZoomOut width={20} />
                </div>
            </div>
        );
    }

    drawable(){
        const overlays = this.model?.overlays;
        let overlay = overlays?.[overlays.length - 1].url;
        const drawable = this.canvas.getLayer(this.state.layer);
        // let overlay = overlays?.[drawable?.pathId]?.url || overlays?.[overlays.length - 1].url;
        if (drawable?.pathId > -1) {
            overlay = overlays?.[drawable.pathId].url;
        }

        return (
            <Drawable 
                ref={this.drawableRef }
                canvas={this.canvas}
                layer={this.state.layer}
                overlay={this.state.overlay ? overlay : undefined}
                transform={this.state.transform}
                moving={this.state.moving}
                instrument={this.state.instrument}
                onTransformUpdate={this.onTransformUpdate}
                />
        );
    }

    modelScene(){
        return (
            <InstanceView 
                instance={this.sceneInstance}
                message={this.state.sceneMessage}
                />
        )
    }
}