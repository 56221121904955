import TextStyle, { DrawStyle, TextAlign } from "../2d/TextStyle";
import EventEmitter from "../utils/EventEmitter";

class DrawableElement<T extends HTMLElement> extends EventEmitter {
    element: T;

    name?: string;
    visible: boolean = true;
    alpha: number = 1;

    width: number;
    height: number;
    originWidth: number;
    originHeight: number;

    x: number = 0;
    y: number = 0;
    // scaleX: number = 1;
    // scaleY: number = 1;
    rotation: number = 0;

    pathId: number = -1;
    path?: number[];

    onload?: ()=>void;

    text?: string;
    textStyle?: TextStyle;

    constructor(element: T){
        super();
        
        this.element = element;

        if (this.element instanceof HTMLImageElement ||
            this.element instanceof HTMLCanvasElement) {
            this.width = this.originWidth = this.element.width;
            this.height = this.originHeight = this.element.height;
            
            this.x = this.width / 2;
            this.y = this.height / 2;
        } else {
            this.width = this.originWidth = 1;
            this.height = this.originHeight = 1;
        }

        if (this.element instanceof HTMLImageElement) {
            this.element.onload = this.onImageLoad;
        }
    }

    onImageLoad = () => {
        if (!(this.element instanceof HTMLImageElement)) { return; }
        this.width = this.originWidth = this.element.naturalWidth;
        this.height = this.originHeight = this.element.naturalHeight;
        
        this.x = this.width / 2;
        this.y = this.height / 2;

        this.emit("load");

        if (this.onload) { this.onload(); }
    };

    left(){
        return this.x - this.width / 2;
    }

    top(){
        return this.y - this.height / 2;
    }

    right(){
        return this.x + this.width / 2;
    }

    bottom(){
        return this.y + this.height / 2;
    }

    scale(){
        return {
            x: this.width / this.originWidth,
            y: this.height / this.originHeight
        };
    }

    isCanvas(){
        return (this.text === undefined && this.element instanceof HTMLCanvasElement);
    }

    isImage(){
        return this.element instanceof HTMLImageElement;
    }

    isText(){
        return this.text !== undefined;
    }

    drawTo(context: CanvasRenderingContext2D) {
        if (!this.visible) { return; }
        
        if (this.path) {
            context.save();

            context.beginPath();
            context.moveTo(this.path[0], this.path[1]);
            for (let i = 2; i < this.path.length; i += 2) {
                context.lineTo(this.path[i], this.path[i+1]);
            }
            context.clip();
        }

        context.save();
        context.translate(this.x, this.y);
        context.rotate(this.rotation);
        // context.scale(this.scaleX, this.scaleY);
        context.globalAlpha = this.alpha;

        if (this.element instanceof HTMLImageElement ||
            this.element instanceof HTMLCanvasElement) {
            context.drawImage(this.element, -this.width / 2, -this.height / 2, this.width, this.height);
        }

        context.globalAlpha = 1;

        context.restore();

        if (this.path) {
            context.restore();
        }
    }

    setText(text: string){
        if (!(this.element instanceof HTMLCanvasElement)) { return; }
        this.text = text;
        if (!this.textStyle) { this.textStyle = new TextStyle(); }
        const context = this.element.getContext("2d");
        if (!context) { return; }

        const lines = this.text.split("\n");
        this.textStyle.setStyleFor(context);
        let width = 0;
        let height = 0;
        for (const line of lines) {
            const metrics = context.measureText(line.replace(/ /g, '.'));
            width = Math.max(width, Math.ceil(metrics.actualBoundingBoxLeft + metrics.actualBoundingBoxRight));
            height = Math.max(height, Math.ceil(metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent));
        }
        this.width = this.originWidth = this.element.width = width + 50;
        this.height = this.originHeight = this.element.height = height * lines.length;
        
        if (!!this.textStyle.background) {
            context.fillStyle = this.textStyle.background;
            context.fillRect(0, 0, this.width, this.height);
        } else {
            context.clearRect(0, 0, this.width, this.height);
        }
        this.textStyle.setStyleFor(context);
        const drawText = this.textStyle.drawStyle === DrawStyle.fill ? context.fillText : context.strokeText;
        const dy = (this.height - 50) / lines.length; 
        const x = this.textStyle.textAlign === TextAlign.left ? 10 :
                  this.textStyle.textAlign === TextAlign.center ? this.width / 2 :
                  this.textStyle.textAlign === TextAlign.right ? this.width - 10 : 10;
        for (let i = 0; i < lines.length; ++i) {
            const line = lines[i];
            // drawText(line, 5, this.height - 25);
            drawText.apply(context, [line, x, dy * i + 25]);
        }

        this.emit("text");
    }

    /**
     * Fill canvas with provided color
     * @param color color
     */
    fill(color: string) {
        if (!(this.element instanceof HTMLCanvasElement)) { return; }
        const context = this.element.getContext("2d");
        if (!context) { return; }
        context.fillStyle = color;
        context.fillRect(0, 0, this.originWidth, this.originHeight);
    }

    /**
     * Clear canvas
     */
    clear(){
        if (!(this.element instanceof HTMLCanvasElement)) { return; }
        const context = this.element.getContext("2d");
        if (!context) { return; }
        context.clearRect(0, 0, this.originWidth, this.originHeight);
    }

    getContext(){
        if (this.isText()) { return; }
        if (this.element instanceof HTMLCanvasElement) {
            return this.element.getContext("2d");
        }
        return null;
    }

    setPath(pathId: number, path?: number[]){
        if (!path) { 
            this.pathId = -1;
            this.path = undefined;
            return;
        }
        this.pathId = pathId;
        this.path = path;
    }

    static image(src: string){
        const image = document.createElement("img");
        image.crossOrigin = "anonymous";
        image.src = src;
        return new DrawableElement<HTMLImageElement>(image);
    }

    static canvas(width: number, height: number) {
        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        const context = canvas.getContext("2d", {alpha: true});
        context?.clearRect(0, 0, width, height);
        return new DrawableElement<HTMLCanvasElement>(canvas);
    }

    static text(text: string) {
        const canvas = document.createElement("canvas");
        canvas.width = 100;
        canvas.height = 100;
        const element = new DrawableElement<HTMLCanvasElement>(canvas);
        element.textStyle = new TextStyle();
        element.setText(text);
        element.x = element.width / 2;
        element.y = element.height / 2;
        return element;
    }
}

export default DrawableElement;