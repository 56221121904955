import { PointerPoint } from "./Types";

/**
 * Swap values in array
 * @param array Array to swap at
 * @param first index of first value
 * @param second index of second value
 * @returns is swapped
 */
export function swap(array: any[], first: number, second: number): boolean {
    if (first < 0 || first >= array.length) { return false; }
    if (second < 0 || second >= array.length) { return false; }
    if (first === second) { return false; }

    const tmp = array[first];
    array[first] = array[second];
    array[second] = tmp;

    return true;
}

export function calcRotatedBounds(left: number, top: number, right: number, bottom: number, rotation: number){
    const centerX = (left + right) / 2;
    const centerY = (top + bottom) / 2;

    // Calculate the new coordinates of each corner after rotation
    const rotatedLeft = centerX + (left - centerX) * Math.cos(rotation) - (top - centerY) * Math.sin(rotation);
    const rotatedTop = centerY + (left - centerX) * Math.sin(rotation) + (top - centerY) * Math.cos(rotation);
    const rotatedRight = centerX + (right - centerX) * Math.cos(rotation) - (bottom - centerY) * Math.sin(rotation);
    const rotatedBottom = centerY + (right - centerX) * Math.sin(rotation) + (bottom - centerY) * Math.cos(rotation);

    // Find the new bounds
    const bounds = {
        left: Math.min(rotatedLeft, rotatedRight),
        top: Math.min(rotatedTop, rotatedBottom),
        right: Math.max(rotatedLeft, rotatedRight),
        bottom: Math.max(rotatedTop, rotatedBottom),
    };

    return bounds;
}

export function rotateDelta(dx: number, dy: number, rotation: number) {
    return {
        x: dx * Math.cos(rotation) + dy * Math.sin(rotation),
        y: -dx * Math.sin(rotation) + dy * Math.cos(rotation)
    };
}

/**
 * Calculates distance
 */
export function distance(dx: number, dy: number) {
    return Math.sqrt(dx*dx + dy*dy);
}

/**
 * Calculate mouse point at canvas
 */
export function calculatePoint(event: MouseEvent, canvas: HTMLCanvasElement): PointerPoint {
    const rect = canvas.getBoundingClientRect();
    const fx = (event.clientX - rect.left) / rect.width;
    const fy = (event.clientY - rect.top) / rect.height;
    const x = fx * canvas.width;
    const y = fy * canvas.height;
    const cx = event.clientX;
    const cy = event.clientY;

    return {x, y, fx, fy, cx, cy};
}