import React from "react";
import { ArrowLeft, PlusSquare, Trash2 } from "react-feather";
import StickersManager from "../utils/StickersManager";
import { TextureInterface } from "../utils/Types";
import styles from './StickersEditor.module.css';
import { Link } from "react-router-dom";

interface StickersEditorProps {
    serverUrl: string;
};

interface StickersEditorState {
    stickers: TextureInterface[];

    stickerName: string;
    stickerFile?: File;

    message: string;
    uploading: boolean;
};

export default class StickersEditor extends React.Component<StickersEditorProps, StickersEditorState> {
    state = {
        stickers: [],

        stickerName: '',
        stickerFile: undefined,
        
        message: '',
        uploading: false
    };

    componentDidMount(): void {
        const manager = StickersManager.instance();
        manager.on("reload-finish", this.onManagerUpdate);
        if (!manager.isBusy) { manager.reload(); }

        const token = localStorage.getItem('token');
        if (!token) {
            this.setState({ message: "Warning: No authorization token. Please, login."});
        }
    }
    
    componentWillUnmount(): void {
        const manager = StickersManager.instance();
        manager.off("reload-finish", this.onManagerUpdate);
    }

    onManagerUpdate = ()=>{
        const manager = StickersManager.instance();
        this.setState({
            stickers: manager.stickers
        });
    };

    uploadSticker = async () => {
        if (this.state.uploading) { return; }
        const token = localStorage.getItem('token');
        if (!token) { return; }

        if (!this.state.stickerName || !this.state.stickerFile) {
            this.setState({
                message: "Name and image required"
            });
            return;
        }

        const form = new FormData();
        form.append('name', this.state.stickerName);
        form.append('url', this.state.stickerFile);

        this.setState({
            message: 'Uploading...',
            uploading: true
        });

        const response = await fetch(this.props.serverUrl, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: form
        });

        if (!response.ok) {
            const text = await response.text();
            this.setState({
                message: `Response not ok. Status ${response.status}. Data: ${text}`,
                uploading: false
            });
            return;
        } else {
            this.setState({
                message: "Sticker uploaded",
                uploading: false
            });
            const manager = StickersManager.instance();
            manager.reload();
        }
    };

    deleteSticker = (id: number) => {
        const manager = StickersManager.instance();
        manager.deleteSticker(id);
    };

    render(): React.ReactNode {
        return (
            <div className={styles.Page}>
                {this.title()}
                {this.message()}
                {this.addStickerForm()}
                {this.stickers()}
            </div>
        );
    }

    title(){
        return (
            <div className={styles.TitleBar}>
                <Link to="/" className={styles.Link}>
                    <div className={styles.Back}>
                        <ArrowLeft />
                        <u>Back</u>
                    </div>
                </Link>
                <div className={styles.Title}>
                    Stickers:
                </div>
                <div>
                </div>
            </div>
        );
    }

    message(){
        return (
            <div className={styles.Message}>
                {this.state.message}
            </div>
        );
    }

    addStickerForm(){
        return (
            <div className={styles.AddForm}
                >
                <div className={styles.FormInput}>
                    <label>Name:</label>
                    <input 
                        placeholder="Sticker name"
                        value={this.state.stickerName}
                        onChange={(e) => { this.setState({stickerName: e.target.value})}}
                        />
                </div>
                <div className={styles.FormInput}>
                    <label>Image file:</label>
                    <input
                        type='file'
                        accept=".jpg,.jpeg,.png"
                        onChange={(e) => { this.setState({stickerFile: e.target.files?.[0]})}}
                        />
                </div>
                <div className={styles.FormPreview}>
                    { this.state.stickerFile && 
                        <img className={styles.FormImage}
                            src={URL.createObjectURL(this.state.stickerFile)} />}
                </div>
                <div className={styles.FormSubmit}
                    onClick={this.uploadSticker}>
                    <PlusSquare /> <u>Add</u>
                </div>
            </div>
        );
    }

    stickers(){
        return (
            <div className={styles.StickerItems}>
                {this.state.stickers.map((sticker, index) => 
                    <StickerItem 
                        key={`index-${index}`}
                        sticker={sticker}
                        onDelete={this.deleteSticker}
                        />)}
            </div>
        );
    }
}

class StickerItem extends React.Component<{
    onDelete: (id: number)=>void;
    sticker: TextureInterface;
}> {
    render(){
        return (
            <div className={styles.Item}>
                <div className={styles.ItemPreview}>
                    <img className={styles.ItemImage}
                        src={this.props.sticker.url}
                        />
                </div>
                <div className={styles.ItemName}>
                    {this.props.sticker.name}
                </div>
                <div className={styles.ItemDelete}
                    onClick={()=>{this.props.onDelete(this.props.sticker.id!)}}>
                    <Trash2 /> <u>Delete</u>
                </div>
            </div>
        );
    }
}