import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ChangePasswdPage from "./admin/ChangePasswdPage";
import StickersEditor from "./admin/StickersEditor";
import ModelAddPage from "./main/ModelAddPage";
import ModelChangePage from "./main/ModelChangePage";
import REditorPage from "./main/routed/REditorPage";
import RLoginPage from "./main/routed/RLoginPage";
import RSelectPage from "./main/routed/RSelectPage";
import ModelManager from "./utils/ModelManager";
import StickersManager from "./utils/StickersManager";
import { ModelInterface } from "./utils/Types";
import { withRouter } from "./utils/WithRouter";

interface AppRoutesProps {
    serverUrl: string;
};

const REditorPageWithRouter = withRouter(REditorPage);
const ModelChangePageWithRouter = withRouter(ModelChangePage);
const RSelectPageWithRouter = withRouter(RSelectPage);

export default class AppRoutes extends React.Component<AppRoutesProps> {
    render(){
        const modelManager = ModelManager.instance();
        modelManager.setServer(this.props.serverUrl);

        const stickersManager = StickersManager.instance();
        stickersManager.setServer(this.props.serverUrl);

        const token = localStorage.getItem('token');
        console.log(token);

        return (
            <BrowserRouter>
                <Routes>
                    {/* <Route path="/" element={ <RSelectPage isAdmin={!!token} />} /> */}
                    <Route path="/" element={ <RSelectPageWithRouter isAdmin={!!token} />} />
                    <Route path="/add" element={ <ModelAddPage onClose={()=>{window.location.assign('/')}} serverUrl={`${this.props.serverUrl}admin/models`}/>} />
                    <Route path="/change/:id" element={ <ModelChangePageWithRouter onClose={()=>{window.location.assign('/')}} serverUrl={`${this.props.serverUrl}admin/models/`}/>} />
                    <Route path="/edit/:id" element={<REditorPageWithRouter />} />
                    <Route path="/login" element={<RLoginPage serverUrl={`${this.props.serverUrl}admin/login`} />} />
                    <Route path="/change_passwd" element={<ChangePasswdPage serverUrl={`${this.props.serverUrl}admin/passwd`} />} />
                    <Route path="/stickers" element={ <StickersEditor serverUrl={`${this.props.serverUrl}admin/stickers`} />} />
                </Routes>
            </BrowserRouter>
        );
    }
}