import React, { createRef } from "react";
import DrawableElement from "../canvas/DrawableElement";
import styles from "./Drawable.module.css";
import Transformer from "../ui/Transformer";
import ClippingCanvas from "../canvas/ClippingCanvas";
import { calculatePoint, rotateDelta } from "../utils/Utils";
import EditInstrument from "../canvas/instruments/EditInstrument";
import { PointerPoint } from "../utils/Types";

interface DrawableProps {
    className?: string;
    canvas: ClippingCanvas;
    layer: number;
    instrument?: EditInstrument;
    disabled?: boolean;
    moving?: boolean;
    transform?: boolean;
    overlay?: string;
    onTransformUpdate?: ()=>void;
}

interface DrawableState {
    x: number;
    y: number;
    scale: number;
};

export default class Drawable extends React.Component<DrawableProps, DrawableState> {
    container = createRef<HTMLDivElement>();
    innerContainer = createRef<HTMLDivElement>();
    overlay = createRef<HTMLImageElement>();
    transformDiv = createRef<Transformer>();

    drawable?: DrawableElement<HTMLElement>;

    state = {
        x: 0,
        y: 0,
        scale: 1
    }

    componentDidMount(){
        if (!this.container.current) { return; }
        if (!this.innerContainer.current) { return; }

        const background = this.props.canvas.getCanvas();
        background.style.position = "absolute";
        background.style.width = "100%";
        background.style.height = "100%";
        background.style.zIndex = "0";
        background.style.opacity = "1";
        this.innerContainer.current.appendChild(background);

        this.subscribe(background);
        const drawable = this.props.canvas.getLayer(this.props.layer);
        if (drawable) {
        //     drawable.element.style.position = "absolute";
        //     drawable.element.style.width = "100%";
        //     drawable.element.style.height = "100%";
        //     drawable.element.style.zIndex = "1";
        //     drawable.element.style.opacity = "1";
        //     this.innerContainer.current.appendChild(drawable.element);
            this.drawable = drawable;
            this.drawable.element.hidden = true;
        //     this.subscribe(drawable.element);
        }

        if (this.overlay.current) {
            this.overlay.current.src = this.props.overlay || "";
        }

        this.container.current.addEventListener("wheel", this.onMouseWheel);
        this.onStyleUpdate(0, 0, 1);

        if (this.props.instrument) { this.props.instrument.setDrawable(this.drawable); }
    }
    
    componentWillUnmount(): void {
        this.container.current?.removeEventListener("wheel", this.onMouseWheel);

        const background = this.props.canvas.getCanvas();
        this.innerContainer.current?.removeChild(background);
        this.unsubscribe(background);
        if (this.drawable) {
            this.drawable.element.hidden = false;
        //     this.innerContainer.current?.removeChild(this.drawable.element);
        //     this.unsubscribe(this.drawable.element);
        }
    }

    componentDidUpdate(prevProps: Readonly<DrawableProps>, prevState: Readonly<DrawableState>, snapshot?: any): void {
        if (this.props.canvas !== prevProps.canvas) {
            const oldBackground = prevProps.canvas.getCanvas();
            this.innerContainer.current?.removeChild(oldBackground);
            this.unsubscribe(oldBackground);

            if (this.drawable) {
                this.drawable.element.hidden = false;
            //     this.unsubscribe(this.drawable.element);
            //     this.innerContainer.current?.removeChild(this.drawable.element);
            }
            this.drawable = undefined;

            const background = this.props.canvas.getCanvas();
            background.style.position = "absolute";
            background.style.width = "100%";
            background.style.height = "100%";
            background.style.zIndex = "0";
            background.style.opacity = "1";
            this.innerContainer.current?.appendChild(background);

            this.subscribe(background);

            const drawable = this.props.canvas.getLayer(this.props.layer);
            if (drawable) {
            //     drawable.element.style.position = "absolute";
            //     drawable.element.style.width = "100%";
            //     drawable.element.style.height = "100%";
            //     drawable.element.style.zIndex = "1";
            //     drawable.element.style.opacity = "0";
            //     this.innerContainer.current?.appendChild(drawable.element);
                this.drawable = drawable;
                this.drawable.element.hidden = true;
            //     this.subscribe(drawable.element);
            }
        } else {
            if (this.props.layer !== prevProps.layer) {
                if (this.drawable) {
                //     this.unsubscribe(this.drawable.element);
                //     this.innerContainer.current?.removeChild(this.drawable.element);
                    this.drawable.element.hidden = false;
                    this.drawable = undefined;
                }

                const drawable = this.props.canvas.getLayer(this.props.layer);
                if (drawable) {
                //     drawable.element.style.position = "absolute";
                //     drawable.element.style.width = "100%";
                //     drawable.element.style.height = "100%";
                //     drawable.element.style.zIndex = "1";
                //     drawable.element.style.opacity = "0";
                //     this.innerContainer.current?.appendChild(drawable.element);
                //     this.subscribe(drawable.element);
                    this.drawable = drawable;
                    this.drawable.element.hidden = true;
                }
                this.onStyleUpdate(0, 0, 1);
            }
        }

        if (this.overlay.current) {
            this.overlay.current.src = this.props.overlay || "";
        }

        if (this.props.instrument && this.props.instrument.drawable !== this.drawable) { this.props.instrument.setDrawable(this.drawable); }
    }

    subscribe(canvas: HTMLElement){
        canvas.addEventListener('pointerdown', this.onPointerDown, false);
        canvas.addEventListener('pointermove', this.onPointerMove, false);

        canvas.addEventListener('pointerup', this.onPointerUp, false);
        canvas.addEventListener('pointercancel', this.onPointerUp, false);
        // mouse enter canvas element
        canvas.addEventListener('pointerenter', this.onPointerEnter, false);
        canvas.addEventListener('pointerover', this.onPointerEnter, false);
        // mouse leave canvas element
        canvas.addEventListener('pointerleave', this.onPointerLeave, false);
        canvas.addEventListener('pointerout', this.onPointerLeave, false);

        window.addEventListener('pointerup', this.onPointerWindowUp, false);
    }
    unsubscribe(canvas: HTMLElement){
        canvas.removeEventListener('pointerdown', this.onPointerDown);
        canvas.removeEventListener('pointermove', this.onPointerMove);
        canvas.removeEventListener('pointerup', this.onPointerUp);
        canvas.removeEventListener('pointercancel', this.onPointerUp);
        canvas.removeEventListener('pointerenter', this.onPointerEnter);
        canvas.removeEventListener('pointerleave', this.onPointerLeave);
        canvas.removeEventListener('pointerout', this.onPointerLeave);
        canvas.removeEventListener('pointerover', this.onPointerEnter);

        window.removeEventListener('pointerup', this.onPointerWindowUp);
    }

    onStyleUpdate(dx: number, dy: number, dscale: number){
        if (!this.container.current || !this.innerContainer.current) { return; }
        const bounds = this.container.current.getBoundingClientRect();
        this.innerContainer.current.style.width = `${Math.min(bounds.width, bounds.height)}px`;
        this.innerContainer.current.style.height = `${Math.min(bounds.width, bounds.height)}px`;
        const iBounds = this.innerContainer.current.getBoundingClientRect();
        const scale = Math.max(0.9, Math.min(this.state.scale * dscale, 5));
        if (bounds) {
            const tr = this.transformDiv.current?.getRect();
            const dl = Math.min(0, (tr?.left ?? 0 - 10) * scale);
            const dt = Math.min(0, (tr?.top ?? 0 - 10) * scale);
            const dr = Math.max(0, ((tr?.right ?? 0) - bounds.width + 10) * scale);
            const db = Math.max(0, ((tr?.bottom ?? 0) - bounds.height + 10) * scale);
            
            const dw = iBounds.width - bounds.width;
            const dh = iBounds.height - bounds.height;
            const w = dw < 0 ? -dw : dw + 20;
            const h = dh < 0 ? -dh : dh + 20;
            const x = Math.max(-w/2 - dr, Math.min(this.state.x + dx, w/2 - dl));
            const y = Math.max(-h/2 - db, Math.min(this.state.y + dy, h/2 - dt));
            this.setState({
                x, y, scale
            });
        } else {
            this.setState({
                scale
            });
        }
    }

    isMiddleButton: boolean = false;
    countPointerDown: number = 0;
    isPointerOut: boolean = true;
    prevPoint: PointerPoint = {x: 0, y: 0, fx: 0, fy: 0, cx: 0, cy: 0};
    onPointerDown = (event: PointerEvent) => {
        if (event.button === 2) { return; }
        event.preventDefault();
        this.isMiddleButton = event.button === 1;
        const point = calculatePoint(event, this.props.canvas.getCanvas());
        this.countPointerDown++;
        this.prevPoint = point;
        if (this.props.moving || this.isMiddleButton || !this.drawable) {
            return;
        }
        if (this.drawable) {
            this.drawable.element.hidden = false;
        }
        this.props.instrument?.onPointerDown(point);
    };
    onPointerMove = (event: PointerEvent) => {
        if (!this.countPointerDown) { return; }
        event.preventDefault();
        const point = calculatePoint(event, this.props.canvas.getCanvas());
        if (this.props.moving || this.isMiddleButton || !this.drawable) {
            const dx = point.cx - this.prevPoint.cx;
            const dy = point.cy - this.prevPoint.cy;
            if (dx !== 0 || dy !== 0) {this.onStyleUpdate(dx, dy, 1); }
            this.prevPoint = point;
            return;
        }
        this.props.instrument?.onPointerMove(point);
    };
    onPointerUp = (event: PointerEvent) => {
        event.preventDefault();
        const point = calculatePoint(event, this.props.canvas.getCanvas());
        this.countPointerDown--;
        this.countPointerDown = this.countPointerDown < 0 ? 0 : this.countPointerDown;
        if (event.button === 1) {
            this.isMiddleButton = false;
            return;
        }
        if (this.props.moving || this.isMiddleButton || !this.drawable) { return; }
        if (this.drawable) {
            this.drawable.element.hidden = true;
        }
        this.props.instrument?.onPointerUp(point);
    };
    onPointerEnter = (event: PointerEvent) => {
        if (this.props.moving || this.isMiddleButton || !this.drawable) { return; }
        event.preventDefault();
        this.isPointerOut = false;
        const point = calculatePoint(event, this.props.canvas.getCanvas());
        this.prevPoint = point;
        this.props.instrument?.onPointerEnter(point);
    };
    onPointerLeave = (event: PointerEvent) => {
        event.preventDefault();
        const point = calculatePoint(event, this.props.canvas.getCanvas());
        this.isPointerOut = true;
        this.prevPoint = point;
        if (this.props.moving || this.isMiddleButton || !this.drawable) {
            return;
        }
        this.props.instrument?.onPointerLeave(point);
    };

    onPointerWindowUp = (event: PointerEvent) => {
        if (!this.isPointerOut) { return; }
        event.preventDefault();
        this.onPointerUp(event);
    };

    onMouseWheel = (event: WheelEvent) => {
        const scaleFactor = event.deltaY > 0 ? 1.05 : 0.95;
        this.onStyleUpdate(0, 0, scaleFactor);
        event.preventDefault()
    };
    
    render(){
        if (this.innerContainer.current) {
            this.innerContainer.current.style.transform = `translate(${this.state.x}px, ${this.state.y}px) scale(${this.state.scale})`;
        }

        return (
            <div className={styles.ControlDrawable} ref={this.container}>
                <div className={styles.InnerContainer} ref={this.innerContainer}>
                    <img ref={this.overlay} className={styles.PartOverlay} hidden={!this.props.overlay} crossOrigin="anonymous" />
                    {/** Canvas inserts there */}
                    {this.transformer()}
                </div>
            </div>
        );
    }

    transformer(){
        // return null;
        if (!this.drawable) { return; }
        const background = this.props.canvas.getCanvas();
        // const element = this.drawable.element;
        const width = background.width;
        const bounds = this.innerContainer.current?.getBoundingClientRect();
        // const elementBounds = element.getBoundingClientRect();
        const elementBounds = background.getBoundingClientRect();
        let x = 0;
        let y = 0;
        if (bounds) {
            const iw = elementBounds.width;
            const ih = elementBounds.height;
            x = (elementBounds.left) - (bounds.left + bounds.width / 2 - iw/2);
            y = (elementBounds.top) - (bounds.top + bounds.height / 2 - ih/2);
        }
        // const scale = (element.clientWidth / width);
        const scale = (background.clientWidth / width);
        return (
            <Transformer 
                ref={this.transformDiv}
                drawable={this.drawable}
                left={x}
                top={y}
                scale={this.state.scale}
                visualScale={scale}
                onUpdate={this.props.onTransformUpdate}
                disabled={!this.props.transform}
                />
        );
    }
}