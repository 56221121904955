import React from "react";
import ClippingCanvas from "../canvas/ClippingCanvas";
import * as THREE from "three";
import styles from "./ExportOptions.module.css";
import OBJExporter from "../utils/OBJExporter";
import { GLTFExporter } from "three/examples/jsm/exporters/GLTFExporter";
import JSZip from "jszip";

interface ExportOptionsProps {
    canvas?: ClippingCanvas;
    fbxURL?: string;
    model?: THREE.Object3D;
};

interface ExportOptionsState {
    parsing: boolean;
};

export default class ExportOptions extends React.Component<ExportOptionsProps, ExportOptionsState> {

    state = {
        parsing: false
    };

    download(dataURL: string, filename: string){
        const link = document.createElement("a");
        link.href = dataURL;
        link.download = filename;
        link.click();
    }

    onPNGClick = () => {
        if (!this.props.canvas) { return; }
        const canvas = this.props.canvas.getCanvas();

        this.download(canvas.toDataURL('image/png'), "texture.png");
    };

    onJPGClick = () => {
        if (!this.props.canvas) { return; }
        const canvas = this.props.canvas.getCanvas();

        this.download(canvas.toDataURL('image/jpeg'), "texture.jpg");
    };

    onFBXClick = () => {
        this.download(this.props.fbxURL!, "model.fbx");
    };

    onOBJClick = async () => {
        if (!this.props.model) { return; }

        this.setState({
            parsing: true
        });

        const exporter = new OBJExporter();
        try {
            const data = exporter.parse(this.props.model, 'mat');

            const zip = new JSZip();
            zip.file("model.obj", data.obj);
            if (data.mtl) {
                zip.file("model.mtl", data.mtl);
            }
            if (data.tex) {
                for (const tex of data.tex) {
                    zip.file(`${tex.name}.${tex.ext}`, tex.data);
                }
            }
            try {
                const blob = await zip.generateAsync({type: "blob"});
                this.download(URL.createObjectURL(blob), 'model.zip');
            } catch (error) {
                console.log('Error to pack .obj files to zip', error);
            }
        } catch (error) {
            console.error('Failed to parse model to .OBJ format', error);
        }

        this.setState({
            parsing: false
        });
    };

    onGLTFClick = async () => {
        if (!this.props.model) { return; }

        this.setState({
            parsing: true
        });

        const exporter = new GLTFExporter();

        try {
            const data = await exporter.parseAsync(this.props.model, { binary: false });
            const blob = new Blob([JSON.stringify(data)], { type: 'application/octet-stream' });

            this.download(URL.createObjectURL(blob), 'model.gltf');
        } catch (error) {
            console.error("Failder to parse model to GLTF format", error);
        }

        this.setState({
            parsing: false
        });
    };

    render(){
        return (
            <div className={styles.ExportOptions}>
                <div className={styles.Row}>
                    Model: 
                    <div className={styles.Buttons}>
                        {this.props.fbxURL && <div
                            className={styles.Button}
                            onClick={this.onFBXClick}
                            >.FBX</div>}
                        {this.props.model && <div
                            className={`${styles.Button} ${this.state.parsing && styles.Disabled}`}
                            // disabled={this.state.parsing}
                            onClick={this.onOBJClick}
                            >.OBJ</div>}
                        {this.props.model && <div
                            className={`${styles.Button} ${this.state.parsing && styles.Disabled}`}
                            onClick={this.onGLTFClick}
                            // disabled={this.state.parsing}
                            >.GLTF</div>}
                    </div>
                </div>
                <div className={styles.Row}>
                    Texture:
                    <div className={styles.Buttons}>
                        <div
                            className={styles.Button}
                            onClick={this.onPNGClick}
                            >.PNG</div>
                        <div
                            className={styles.Button}
                            onClick={this.onJPGClick}
                            >.JPG</div>
                    </div>
                </div>
            </div>
        );
    }
}