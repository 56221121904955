import { PointerPoint } from "../../utils/Types";
import DrawableElement from "../DrawableElement";

export default abstract class EditInstrument {
    /** Name of the instrument */
    name?: string;
    /** Drawable element to change */
    drawable?: DrawableElement<HTMLElement>;

    /** Calls when starting instrument operation */
    onOperationStart?: ()=>void;

    /** Calls when need to know update */
    // TODO: data to operation
    onDrawableUpdate?: ()=>void;

    /** Call when finish instrument operation */
    onOperationFinish?: ()=>void;

    setDrawable(drawable?: DrawableElement<HTMLElement>) {
        this.drawable = drawable;
    }

    abstract onPointerDown(point: PointerPoint): void;
    abstract onPointerMove(point: PointerPoint): void;
    abstract onPointerUp(point: PointerPoint): void;
    abstract onPointerEnter(point: PointerPoint): void;
    abstract onPointerLeave(point: PointerPoint): void;

    abstract configurator(drawable?: DrawableElement<HTMLElement>): JSX.Element | null;
}