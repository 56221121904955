// import React from 'react';
// import logo from './logo.svg';
import { useState } from 'react';
import './App.css';
import AppRoutes from './AppRoutes';
import MainPage from './main/MainPage';
import OldMainPage from './ui/MainPage';

const protocol = process.env.REACT_APP_SERVER_PROTOCOL || 'http';
const serverIp = process.env.REACT_APP_SERVER_IP || 'localhost';
const serverPort = process.env.REACT_APP_SERVER_PORT || '3000';
// const serverUrl = 'http://localhost:3000/';
const serverUrl = `${protocol}://${serverIp}:${serverPort}/`;

// function App() {
//     const [useOld, setUseOld] = useState(false);
//     const [useNew, setUseNew] = useState(false);

//     return (
//         <div className="App">
//             <MainPage serverUrl={serverUrl} />
//             {/* {useNew && <MainPage serverUrl={serverUrl} />}
//             {useOld && <OldMainPage serverUrl={serverUrl} />}
//             {!(useNew || useOld) &&
//                 <div>
//                     <button onClick={()=>{setUseNew(true)}}>New design</button>
//                     <button onClick={()=>{setUseOld(true)}}>Old design</button>
//                 </div>
//             } */}
//         </div>
//     );
// }

function App(){
    return (
        <div className="App">
            <AppRoutes serverUrl={serverUrl} />
        </div>
    );
}

export default App;
