import { PointerPoint } from "../../utils/Types";
import EditInstrument from "./EditInstrument";
import PencilConfigurator from "./PencilConfigurator";

export default class PencilInstrument extends EditInstrument {
    name = "Pencil";

    size: number = 50;
    onSizeChange = (size: number) => {this.size = size;}
    color: string = "#000000";
    onColorChange = (color: string) => {this.color = color;}
    erase: boolean = false;
    onEraseChange = (erase: boolean) => {this.erase = erase;}

    onFill = () => {
        this.applyPath();
        this.drawable?.fill(this.color);
        this.removePath();
        // this.onDrawableUpdate?.();
        this.onOperationFinish?.();
    };
    onClear = () => {
        this.applyPath();
        this.drawable?.clear();
        this.removePath();
        // this.onDrawableUpdate?.();
        this.onOperationFinish?.();
    };

    setStyle(){
        const context = this.drawable?.getContext();

        if (!context) { return; }
        context.lineWidth = this.size;
        context.lineCap = 'round';
        context.lineJoin = 'round';
        context.strokeStyle = this.color;
    }

    prevPoint: PointerPoint | null = null;
    isPointerOut: boolean = true;
    isPointerDown: boolean = false;

    onPointerDown(point: PointerPoint): void {
        this.onOperationStart?.();
        this.setStyle();
        
        this.applyPath();

        this.drawLine(point, point);
        this.prevPoint = point;
        this.isPointerDown = true;
    }
    onPointerMove(point: PointerPoint): void {
        if (!this.isPointerDown) { return; }
        if (this.isPointerOut) { return; }
        if (this.prevPoint) {
            this.drawLine(this.prevPoint, point);
        }
        this.prevPoint = point;
    }
    onPointerUp(point: PointerPoint): void {
        this.removePath();

        if (this.isPointerDown) {
            this.onOperationFinish?.();
        }
        this.isPointerDown = false;
    }
    onPointerEnter(point: PointerPoint): void {
        this.prevPoint = point;
        this.isPointerOut = false;
    }
    onPointerLeave(point: PointerPoint): void {
        if (!this.isPointerDown) { return; }
        if (!this.isPointerOut && this.prevPoint) {
            this.drawLine(this.prevPoint, point);
        }
        this.isPointerOut = true;
        this.prevPoint = point;
    }

    applyPath(){
        const context = this.drawable?.getContext();
        const path = this.drawable?.path;

        if (!context || !path) { return; }

        context.save();

        context.beginPath();
        
        let sp = this.transformPoint({x: path[0], y: path[1]});
        context.moveTo(sp.x, sp.y);
        for (let i = 2; i < path.length; i += 2) {
            sp = this.transformPoint({x: path[i], y: path[i+1]});
            context.lineTo(sp.x, sp.y);
        }
        context.clip();
    }
    removePath(){
        const context = this.drawable?.getContext();
        const path = this.drawable?.path;

        if (!context || !path) { return; }

        context.restore();
    }

    transformPoint(p: {x: number, y: number}){
        const scale = this.drawable!.scale();
        const x = this.drawable!.x;
        const y = this.drawable!.y;
        const ow = this.drawable!.originWidth;
        const oh = this.drawable!.originHeight;
        const cos = Math.cos(-this.drawable!.rotation);
        const sin = Math.sin(-this.drawable!.rotation);

        const srx = (p.x - x);
        const sry = (p.y - y);

        const rsrx = srx * cos - sry * sin;
        const rsry = srx * sin + sry * cos;

        const sx = (rsrx) / scale.x + ow/2;
        const sy = (rsry) / scale.y + oh/2;
        return {x: sx, y: sy};
    }

    drawLine(start: PointerPoint, end: PointerPoint) {
        const context = this.drawable?.getContext();

        if (!context) { return; }

        const sp = this.transformPoint(start);
        const ep = this.transformPoint(end);
        
        if (this.erase) {
            context.globalCompositeOperation = 'destination-out';
        } else {
            context.globalCompositeOperation = 'source-over';
        }
        context.beginPath();
        context.moveTo(sp.x, sp.y);
        context.lineTo(ep.x, ep.y);
        context.stroke();

        // this.onDrawableUpdate?.();
    }

    configurator() {
        return PencilConfigurator.create({
            color: this.color,
            onColorChange: this.onColorChange,
            size: this.size,
            onSizeChange: this.onSizeChange,
            erase: this.erase,
            onEraseChange: this.onEraseChange,
            onFill: this.onFill,
            onClear: this.onClear
        });
    }
}