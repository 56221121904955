import React from "react";
import { ArrowDown, ArrowUp, Eye, EyeOff, Plus, PlusSquare, Trash2 } from "react-feather";
import ClippingCanvas from "../canvas/ClippingCanvas";
import DrawableElement from "../canvas/DrawableElement";
import styles from "./LayersController.module.css";

interface LayersControllerProps {
    canvas: ClippingCanvas;
    selected?: number;
    onSelect?: (layer: number) => void;
    onAdded?: (index: number) => void;
    onRemoved?: (index: number, drawable: DrawableElement<HTMLElement>) => void;
    onRenamed?: () => void;
};


class LayersController extends React.Component<LayersControllerProps, {
    rename: number
}> {
    
    state = {
        rename: -1
    };

    componentDidMount(): void {
        this.props.canvas.on("layer-add", this.onLayerAdd);
        this.props.canvas.on("layer-remove", this.onLayerRemove);
        this.props.canvas.on("layer-swap", this.onLayerSwap);
    }
    
    componentWillUnmount(): void {
        this.props.canvas.off("layer-add", this.onLayerAdd);
        this.props.canvas.off("layer-remove", this.onLayerRemove);
        this.props.canvas.off("layer-swap", this.onLayerSwap);
    }

    onLayerAdd = (index: number)=>{
        this.selectLayer(index);
    };
    onLayerRemove = (index: number)=>{
        this.selectLayer(index >= this.props.canvas.layers.length ? index - 1 : index);
    };
    onLayerSwap = (first: number, second: number)=>{
        this.selectLayer(Math.min(Math.max(0, second), this.props.canvas.layers.length - 1));
    };

    componentDidUpdate(prevProps: Readonly<LayersControllerProps>, prevState: Readonly<{}>, snapshot?: any): void {
    }

    addLayer = () => {
        const index = this.props.canvas.addLayer("New Layer");
        this.props.onAdded?.(index);
        // this.selectLayer(index);
    };

    selectLayer = (index: number)=>{
        if (this.props.onSelect) { this.props.onSelect(index); }
    };

    onLayerItemClick = (index: number) => {
        if (index !== this.props.selected) {
            this.selectLayer(index);
        }
    };

    onLayerItemDClick = (index: number) => {
        if (this.state.rename === index) { return; }
        console.log(index);
        this.setState({rename: index});
    };

    changeLayerVisibility = (index: number, visible: boolean) => {
        const layer = this.props.canvas.getLayer(index);
        if (!layer) { return; }
        layer.visible = visible;
        this.props.canvas.update();
        this.forceUpdate();
    };

    removeLayer = (index: number) => {
        const drawable = this.props.canvas.removeLayer(index);
        if (drawable) {
            this.props.onRemoved?.(index, drawable);
        }
        // this.selectLayer(index >= this.props.canvas.layers.length ? index - 1 : index);
    };

    moveLayer = (index: number, to: number) => {
        this.props.canvas.swapLayers(index, to);
        // this.selectLayer(to);
    };

    render(){
        return (
            <div className={styles.LayersController}>
                <div className={styles.Title}>
                    Layers
                    <div className={styles.LayerPlus}
                        title="Add new layer"
                        onClick={this.addLayer}
                        >
                        <Plus width={20} strokeWidth={2.5} />
                    </div>
                </div>
                {this.layersList()}
            </div>
        )
    }

    layersList(){
        const layers = this.props.canvas.layers;

        return (
            <ul className={styles.LayerList}>
                {layers.map((layer, index)=>{
                    return this.layerItem(index, layer);
                })}
            </ul>
        );
    }

    layerName(name: string, maxLength: number = 13) {
        if (name.length > maxLength) {
            return name.substring(0, maxLength - 3) + '...';
        }
        return name;
    }

    layerRename(layer: DrawableElement<HTMLElement>){
        const index = this.state.rename;

        return (
            <input className={styles.LayerRenameInput}
                defaultValue={layer.name}
                // value={name}
                onKeyDown={(event)=>{
                    if (event.key === 'Escape') {
                        this.setState({rename: -1});
                    }
                    if (event.key === 'Enter') {
                        this.setState({rename: -1}, () =>{
                            const target = (event.target as HTMLInputElement);
                            if (target.value && target.value !== layer.name) {
                                layer.name = target.value;
                                this.props.onRenamed?.();
                            }
                        });
                    }
                }}
                onBlur={(event)=>{
                    this.setState({rename: -1}, () =>{
                        const target = (event.target as HTMLInputElement);
                        if (target.value && target.value !== layer.name) {
                            layer.name = target.value;
                            this.props.onRenamed?.();
                        }
                    });
                }}
                autoFocus={true}
                />
        );
    }

    layerItem(index: number, layer: DrawableElement<HTMLElement>){
        const layerName = layer.name ?? `Layer ${index + 1}`;
        return (
            <li className={styles.LayerItem}
                key={`layer-${index}`}>
                <div className={styles.LayerItemRow}>
                    <div className={`${styles.LayerItemName} ${this.props.selected === index ? styles.LayerItemNameSelected : ''}`}
                        onClick={()=>{this.onLayerItemClick(index)}}
                        onDoubleClick={()=>{this.onLayerItemDClick(index)}}
                        >
                        {this.state.rename === index ? this.layerRename(layer) : this.layerName(layerName, 20)}
                    </div> 
                    <div className={styles.LayerItemControls}>
                        {this.visibleButton(index, layer.visible)} 
                        {this.removeButton(index)} 
                        {this.moveUpButton(index)} 
                        {this.moveDownButton(index)}
                    </div>
                </div>
            </li>
        );
    }

    visibleButton(index: number, visible: boolean) {
        return (
            <div className={`${styles.IconButton} ${styles.Eye}`}
                title={`${visible ? "Hide layer" : "Show layer"}`}
                onClick={(e)=>{this.changeLayerVisibility(index, !visible); e.preventDefault(); }}
                >
                {visible ? <Eye width={18} height={18} /> : <EyeOff width={18} height={18} />}
            </div>
        )
    }

    removeButton(index: number){
        return (
            <div className={`${styles.IconButton} ${styles.Delete}`}
                title="Remove layer"
                onClick={(e) => {this.removeLayer(index); e.preventDefault();}}
                >
                <Trash2 width={18} height={18} />
            </div>
        );
    }

    moveUpButton(index: number){
        return (
            <div className={`${styles.IconButton} ${styles.Arrow}`}
                title="Move layer up"
                onClick={(e) => {this.moveLayer(index, index - 1); e.preventDefault()}}
                >
                <ArrowUp width={18} height={18} />
            </div>
        );
    }
    moveDownButton(index: number){
        return (
            <div className={`${styles.IconButton} ${styles.Arrow}`}
                title="Move layer down"
                onClick={(e) => {this.moveLayer(index, index + 1); e.preventDefault()}}
                >
                <ArrowDown width={18} height={18} />
            </div>
        );
    }
}

export default LayersController;