import EventEmitter from "./EventEmitter"
import { TextureInterface } from "./Types";

export default class StickersManager extends EventEmitter {
    static _instance?: StickersManager;

    static instance(){
        if (!this._instance) {
            this._instance = new StickersManager();
        }
        return this._instance;
    }

    serverUrl: string = '';

    isBusy: boolean = false;
    isError: boolean = false;

    stickers: TextureInterface[] = [];

    getStickerById(id: number){
        const sticker = this.stickers.filter(sticker => sticker.id === id)[0];
        return sticker;
    }

    setServer(url: string){
        this.serverUrl = url;
        this.reload();
    }

    async reload(){
        if (this.serverUrl === '') { 
            this.emit('reload-finish');
            return; 
        }
        this.stickers = [];

        this.emit("reload-start");

        this.isBusy = true;
        this.isError = false;

        const url = `${this.serverUrl}stickers`;
        try {
            const response = await fetch(url);

            if (!response.ok) {
                console.error(`Stickers: Failed to retrieve response from: ${url}. Status: ${response.status}`);

                this.isError = true;
                this.isBusy = false;
                this.emit("reload-finish");

                return;
            }

            const patchUrl = (url: string) => {
                if (url.startsWith('./')) {
                    return this.serverUrl + url.slice(2);
                }
                return url;
            }

            const list: TextureInterface[] = await response.json();

            /** Parse response */
            this.stickers = list.map((item) => ({
                id: item.id,
                name: item.name,
                url: patchUrl(item.url)
            }))
        } catch (error) {
            this.isError = true;
            console.error("Stickers: Failed to fetch", error);
        }
        this.isBusy = false;
        this.emit("reload-finish");
    }

    async deleteSticker(id: number){
        const token = localStorage.getItem('token');
        if (!token) { return; }
        const url = `${this.serverUrl}admin/stickers/${id}`;
        this.emit('reload-start');
        const response = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const message = await response.json();

        if (response.ok) {
            console.log(message.message);
            this.stickers = this.stickers.filter(model => model.id !== id);
        } else {
            console.log(message.error);
        }
        this.emit('reload-finish');
    }
}