import React from "react";
import { ChevronDown } from "react-feather";
import DrawableElement from "../canvas/DrawableElement";
import { PartInterface } from "../utils/Types";
import styles from "./LayerConfigurator.module.css";

interface LayerConfiguratorProps {
    drawable: DrawableElement<HTMLElement>;
    parts?: PartInterface[];
    onChange: ()=>void;
};

interface LayerConfigurationState {
    alpha: number;
    part: number;
};

export default class LayerConfigurator extends React.Component<LayerConfiguratorProps, LayerConfigurationState> {

    state = {
        alpha: this.props.drawable.alpha,
        part: this.props.drawable.pathId
    };

    componentDidUpdate(prevProps: Readonly<LayerConfiguratorProps>, prevState: Readonly<{}>, snapshot?: any): void {
        if (this.props.drawable !== prevProps.drawable){
            this.state.alpha = this.props.drawable.alpha;
            this.state.part = this.props.drawable.pathId;
            this.forceUpdate();
        }
        //  else {
        //     this.props.drawable.alpha = this.state.alpha;
        //     this.props.drawable.setPath(this.state.part, this.props.parts?.[this.state.part]?.path);
        //     this.props.onChange();
        // }
    }

    reload(){
        this.setState({
            alpha: this.props.drawable.alpha,
            part: this.props.drawable.pathId
        });
    }

    onPartChange(partId: number) {
        this.setState({part: partId});
        this.props.drawable.setPath(partId, this.props.parts?.[partId]?.path);
        this.props.onChange();
    }

    onAlphaChange(alpha: number) {
        this.props.drawable.alpha = alpha;
        this.props.onChange();
    }

    render(){
        return (
            <div className={styles.Container}>
                <div className={styles.Title}>
                    Layer
                </div>
                <div className={styles.Row}>
                    Opacity:
                    <div className={styles.Slide}>
                        <input
                            className={styles.SlideInput}
                            type='range'
                            min={0}
                            max={100}
                            value={isNaN(this.state.alpha) ? 0 : (this.state.alpha * 100)}
                            onMouseUp={(e)=>{this.onAlphaChange(this.state.alpha)}}
                            onChange={(e)=>{this.setState({alpha: e.target.valueAsNumber * 0.01})}}
                            />
                    </div>
                    <input className={styles.Input}
                        type='number'
                        min={0}
                        max={100}
                        value={isNaN(this.state.alpha) ? 0 : (this.state.alpha * 100)}
                        onChange={(e)=>{
                            this.setState({alpha: e.target.valueAsNumber * 0.01});
                            this.onAlphaChange(e.target.value ? e.target.valueAsNumber : 0);
                        }}
                        
                        />
                </div>
                <div className={styles.Row}>
                    Part:
                    <div className={styles.Select}>
                        <ChevronDown className={styles.SelectArrow} width={20} height={20} />
                        <select 
                            value={this.state.part}
                            onChange={(e)=>{this.onPartChange(parseInt(e.target.value))}}
                            >
                            <option
                                value={-1}
                                >None</option>
                            {this.props.parts?.map((part, index)=>(
                                <option 
                                    key={`part${index}`}
                                    value={index}
                                    >{part.name}</option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
        );
    }
}