import React, { createRef } from "react";
import { Upload } from "react-feather";
import ClippingCanvas from "../../canvas/ClippingCanvas";
import Popup from "../../ui/Popup";
import ImageManager from "../../utils/ImageManager";
import styles from "./ImageCategory.module.css";

interface ImageCategoryProps {
    canvas: ClippingCanvas;
    onAdded?: (index: number) =>  void;
};

interface ImageCategoryState {
    images: string[],
    names: string[],
    upload: boolean
}

export default class ImageCategory extends React.Component<ImageCategoryProps, ImageCategoryState> {
    manager: ImageManager = ImageManager.instance();

    urlRef = createRef<HTMLInputElement>();
    fileRef = createRef<HTMLInputElement>();

    state = {
        images: this.manager.images,
        names: this.manager.names,
        upload: false
    };

    componentDidMount(){
        this.manager.onImageAdded = this.onImageAdded;
    }

    componentWillUnmount(): void {
        this.manager.onImageAdded = undefined;
    }

    onImageAdded = () => {
        this.setState({images: this.manager.images, names: this.manager.names});
        const {src, name} = this.manager.last();
        this.props.canvas.addImage(src, name);
    };

    onImageSelect = (index: number) => {
        const src = this.state.images[index];
        const name = this.state.names[index];
        const layer = this.props.canvas.addImage(src, name);
        this.props.onAdded?.(layer);
    }

    loadImage = ()=>{
        const src = this.urlRef.current?.value;
        if (!src) {
            return;
        }
        this.urlRef.current!.value = "";

        const pathArray = src.split('/');
        const lastSegment = pathArray[pathArray.length - 1];
        const fileName = lastSegment.split('?')[0].replace(/\.[^/.]+$/, '');
        this.manager.addSource(src, fileName);
        this.setState({ upload: false });
    };
    uploadImage = ()=>{
        this.fileRef.current?.click();
    };
    onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({upload: false});
        const files = event.target.files;
        if (!files) { return; }
        this.manager.addFiles(files);
    };

    render() {
        return (
            <div className={styles.Category}>
                <Popup
                    show={this.state.upload}
                    onClose={()=>{this.setState({upload: false})}}>
                        {this.uploader()}
                </Popup>
                <div className={styles.Title}>
                    Add image
                </div>
                <div className={styles.Container}>
                    <div className={styles.Item}>
                        <div className={styles.Add}
                            onClick={()=>{this.setState({upload: true})}}
                            >
                            <Upload width={30} height={30} />
                            Upload
                        </div>
                    </div>
                    {this.state.images.map((src, index) => (
                        <ImageItem 
                            key={`image-${index}`}
                            index={index}
                            src={src}
                            onSelect={this.onImageSelect}
                            />
                    ))}
                </div>
            </div>
        );
    }

    /** Show popup to upload image */
    uploader(){
        return (
            <div className={styles.Uploader}>
                Add Image
                <div className={styles.UploaderRow}>
                    {/* By link: */}
                    <input 
                        className={styles.URLInput}
                        ref={this.urlRef} 
                        type="text" 
                        placeholder="URL to image..." 
                        />
                    <div className={styles.Button}
                        onClick={this.loadImage}
                        >Load</div>
                </div>
                OR
                <div className={styles.UploaderRow}>
                    Upload from device:
                    <input ref={this.fileRef} className={styles.FileInput} type="file" accept="image/*" multiple onChange={this.onFileChange}/>
                    <div className={styles.Button}
                        onClick={this.uploadImage}
                        >Select...</div>
                </div>
            </div>
        );
    }
}

class ImageItem extends React.Component<
{
    src: string,
    index: number,
    onSelect: (index: number) => void
}
> {
    render() {
        return (
            <div className={styles.Item}
                onClick={()=>{ this.props.onSelect(this.props.index); }}
                >
                <img 
                    className={styles.ItemImage}
                    src={this.props.src}
                    crossOrigin="anonymous"
                    />
            </div>
        );
    }
}