/** Texture (image) for the model */
export interface TextureInterface {
    id?: number,
    /** Name of the texture (image) */
    name: string;
    /** URL to the image file */
    url: string;
};

/** Editing zone on texture of model part */
export interface PartInterface {
    /** Name of the part */
    name: string;
    /** Relative coordinates on texture [0; 1] that defines part */
    path: number[];
};

/** Model information */
export interface ModelInterface {
    id?: number;
    /** Name of the model */
    name: string;
    /** URL to the .fbx model file */
    url: string;
    /** Base textures for the model */
    textures: TextureInterface[];
    /** Overlay images for model texture. First is for all texture, next is for each part. */
    overlays?: TextureInterface[] | null;
    /** Parts of model textrure. Defines editing zones on texture of model part */
    parts?: PartInterface[] | null;
    /** Preview image */
    preview?: string | null;
};

/** Creates empty model information object */
export function emptyModel(): ModelInterface {
    return {
        name: "",
        url: "",
        textures: []
    };
}

/** Clone model information */
export function cloneModel(model: ModelInterface): ModelInterface {
    return {
        name: model.name,
        url: model.url,
        textures: model.textures.map((texture) => ({...texture})),
        overlays: model.overlays ? model.overlays.map((overlay)=>({...overlay})) : undefined,
        parts: model.parts ? model.parts.map((part)=>({name: part.name, path: [...part.path]})) : undefined,
        preview: model.preview
    };
}

/** Response of models list from server */
export interface Models {
    models: ModelInterface[];
};

/** Pointer event coordinates for canvas */
export interface PointerPoint {
    /** X on canvas */
    x: number;
    /** Y on canvas */
    y: number;
    /** X in [0, 1] range (x/canvas.width) */
    fx: number;
    /** Y in [0, 1] range (y/canvas.height) */
    fy: number;
    /** Client X from event */
    cx: number;
    /** Client Y from event */
    cy: number;
};