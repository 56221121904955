import * as THREE from "three";
import EventEmitter from "../utils/EventEmitter";
import { swap } from "../utils/Utils";
import DrawableElement from "./DrawableElement";

class ClippingCanvas extends EventEmitter {
    /** Main canvas that used in texture */
    canvas: HTMLCanvasElement;
    /** THREE.JS canvas texture */
    texture: THREE.CanvasTexture;

    template?: HTMLImageElement;

    /** Layers canvas */
    layers: DrawableElement<HTMLElement>[] = [];
    /** Layers visibility */
    layersVisibility: boolean[] = [];
    /** Layers name */
    layersName: string[] = [];
    /** Used in default layer name */
    lastId: number = 1;

    /** Size of canvas */
    /** Width of canvas/texture */
    width;
    /** Height of canvas/texture */
    height;

    /**
     * Creates new class that allows to manipulate multiple canvases (layers) to make one solid texture
     * @param width Width of canvas/texture
     * @param height Height of canvas/texture
     */
    constructor(width = 512, height = 512){
        super();

        this.width = width;
        this.height = height;

        this.canvas = document.createElement("canvas");
        this.canvas.width = this.width;
        this.canvas.height = this.height;

        const context = this.canvas.getContext("2d", { alpha: false });
        if (context) {
            context.fillStyle = 'white';
            context.fillRect(0, 0, this.width, this.height);
        }

        this.texture = new THREE.CanvasTexture(this.canvas);
        this.texture.generateMipmaps = true;
        this.texture.minFilter = THREE.LinearMipMapLinearFilter;
        this.addLayer("Base");
    }

    /**
     * Remove layers canvases and dispose texture, do not use after this call
     */
    clear(){
        this.layers.length = 0;
        this.texture.dispose();
    }

    setTemplate(src?: string){
        if (this.template) {
            this.template = undefined;
        }

        if (src) {
            this.template = document.createElement("img");
            this.template.crossOrigin = "anonymous";
            this.template.onload = this.onTempateLoad;
            this.template.src = src;
        }

        this.update();
    }

    getTemplate(){
        return this.template?.src;
    }

    onTempateLoad = () => {
        this.update();
    };

    /**
     * @returns Textures canvas
     */
    getCanvas(){
        return this.canvas;
    }

    /**
     * @returns Texture
     */
    getTexture(){
        return this.texture;
    }

    /**
     * Updates textures canvas by drawing over all visible layers
     */
    update(updateTexture: boolean = true, skipIndex?: number){
        const context = this.canvas.getContext("2d");
        if (!context) { return; }
        if (this.template) {
            context.drawImage(this.template, 0, 0, this.width, this.height);
        } else {
            context.fillStyle = 'white';
            context.fillRect(0, 0, this.width, this.height);
        }
        if (this.layers.length) {
            for (let i = 0; i < this.layers.length; ++i) {
                if (!this.layersVisibility[i]) { continue; }
                if (!this.layers[i].visible) { continue; }
                if (i === skipIndex) { continue; }

                this.layers[i].drawTo(context);
            }
        }

        this.texture.needsUpdate = updateTexture;
    }

    prepareName(name: string) {
        let layerName = name;
        let nameRepeat = this.layers.findIndex((drawable) => { return drawable.name === layerName});
        let i = 0;
        while (nameRepeat !== -1) {
            i++;
            layerName = `(${i}) ${name}`;
            nameRepeat = this.layers.findIndex((drawable) => { return drawable.name === layerName});
        }

        return layerName;
    }

    /**
     * Add new layer to stack
     * 
     * @returns Index of layer
     */
    addLayer(name?: string): number {
        const layer = DrawableElement.canvas(this.width, this.height);

        const layerName = this.prepareName(name ?? `Layer ${++this.lastId}`);

        layer.name = layerName;
        this.layers.push(layer);
        this.layersVisibility.push(true);
        this.layersName.push(layerName);

        const index = this.layers.length - 1;

        this.update();

        this.emit("layer-add", index, "canvas");
        this.emit("layer-update", "add");

        return index;
    }

    addImage(src: string, name?: string): number {
        const layer = DrawableElement.image(src);

        const layerName = this.prepareName(name ?? `Image`);
        layer.name = layerName;
        layer.onload = ()=>{ this.update(); }

        this.layers.push(layer);
        this.layersVisibility.push(true);
        this.layersName.push(layerName);

        const index = this.layers.length - 1;

        this.update();

        this.emit("layer-add", index, "image");
        this.emit("layer-update", "add");

        return index;
    }

    addText(text: string = "Text", name?: string){
        const layer = DrawableElement.text(text);

        const layerName = this.prepareName(name ?? `Text`);

        layer.name = layerName;

        this.layers.push(layer)
        this.layersVisibility.push(true);
        this.layersName.push(layerName);

        const index = this.layers.length - 1;

        this.update();

        this.emit("layer-add", index, "text");
        this.emit("layer-update", "add");

        return index;
    }

    restoreLayer(layer: DrawableElement<HTMLElement>, index: number){
        this.layers.push(layer);
        this.layersVisibility.push(layer.visible);
        this.layersName.push(layer.name ?? "restored");

        this.moveTo(this.layers.length - 1, index);

        this.update();

        this.emit("layer-add", index);
        this.emit("layer-update", "restore");
    }

    /**
     * Get layers canvas
     * 
     * @param index Index of layer in stack
     * @returns Layers canvas
     */
    getLayer(index: number): DrawableElement<HTMLElement> {
        let layer = this.layers[index];
        if (!layer) {
            layer = this.layers[this.layers.length - 1];
        }
        return layer;
    }

    /**
     * Change visibility of layer
     * @param index Index of layer
     * @param visible Is layer visible
     */
    setVisible(index: number, visible: boolean) {
        if (index < 0 || index >= this.layers.length) { return; }
        this.layersVisibility[index] = visible;
        this.update();
    }

    /**
     * Remove layer from stack
     * 
     * @param index Index of layer
     */
    removeLayer(index: number){
        if (!this.layers[index]) { return; }
        const layer = this.layers[index];
        this.layers.splice(index, 1);
        this.layersVisibility.splice(index, 1);
        this.layersName.splice(index, 1);

        this.update();

        this.emit("layer-remove", index, layer);
        this.emit("layer-update", "remove");

        return layer;
    }

    /**
     * Swap layers
     * 
     * @param first Index of first layer
     * @param second Index of seconds layer
     */
    swapLayers(first: number, second: number): boolean {
        const result = swap(this.layers, first, second);
        swap(this.layersVisibility, first, second);
        swap(this.layersName, first, second);
        
        this.update();

        this.emit("layer-swap", first, second);
        this.emit("layer-update", "swap");

        return result;
    }

    /**
     * Move layer upper
     * 
     * @param index Index of layer
     */
    moveUp(index: number){
        this.swapLayers(index, index + 1);
    }

    /**
     * Move layer down
     * 
     * @param index Index of layer
     */
    modeDown(index: number){
        this.swapLayers(index, index - 1);
    }

    moveTo(index: number, target: number){
        const layer = this.layers.splice(index, 1)[0];
        const vis = this.layersVisibility.splice(index, 1)[0];
        const name = this.layersName.splice(index, 1)[0];
        
        if (!layer) { 
            console.warn("Possible bug while moving layer. Last time by UndoRedo functions.");
            return; 
        }

        this.layers.splice(target, 0, layer);
        this.layersVisibility.splice(target, 0, vis);
        this.layersName.splice(target, 0, name);
    }
}

export default ClippingCanvas;