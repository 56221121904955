import React, { createRef } from "react";
import { calculatePoint } from "../utils/Utils";
import styles from "./PathCreator.module.css";

interface PathCreatorProps {
    src?: string;
    path?: number[];
    onConfirm?: (path: number[]) => void;
};

interface PathCreatorState {
    path: number[];
};

export default class PathCreator extends React.Component<PathCreatorProps, PathCreatorState> {
    state = {
        path: new Array<number>()
    };

    imageRef = createRef<HTMLImageElement>();
    canvasRef = createRef<HTMLCanvasElement>();

    componentDidMount(): void {
        if (!this.canvasRef.current) { return }

        this.canvasRef.current.width = 10;
        this.canvasRef.current.height = 10;
        this.canvasRef.current.addEventListener("click", this.onMouseClick);
    }

    componentWillUnmount(): void {
        this.canvasRef.current?.removeEventListener("click", this.onMouseClick);
    }

    onImageLoad = ()=>{
        if (!this.canvasRef.current) { return; }
        if (!this.imageRef.current) { return; }

        this.canvasRef.current.width = this.imageRef.current.width;
        this.canvasRef.current.height = this.imageRef.current.height;

        this.updatePath();
    };

    updatePath = () => {
        if (!this.canvasRef.current) { return; }
        const { width, height } = this.canvasRef.current;

        const context = this.canvasRef.current.getContext("2d", { alpha: true });
        if (!context) { return; }
        
        context.clearRect(0, 0, width, height);
        
        if (!this.state.path.length) { return; }
        
        context.lineWidth = 5;
        context.lineJoin = "round";
        context.strokeStyle = "#00ff00";
        context.fillStyle = "#ff000088";

        context.beginPath();
        context.moveTo(this.state.path[0] * width, this.state.path[1] * height);
        context.strokeRect(this.state.path[0] * width - 5, this.state.path[1] * height - 5, 10, 10);
        for (let i = 2; i < this.state.path.length; i += 2) {
            context.lineTo(this.state.path[i] * width, this.state.path[i+1] * height);
        }
        context.fill();
        context.stroke();
    }

    onClear = () => {
        this.setState({path: []}, this.updatePath);
    };

    onConfirm = () => {
        this.props.onConfirm?.(this.state.path);
    };

    onMouseClick = (e: MouseEvent) => {
        if (!this.canvasRef.current) { return; }

        const point = calculatePoint(e, this.canvasRef.current);
        this.setState({ path: [...this.state.path, point.fx, point.fy]}, this.updatePath);
    };

    componentDidUpdate(prevProps: Readonly<PathCreatorProps>, prevState: Readonly<PathCreatorState>, snapshot?: any): void {
        if (prevProps.path !== this.props.path && this.props.path !== this.state.path) {
            this.state.path = this.props.path ?? [];
            this.updatePath();
        }

        if (prevProps.src !== this.props.src) {
            if (this.props.src) {
                if (this.imageRef.current)
                this.imageRef.current.src = this.props.src;
            }
        }
    }

    render(){
        return (
            <div>
                Path will auto close, so there are no need to try close path. <br />
                Affected part is filled with transparent red color.
                <div className={styles.Container}>
                    <div className={styles.Clear}
                        onClick={this.onClear}>
                        <u>Clear</u>
                    </div>
                    <div className={styles.Creator}>
                        <img 
                            className={styles.BackImage}
                            ref={this.imageRef}
                            onLoad={this.onImageLoad}
                            />
                        <canvas 
                            className={styles.FrontCanvas}
                            ref={this.canvasRef}
                            />
                    </div>
                    <div className={styles.Confirm}
                        onClick={this.onConfirm}>
                        <u>Confirm</u>
                    </div>
                </div>
            </div>
        );
    }
}