import React, { createRef } from "react";
import DrawableElement from "../canvas/DrawableElement";
import styles from '../styles/Transformer.module.css';
import { calcRotatedBounds, distance, rotateDelta } from "../utils/Utils";

interface TransformerProps {
    drawable: DrawableElement<HTMLElement>;
    top: number;
    left: number;
    scale: number;
    visualScale: number;
    onUpdate?: ()=>void;
    disabled?: boolean;
};

interface TransformerState {
};

const BORDER = 3;
const RESIZE = 10;
const ROTATE = 10;

enum Corner {
    none,
    topLeft, topCenter, topRight,
    middleLeft, middleCenter, middleRight,
    bottomLeft, bottomCenter, bottomRight,
    rotate
};

class Transformer extends React.Component<TransformerProps, TransformerState> {
    mainRef = createRef<HTMLDivElement>();
    topLeftRef = createRef<HTMLDivElement>();
    topCenterRef = createRef<HTMLDivElement>();
    topRightRef = createRef<HTMLDivElement>();
    middleLeftRef = createRef<HTMLDivElement>();
    middleRightRef = createRef<HTMLDivElement>();
    bottomLeftRef = createRef<HTMLDivElement>();
    bottomCenterRef = createRef<HTMLDivElement>();
    bottomRightRef = createRef<HTMLDivElement>();
    rotateRef = createRef<HTMLDivElement>();

    componentDidMount(): void {
        this.subscribe();

        // if (this.props.drawable.isCanvas()) {
            this.props.drawable.element.style.position = "absolute";
            this.props.drawable.element.style.width = "100%";
            this.props.drawable.element.style.height = "100%";
            this.props.drawable.element.style.top = `${0}px`;
            this.props.drawable.element.style.left = `${0}px`;
            this.mainRef.current?.appendChild(this.props.drawable.element);
        // }


        this.props.drawable.on("text", this.onTextUpdate);
        this.props.drawable.on("load", this.onImageLoaded);
    }
    
    componentWillUnmount(): void {
        this.unsubscribe();
        
        // if (this.props.drawable.isCanvas()) {
            this.mainRef.current?.removeChild(this.props.drawable.element);
        // }

        this.props.drawable.off("text", this.onTextUpdate);
        this.props.drawable.off("load", this.onImageLoaded);
    }

    componentDidUpdate(prevProps: Readonly<TransformerProps>, prevState: Readonly<TransformerState>, snapshot?: any): void {
        if (prevProps.drawable !== this.props.drawable) {
            prevProps.drawable.off("text", this.onTextUpdate);
            prevProps.drawable.off("load", this.onImageLoaded);
            // if (prevProps.drawable.isCanvas()) {
                this.mainRef.current?.removeChild(prevProps.drawable.element);
            // }
            this.props.drawable.on("text", this.onTextUpdate);
            this.props.drawable.on("load", this.onImageLoaded);
            // if (this.props.drawable.isCanvas()) {        
                this.props.drawable.element.style.position = "absolute";
                this.props.drawable.element.style.width = "100%";
                this.props.drawable.element.style.height = "100%";
                this.props.drawable.element.style.top = `${0}px`;
                this.props.drawable.element.style.left = `${0}px`;
                this.mainRef.current?.appendChild(this.props.drawable.element);
            // }
        }
    }

    onTextUpdate = ()=>{
        this.forceUpdate();
    };

    onImageLoaded = ()=>{
        this.forceUpdate();
    };

    getRect(){
        const {left, top, visualScale} = this.props;
        const l = left + this.props.drawable.left() * visualScale;
        const t = top + this.props.drawable.top() * visualScale;
        const w = this.props.drawable.width * visualScale;
        const h = this.props.drawable.height * visualScale;
        const dr = h / 2 + 50;
        const drx = Math.cos(this.props.drawable.rotation - Math.PI / 2) * dr;
        const dry = Math.sin(this.props.drawable.rotation - Math.PI / 2) * dr;

        const rl = l + w / 2 + drx;
        const rt = t + h / 2 + dry;
        const rr = rl + ROTATE;
        const rb = rt + ROTATE;

        const ml = l - BORDER;
        const mt = t - BORDER;
        const mr = ml + w;
        const mb = mt + h;
        const mbr = calcRotatedBounds(ml, mt, mr, mb, this.props.drawable.rotation);

        const bleft = Math.min(mbr.left, rl);
        const btop = Math.min(mbr.top, rt);
        const bright = Math.max(mbr.right, rr);
        const bbottom = Math.max(mbr.bottom, rb);
        return {left: bleft, top: btop, right: bright, bottom: bbottom, width: bright - bleft, height: bbottom - btop};
    }

    subscribe(){
        this.mainRef.current?.addEventListener("pointerdown", this.onPointerDown);
        window.addEventListener('pointermove', this.onPointerMove);

        this.topLeftRef.current?.addEventListener("pointerdown", this.onTopLeft);
        this.topCenterRef.current?.addEventListener("pointerdown", this.onTopCenter);
        this.topRightRef.current?.addEventListener("pointerdown", this.onTopRight);

        this.middleLeftRef.current?.addEventListener("pointerdown", this.onMiddleLeft);
        this.middleRightRef.current?.addEventListener("pointerdown", this.onMiddleRight);

        this.bottomLeftRef.current?.addEventListener("pointerdown", this.onBottomLeft);
        this.bottomCenterRef.current?.addEventListener("pointerdown", this.onBottomCenter);
        this.bottomRightRef.current?.addEventListener("pointerdown", this.onBottomRight);

        this.rotateRef.current?.addEventListener("pointerdown", this.onRotate);

        window.addEventListener('pointerup', this.onPointerWindowUp);
    }
    unsubscribe(){
        this.mainRef.current?.removeEventListener("pointerdown", this.onPointerDown);
        window.removeEventListener('pointermove', this.onPointerMove);
        
        this.topLeftRef.current?.removeEventListener("pointerdown", this.onTopLeft);
        this.topCenterRef.current?.removeEventListener("pointerdown", this.onTopCenter);
        this.topRightRef.current?.removeEventListener("pointerdown", this.onTopRight);

        this.middleLeftRef.current?.removeEventListener("pointerdown", this.onMiddleLeft);
        this.middleRightRef.current?.removeEventListener("pointerdown", this.onMiddleRight);

        this.bottomLeftRef.current?.removeEventListener("pointerdown", this.onBottomLeft);
        this.bottomCenterRef.current?.removeEventListener("pointerdown", this.onBottomCenter);
        this.bottomRightRef.current?.removeEventListener("pointerdown", this.onBottomRight);

        this.rotateRef.current?.removeEventListener("pointerdown", this.onRotate);

        window.removeEventListener('pointerup', this.onPointerWindowUp);
    }

    eventType: Corner = Corner.none;

    onTopLeft = (event: PointerEvent) => {
        this.eventType = Corner.topLeft;
    }
    onTopCenter = (event: PointerEvent) => {
        this.eventType = Corner.topCenter;
    };
    onTopRight = (event: PointerEvent) => {
        this.eventType = Corner.topRight;
    };
    onMiddleLeft = (event: PointerEvent) => {
        this.eventType = Corner.middleLeft;
    };
    onMiddleRight = (event: PointerEvent) => {
        this.eventType = Corner.middleRight;
    };
    onBottomLeft = (event: PointerEvent) => {
        this.eventType = Corner.bottomLeft;
    };
    onBottomCenter = (event: PointerEvent) => {
        this.eventType = Corner.bottomCenter;
    };
    onBottomRight = (event: PointerEvent) => {
        this.eventType = Corner.bottomRight;
    };

    onRotate = (event: PointerEvent) => {
        this.eventType = Corner.rotate;
        this.countPointerDown++;
        this.props.drawable.element.hidden = false;
    };

    prevPoint = {x: 0, y: 0};
    countPointerDown = 0;
    onPointerDown = (event: PointerEvent) => {
        if (event.button === 2) { return; }
        event.preventDefault();
        this.countPointerDown++;
        this.prevPoint.x = event.clientX;
        this.prevPoint.y = event.clientY;
        this.props.drawable.element.hidden = false;
    };
    onPointerMove = (event: PointerEvent) => {
        if (!this.countPointerDown) { return; }
        event.preventDefault();
        const dx = (event.clientX - this.prevPoint.x) / (this.props.visualScale * this.props.scale);
        const dy = (event.clientY - this.prevPoint.y) / (this.props.visualScale * this.props.scale);

        const bound = this.mainRef.current!.getBoundingClientRect();
        const bx = bound.left + bound.width / 2;
        const by = bound.top + bound.height / 2;

        let width = this.props.drawable.width;
        let height = this.props.drawable.height;
        const cos = Math.cos(-this.props.drawable.rotation);
        const sin = Math.sin(-this.props.drawable.rotation);
        const rdx = dx * cos - dy * sin;
        const rdy = dx * sin + dy * cos;
        const as = (width + height) / 2;

        switch (this.eventType) {
            case Corner.rotate:
                const rotation = Math.atan2(event.clientY - by, event.clientX - bx) + Math.PI/2;
                this.props.drawable.rotation = rotation;
                break;
            case Corner.topLeft:
                const tl = 1 - (rdx + rdy) / as;
                width *= tl;
                height *= tl;
                break;
            case Corner.topCenter:
                height += -rdy;
                break;
            case Corner.topRight:
                const tr = 1 - (-rdx + rdy) / as;
                width *= tr;
                height *= tr;
                break;
            case Corner.middleLeft:
                width += -rdx;
                break;
            case Corner.middleRight:
                width += rdx;
                break;
            case Corner.bottomLeft:
                const bl = 1 - (rdx - rdy) / as;
                width *= bl;
                height *= bl;
                break;
            case Corner.bottomCenter:
                height += rdy;
                break;
            case Corner.bottomRight:
                const br = 1 - (-rdx - rdy) / as;
                width *= br;
                height *= br;
                break;
            case Corner.none:
                this.props.drawable.x += dx;
                this.props.drawable.y += dy;
                break;
        }
        this.props.drawable.width = width < 1 ? 1 : width;
        this.props.drawable.height = height < 1 ? 1 : height;

        this.prevPoint.x = event.clientX;
        this.prevPoint.y = event.clientY;
        this.forceUpdate();
    };
    onPointerWindowUp = (event: PointerEvent) => {
        if (this.props.disabled) { return; }
        this.props.drawable.element.hidden = true;
        if (this.countPointerDown) { this.props.onUpdate?.(); }
        event.preventDefault();
        this.countPointerDown--;
        this.countPointerDown = this.countPointerDown < 0 ? 0 : this.countPointerDown;
        this.eventType = Corner.none;
    };

    render(){
        const { visualScale } = this.props;
        const left = this.props.left + this.props.drawable.left() * visualScale;
        const top = this.props.top + this.props.drawable.top() * visualScale;
        const width = this.props.drawable.width * visualScale;
        const height = this.props.drawable.height * visualScale;
        const d = height / 2 + 50;
        const dx = Math.cos(this.props.drawable.rotation - Math.PI / 2) * d;
        const dy = Math.sin(this.props.drawable.rotation - Math.PI / 2) * d;
        return (<>
            <div
                ref={this.rotateRef}
                className={styles.Rotate}
                style={{
                    left: left + width / 2 - ROTATE / 2 - BORDER / 2 + dx,
                    top: top + height / 2 - ROTATE / 2 + dy,
                    width: ROTATE,
                    height: ROTATE,
                    borderRadius: ROTATE / 2
                }}
                hidden={this.props.disabled}
                ></div>
            <div
                ref={this.mainRef}
                className={styles.Main}
                style={{
                    left: left - BORDER,
                    top: top - BORDER,
                    width: width,
                    height: height,
                    borderWidth: BORDER,
                    pointerEvents: this.props.disabled ? 'none' : 'auto',
                    rotate: `${this.props.drawable.rotation}rad`
                }}
                >
                <div hidden={this.props.disabled}>
                    {/* <div className={styles.DiagonalLeft} /> */}
                    {/* <div className={styles.DiagonalRight} /> */}
                    <div
                        ref={this.topLeftRef}
                        className={styles.Resize}
                        style={{
                            left: - RESIZE - BORDER,
                            top: - RESIZE - BORDER,
                            width: RESIZE,
                            height: RESIZE,
                        }}
                        ></div>
                    <div
                        ref={this.topCenterRef}
                        className={styles.Resize}
                        style={{
                            left: width / 2 - RESIZE / 2 - BORDER / 2,
                            top: - RESIZE - BORDER,
                            width: RESIZE,
                            height: RESIZE,
                        }}
                        ></div>
                    <div
                        ref={this.topRightRef}
                        className={styles.Resize}
                        style={{
                            left: width,
                            top: - RESIZE - BORDER,
                            width: RESIZE,
                            height: RESIZE,
                        }}
                        ></div>
                    <div
                        ref={this.middleLeftRef}
                        className={styles.Resize}
                        style={{
                            left: - RESIZE - BORDER,
                            top: height / 2 - RESIZE / 2 - BORDER / 2,
                            width: RESIZE,
                            height: RESIZE,
                        }}
                        ></div>
                    <div
                        ref={this.middleRightRef}
                        className={styles.Resize}
                        style={{
                            left: width,
                            top: height / 2 - RESIZE / 2 - BORDER / 2,
                            width: RESIZE,
                            height: RESIZE,
                        }}
                        ></div>
                    <div
                        ref={this.bottomLeftRef}
                        className={styles.Resize}
                        style={{
                            left: - RESIZE - BORDER,
                            top: height,
                            width: RESIZE,
                            height: RESIZE,
                        }}
                        ></div>
                    <div
                        ref={this.bottomCenterRef}
                        className={styles.Resize}
                        style={{
                            left: width / 2 - RESIZE / 2 - BORDER / 2,
                            top: height,
                            width: RESIZE,
                            height: RESIZE,
                        }}
                        ></div>
                    <div
                        ref={this.bottomRightRef}
                        className={styles.Resize}
                        style={{
                            left: width,
                            top: height,
                            width: RESIZE,
                            height: RESIZE,
                        }}
                        ></div>
                </div>
            </div>
        </>
        );
    }
}

export default Transformer;