import React, { createRef } from "react";
import ModelSceneInstance from "./ModelSceneInstance";
import styles from "./InstanceView.module.css";

interface InstanceViewProps {
    instance: ModelSceneInstance;
    message?: string;
}

export default class InstanceView extends React.Component<InstanceViewProps> {
    container = createRef<HTMLDivElement>();

    componentDidMount(): void {
        this.container.current?.appendChild(this.props.instance.getCanvas());
        window.addEventListener("resize", this.onWindowResize);
        this.onWindowResize();
    }

    componentWillUnmount(): void {
        this.container.current?.removeChild(this.props.instance.getCanvas());
        window.removeEventListener("resize", this.onWindowResize);
    }

    onWindowResize = () => {
        if (!this.container.current) { return; }
        const style = getComputedStyle(this.container.current);
        const width = parseInt(style.width);
        const height = parseInt(style.height);

        console.log(width, height);
        this.props.instance.resize(width, height);
    }

    render(){
        return (
            <div className={styles.ThreeScene} ref={this.container}>
                {this.props.message && <div className={styles.Message}>{this.props.message}</div>}
            </div>
        );
    }
}