import React from "react";
import { RefreshCw } from "react-feather";
import ClippingCanvas from "../../canvas/ClippingCanvas";
import StickersManager from "../../utils/StickersManager";
import { TextureInterface } from "../../utils/Types";
import styles from "./StickersCategory.module.css";

interface StickersCategoryProps {
    canvas: ClippingCanvas;
    onAdded?: (index: number) => void;
};

interface StickersCategoryState {
    stickers: TextureInterface[];
    reloading: boolean;
};

export default class StickersCategory extends React.Component<StickersCategoryProps, StickersCategoryState> {
    state = {
        stickers: [],
        reloading: false
    };

    componentDidMount(): void {
        const manager = StickersManager.instance();
        manager.on("reload-finish", this.onManagerReloaded);
        this.setState({
            stickers: manager.stickers
        });
    }

    componentWillUnmount(): void {
        const manager = StickersManager.instance();
        manager.off("reload-finish", this.onManagerReloaded);
    }

    onManagerReloaded = () => {
        const manager = StickersManager.instance();
        this.setState({
            reloading: false,
            stickers: manager.stickers
        });
    };

    onReload = () => {
        if (this.state.reloading) { return; }
        const manager = StickersManager.instance();
        if (manager.isBusy) { return; }
        this.setState({reloading: true}, () => {manager.reload()});
    };

    onStickerSelect = (sticker: TextureInterface) => {
        const layer = this.props.canvas.addImage(sticker.url, sticker.name);
        this.props.onAdded?.(layer);
    };

    render() {
        return (
            <div className={styles.Container}>
                {/* <div className={styles.Reload}
                    onClick={this.onReload}>
                    <RefreshCw width={24} height={24} />
                    <u>{this.state.reloading ? 'Loading...' : 'Reload'}</u>
                </div> */}
                <div className={styles.Title}>
                    Add sticker
                </div>
                {this.items()}
            </div>
        );
    }
    
    items(){
        return (
            <div className={styles.ItemsContainer}>
                {this.state.stickers.map((sticker, index) => 
                    <StickerItem 
                        key={`index-${index}`}
                        sticker={sticker}
                        onSelect={this.onStickerSelect}
                        />)}
            </div>
        );
    }
}

class StickerItem extends React.Component<
{
    sticker: TextureInterface,
    onSelect: (sticker: TextureInterface) => void
}
> {
    render() {
        return (
            <div className={styles.Item}
                onClick={()=>{ this.props.onSelect(this.props.sticker); }}
                >
                <img 
                    className={styles.ItemImage}
                    src={this.props.sticker.url}
                    crossOrigin="anonymous"
                    />
            </div>
        );
    }
}