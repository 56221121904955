import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { ModelInterface } from "../utils/Types";

export default class ModelSceneInstance {
    renderer: THREE.WebGLRenderer;
    camera: THREE.PerspectiveCamera;
    scene: THREE.Scene;
    controls: OrbitControls;
    model?: THREE.Group;
    meshes?: THREE.Object3D[];
    textures?: THREE.Texture[];

    isReady = false;

    constructor(){
        this.renderer = new THREE.WebGLRenderer();

        this.scene = new THREE.Scene();
        this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        this.setupScene();
        this.controls = new OrbitControls(this.camera!, this.renderer!.domElement);

        this.startRenderer();
    }

    getCanvas(){
        return this.renderer.domElement;
    }

    /**
     * Setups 3D scene
     */
    setupScene(){
        this.scene.background = new THREE.Color(0xf3f4f6)

        const helper = new THREE.GridHelper(10, 10);
        this.scene.add(helper);
        helper.position.y = -3;

        // const radius = 10;
        // const sectors = 8;
        // const rings = 8;
        // const divisions = 64;

        // const helper = new THREE.PolarGridHelper( radius, sectors, rings, divisions );
        // this.scene.add( helper );
        (helper.material as THREE.Material).transparent = true;
        (helper.material as THREE.Material).opacity = 0.3;

        const light = new THREE.DirectionalLight(0xffffff, 1);
        light.position.set(5, 10, 7);
        light.castShadow = true;
        this.scene.add(light);

        const blight = new THREE.DirectionalLight(0xffffff, 1);
        blight.position.set(0, -5, 0);
        blight.castShadow = true;
        this.scene.add(blight);

        const light2 = new THREE.DirectionalLight(0xffffff, 1);
        light2.position.set(-5, 10, -7);
        light2.castShadow = true;
        this.scene.add(light2);

        this.camera.position.x = 5;
        this.camera.position.y = 5;
    }

    /**
     * Start 3D rendering
     */
    startRenderer(){
        this.isReady = true;
        this.animate();
    }

    /**
     * Loads provided by props model
     */
    async loadModel(modelData: ModelInterface){
        // Load textures
        const textureLoader = new THREE.TextureLoader();
        const textures: THREE.Texture[] = [];
        for (let t of modelData.textures) {
            try {
                const texture = await textureLoader.loadAsync(t.url);
                texture.name = t.name;
                textures.push(texture);
            } catch (error) {
                console.error("Error while loading texture: ", error);
            }
        }
        // Load model
        const loader = new FBXLoader();
        try {
            const model = await loader.loadAsync(modelData.url);
            this.onModelLoaded(model, textures);
            return true;
        } catch (error) {
            console.error("Error while loading model: ", error);
            return false;
        }
    }

    /**
     * Calls when model is successfully loaded
     * 
     * @param model Loaded model from .fbx file
     */
    onModelLoaded(model: THREE.Group, textures?: THREE.Texture[]) {
        this.model = model;
        this.textures = textures;
        this.meshes = [];
        const toRemove: THREE.Object3D[] = [];
        model.traverse((child) => {
            // Remove lights from loaded model
            if ((child as THREE.Light).isLight) {
                console.log('Removing light from imported model');
                // child.removeFromParent();
                toRemove.push(child);
            }
            // Update materials of meshes
            if ((child as THREE.Mesh).isMesh) {
                this.meshes!.push(child);
                (child as THREE.Mesh).castShadow = true;
                (child as THREE.Mesh).receiveShadow = true;
                if ((child as THREE.Mesh).material) {
                    if (Array.isArray((child as THREE.Mesh).material)) {
                        ((child as THREE.Mesh).material as THREE.Material[]).forEach(material => {
                            material.transparent = false;
                        });
                    } else {
                        ((child as THREE.Mesh).material as THREE.MeshBasicMaterial).transparent = false;
                    }
                }
            }
        });
        this.model.scale.set(0.01, 0.01, 0.01);
        toRemove.forEach((child) => {
            child.removeFromParent();
        })

        const boundingBox = new THREE.Box3();
        boundingBox.setFromObject(this.model);

        const distance = boundingBox.getSize(new THREE.Vector3()).length();

        this.camera.position.x = -distance * 1;
        this.camera.position.y = distance * 1;

        boundingBox.getCenter(this.controls.target); 
        this.controls.update();

        this.scene.add(model);
    }

    setTexture(texture: THREE.Texture){
        if (!this.model || !this.meshes) { return; }

        for (const mesh of this.meshes) {
            const material = (mesh as THREE.Mesh).material;
            if (!material) { return; }
            if (Array.isArray(material)) {
                (mesh as THREE.Mesh).material = material.map(m => {
                    return new THREE.MeshStandardMaterial({map: texture || null})
                });
            } else {
                (mesh as THREE.Mesh).material = new THREE.MeshStandardMaterial({map: texture || null});
            }
        }
    }

    /**
     * Dispose elements
     */
    dispose(){
        this.model?.traverse((child) => {
            if ((child as THREE.Mesh).isMesh) {
                if ((child as THREE.Mesh).material) {
                    if (Array.isArray((child as THREE.Mesh).material)) {
                        ((child as THREE.Mesh).material as THREE.Material[]).forEach(material => {
                            (material as THREE.MeshBasicMaterial).map?.dispose();
                            material.dispose();
                        });
                    } else {
                        ((child as THREE.Mesh).material as THREE.MeshBasicMaterial).map?.dispose();
                        ((child as THREE.Mesh).material as THREE.MeshBasicMaterial).dispose();
                    }
                }
                (child as THREE.Mesh).geometry.dispose();
            }
        })
        this.renderer?.dispose();
        this.renderer?.forceContextLoss();
        this.isReady = false;
    }

    /** Ticker function */
    animate = () => {
        if (!this.isReady) { return; }
        requestAnimationFrame(this.animate);

        this.renderer!.render(this.scene!, this.camera!);
    }

    resize(width: number, height: number){
        if (!this.isReady) { return; }

        this.renderer.setSize(width, height);
        this.camera.aspect = width / height;
        this.camera.updateProjectionMatrix();
    }
}