import React, { createRef } from "react";
import styles from "./TextCategory.module.css";
import {fonts, FontStyle, TextAlign} from "../../2d/TextStyle";
import ClippingCanvas from "../../canvas/ClippingCanvas";
import { PlusSquare } from "react-feather";

interface TextCategoryProps {
    canvas: ClippingCanvas;
    onAdded?: (index: number) => void;
};

interface TextCategoryState {
    text: string;
    font: string;
    color: string;
    background: string;
    isBackground: boolean;
    size: string;
    isBold: boolean;
    isItalic: boolean;
    textAlign: string;
};

const DEFAULT_STATE = {
    text: "",
    font: "Arial",
    color: "#000000",
    background: "#ffffff",
    isBackground: false,
    size: "512",
    isBold: false,
    isItalic: false,
    textAlign: "left"
};

export default class TextCategory extends React.Component<TextCategoryProps, TextCategoryState> {
    state = {
        text: "",
        font: "Arial",
        color: "#000000",
        background: "#ffffff",
        isBackground: false,
        size: "512",
        isBold: false,
        isItalic: false,
        textAlign: "left"
    };
    nameInputRef = createRef<HTMLInputElement>();

    componentDidMount(){
        this.setState(DEFAULT_STATE);
        this.nameInputRef.current?.addEventListener("keydown", this.onKeyDown);
    }

    componentWillUnmount(): void {
        this.nameInputRef.current?.removeEventListener("keydown", this.onKeyDown);
    }

    onKeyDown = (event: KeyboardEvent) => {
        if (event.key === "Enter") {
            this.addText();
            this.nameInputRef.current?.blur();
        }
    };

    addText = ()=>{
        const text = this.state.text || "Text"
        const layer = this.props.canvas.addText(text, text);
        this.props.onAdded?.(layer);
        this.setState({text: ""})
    };

    render(){
        return (
            <div className={styles.Container}>
                <div className={styles.Title}>
                    Add text
                </div>
                <div className={styles.Row}>
                    <input 
                        ref={this.nameInputRef}
                        className={styles.Name}
                        type="text"
                        value={this.state.text}
                        placeholder={"Layer name"}
                        onChange={(e)=>{this.setState({text: e.target.value})}}
                        />
                    <div className={styles.Add}
                        onClick={this.addText}
                        >
                        <PlusSquare width={24} />
                        {/* <u>Add text</u> */}
                    </div>
                </div>
            </div>
        );

        return (
            <div className={styles.Container}>
                <div className={styles.Row}>
                    Font:
                    <div className={styles.Group}>
                        {this.fontSelect()}
                        {this.fontColor()}
                    </div>
                </div>
                <div className={styles.Row}>
                    Size:
                    {this.fontSize()}
                </div>
                <div className={styles.Row}>
                    Style: 
                    {this.fontStyle()}
                    Align: 
                    <div className={styles.Group}>
                        {this.textAlign()}
                    </div>
                </div>
                <div className={styles.Row}>
                    Background:
                    {this.backgroundColor()}
                </div>
                <textarea 
                    className={styles.TextInput}
                    rows={7}
                    value={this.state.text}
                    onChange={(e)=>{this.setState({text: e.target.value})}}
                    />
            </div>
        );
    }

    fontSelect(){
        return (
            <select
                className={styles.FontSelect}
                value={this.state.font}
                onChange={(e)=>{this.setState({font: e.target.value})}}
                >
                {fonts.map((name)=><option key={name} value={name}>{name}</option>)}
            </select>
        );
    }

    fontSize(){
        return (
            <>
            {/* <div className={styles.Group}> */}
                <input
                    type="range"
                    value={this.state.size}
                    min={12}
                    max={1024}
                    onChange={(e)=>{this.setState({size: e.target.value})}}
                    />
                <input 
                    className={styles.FontSize}
                    type="number" 
                    value={this.state.size}
                    onChange={(e)=>{this.setState({size: e.target.value})}}
                    />
            {/* </div> */}
            </>
        );
    }

    fontStyle(){
        return (
            <div className={styles.Group}>
                <button
                    className={styles.StyleButton}
                    onClick={()=>{this.setState({isBold: !this.state.isBold})}}
                    >
                        {this.state.isBold ? <s><b>b</b></s> : <b>b</b>}
                    </button>
                <button
                    className={styles.StyleButton}
                    onClick={()=>{this.setState({isItalic: !this.state.isItalic})}}
                    >
                        {this.state.isItalic ? <s><i>i</i></s> : <i>i</i>}
                    </button>
            </div>
        );
    }

    textAlign(){
        return (
            <select
                value={this.state.textAlign}
                onChange={(e)=>{this.setState({textAlign: e.target.value})}}
                >
                <option value="left">Left</option>
                <option value="center">Center</option>
                <option value="right">Right</option>
            </select>
        );
    }

    fontColor(){
        return (
            <input
                type="color"
                value={this.state.color}
                onChange={(e)=>{this.setState({color: e.target.value})}}
                />
        );
    }

    backgroundColor(){
        return (
            <div className={styles.Group}>
                <input
                    type="checkbox"
                    checked={this.state.isBackground}
                    onChange={(e)=>{this.setState({isBackground: e.target.checked})}}
                    />
                <input
                    type="color"
                    value={this.state.background}
                    onChange={(e)=>{this.setState({background: e.target.value})}}
                    />
            </div>
        );
    }
}