import React from "react";
import { Link } from "react-router-dom";
import styles from "../LoginPage.module.css";

interface RLoginPageProps {
    serverUrl: string;
}

interface RLoginPageState {
    login: string;
    password: string;
};

export default class RLoginPage extends React.Component<RLoginPageProps, RLoginPageState> {

    state = {
        login: '',
        password: ''
    };

    onSubmit = async () => {
        try {
            const response = await fetch(this.props.serverUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username: this.state.login,
                    password: this.state.password
                })
            });

            if (!response.ok) {
                // console.error("Not ok: ", response.status);
                const data = await response.text();

                console.log(response.status, data);
                return;
            }

            const { token } = await response.json();

            localStorage.setItem('token', token);

            /// success!!!

            window.location.href = '/';
        } catch (error) {
            console.log("Error: ", error);
        }
    };

    render(){
        return (
            <div className={styles.Container}>
                <form className={styles.Form}
                    // onSubmit={this.onSubmit}>
                    onSubmit={(e)=>{e.preventDefault(); this.onSubmit();}}>
                <div className={styles.FormLogin}>
                    <label htmlFor="login">Login:</label>
                    <input
                        type="text"
                        id="login"
                        value={this.state.login}
                        onChange={(e)=>{this.setState({login: e.target.value})}}
                       />
                </div>
                <div className={styles.FormPassword}>
                    <label htmlFor="password">Password:</label>
                    <input
                    type="password"
                    id="password"
                    value={this.state.password}
                    onChange={(e)=>{this.setState({password: e.target.value})}}
                    />
                </div>
                <div className={styles.FormButtons}>
                    <button type="submit">Login</button>
                    <Link to="/"><button>Cancel</button></Link>
                </div>
                </form>
            </div>
        );
    }
}