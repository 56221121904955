
export default class ImageManager {
    static _instance?: ImageManager;

    static instance(){
        if (!this._instance) {
            this._instance = new ImageManager();
        }
        return this._instance;
    }

    images: string[] = [];
    names: string[] = [];

    onImageAdded?: (src: string)=>void;

    last() {
        const index = this.images.length - 1;
        return {
            src: this.images[index],
            name: this.names[index]
        };
    }

    addSource(src: string, name?: string){
        if (this.images.includes(src)) { return; }

        this.images.push(src);
        if (name) {
            this.names.push(name);
        } else {
            this.names.push("Image");
        }
        this.onImageAdded?.(src);
    }

    addFiles(files: FileList){
        for (let i = 0; i < files.length; ++i) {
            this.addFile(files[i]);
        }
    }

    addFile(file: File){
        const reader = new FileReader();
        reader.onload = (e) => {
            const result = e.target?.result as string;
            if (!result) { return; }
            this.addSource(result, file.name.replace(/\.[^/.]+$/, ''));
        };
        reader.readAsDataURL(file);
    }
}