import React, { createRef } from "react";
import { FilePlus, MinusSquare, PlusSquare, Trash2 } from "react-feather";
import PathCreator from "../2d/PathCreator";
import Popup from "../ui/Popup";
import { cloneModel, emptyModel, ModelInterface, PartInterface, TextureInterface } from "../utils/Types";
import styles from "./ModelAddPage.module.css";

interface ModelAddPageProps {
    serverUrl: string;
    onClose: ()=>void;
};

interface ModelAddPageState {
    model: ModelInterface;

    templateName: string;
    templateFile: string;
    
    partName: string;
    partFile: string;
    partPath: number[];

    selectPath: boolean;

    message: string;
    uploading: boolean;
};

export default class ModelAddPage extends React.Component<ModelAddPageProps, ModelAddPageState> {
    templateInputRef = createRef<HTMLInputElement>();
    partInputRef = createRef<HTMLInputElement>();

    xhr = new XMLHttpRequest();

    state = {
        model: emptyModel(),
        templateName: "",
        templateFile: "",
        partName: "",
        partFile: "",
        partPath: [],
        selectPath: false,
        message: '',
        uploading: false
    };

    files = new Map<string, File>();

    readImage = (file: File) => {
        return URL.createObjectURL(file);
    }

    sendForm(form: FormData){
        const token = localStorage.getItem('token');
        if (!token) { return; }

        this.setState({
            message: '',
            uploading: true
        });

        this.xhr.open('PUT', this.props.serverUrl);
        this.xhr.setRequestHeader('Authorization', `Bearer ${token}`);

        this.xhr.send(form);
    }

    componentDidMount(): void {
        const token = localStorage.getItem('token');
        if (!token) { 
            this.setState({message: 'Warning: No authorization token. Please, login.'}); 
        }

        this.xhr.upload.addEventListener('progress', this.onUploadProgress);

        this.xhr.addEventListener('load', this.onUploadFinish);
        this.xhr.addEventListener('error', this.onUploadError);
        this.xhr.addEventListener('abort', this.onUploadAbort);
    }

    componentWillUnmount(): void {
        this.xhr.upload.removeEventListener('progress', this.onUploadProgress);
        this.xhr.removeEventListener('load', this.onUploadFinish);
        this.xhr.removeEventListener('error', this.onUploadError);
        this.xhr.removeEventListener('abort', this.onUploadAbort);
    }

    onUploadProgress = (e: ProgressEvent<XMLHttpRequestEventTarget>) => {
        if (!e.lengthComputable) { return; }
        const percentComplete = (e.loaded / e.total) * 100;
        this.setState({message: `Upload progress: ${percentComplete.toFixed(2)}%`});
    };
    onUploadFinish = () => {
        if (this.xhr.status >= 200 && this.xhr.status < 300) {
            console.log('Response:', this.xhr.responseText);
            this.setState({
                uploading: false,
                message: "Upload complete successfully!"
            });
        } else {
            console.error('Request failed with status:', this.xhr.status);
            this.setState({
                uploading: false,
                message: `Failed to add model. Status: ${this.xhr.status}. Text: ${this.xhr.responseText}`
            });
        }
    };
    onUploadError = () => {
        this.setState({
            uploading: false,
            message: 'Error occurred during upload'
        });
    };
    onUploadAbort = () => {
        this.setState({
            uploading: false,
            message: 'Upload aborted'
        });
    };

    onUpload = async () => {
        if (this.state.uploading) { return; }
        console.log("Uploading: ", this.state.model);
        // valid?

        const form = new FormData();

        form.append('name', this.state.model.name);
        form.append('url', this.files.get(this.state.model.url)!);
        if (this.state.model.preview) {
            form.append('preview', this.files.get(this.state.model.preview)!);
        }
        this.state.model.textures.forEach((texture) => {
            // form.append('textureNames', texture.name);
            form.append('textures', this.files.get(texture.url)!);
        });
        form.append('parts', JSON.stringify(this.state.model.parts));
        this.state.model.overlays?.forEach((overlay)=>{
            // form.append('overlayNames', overlay.name);
            form.append('overlays', this.files.get(overlay.url)!);
        });

        // @ts-ignore
        console.log([...form]);

        this.sendForm(form);
        return;

        const token = localStorage.getItem('token');
        if (!token) { 
            console.error("No token founded, login first.");
            return; 
        }

        const response = await fetch(this.props.serverUrl, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: form
        });

        console.log(response);

        if (!response.ok) {
            const text = await response.text();
            this.setState({message: `Response not ok. Status ${response.status}. Data: ${text}`});
            return;
        } else {
            this.setState({message: "Model uploaded"});
        }
    };

    addTemplate = () => {
        const name = this.state.templateName;
        const file = this.state.templateFile;

        if (!name || !file) { return; }

        const model = cloneModel(this.state.model);
        model.textures.push({name, url: file});

        if (this.templateInputRef.current) {
            this.templateInputRef.current.value = "";
        }

        this.setState({
            model,
            templateName: "",
            templateFile: ""
        });
    };

    deleteTemplate = (index: number) => {
        const model = cloneModel(this.state.model);
        model.textures.splice(index, 1);
        this.setState({model});
    };

    addPart = () => {
        const name = this.state.partName;
        const file = this.state.partFile;
        const path = this.state.partPath;

        if (!name || !file || !path.length) { return; }

        const model = cloneModel(this.state.model);
        if (!model.parts) { model.parts = []; }

        const part: PartInterface = {
            name, path
        };

        const i = model.parts.length;
        model.parts.push(part);

        const overlay: TextureInterface = {
            name, url: file
        };

        if (!model.overlays) { model.overlays = [];}
        model.overlays.splice(i, 0, overlay);

        if (this.partInputRef.current) {
            this.partInputRef.current.value = '';
        }

        this.setState({
            partName: '',
            partFile: '',
            partPath: [],
            model
        });
    };

    selectPath = () => {
        this.setState({selectPath: true});
    };

    deletePart = (index: number) => {
        const model = cloneModel(this.state.model);
        model.overlays!.splice(index, 1);
        model.parts!.splice(index, 1);
        this.setState({model});
    };

    onPathConfirm = (path: number[]) => {
        this.setState({
            selectPath: false,
            partPath: path
        });
    };

    render(){
        return (
            <div className={styles.Main}>
                {this.titleBar()}
                <div style={{width: '80%', margin: '5px'}}>
                    {this.state.message}
                </div>
                <div className={styles.ModelForm}>
                    Model information:
                    {this.modelFile()}
                    Model base overlay:
                    {this.modelOverlay()}
                    Model templates:
                    {this.modelTemplates()}
                    Model parts:
                    {this.modelParts()}
                </div>
                {this.state.partFile && <Popup
                    onClose={()=>{this.setState({selectPath: false})}}
                    show={this.state.selectPath}>
                    <PathCreator 
                        src={this.readImage(this.files.get(this.state.partFile)!)}
                        path={this.state.partPath}
                        onConfirm={this.onPathConfirm}
                        />
                </Popup>}
            </div>
        );
    }

    titleBar(){
        return (
            <div className={styles.TitleBar}>
                <div className={styles.Close}
                    onClick={this.props.onClose}
                    >
                    <MinusSquare width={24} height={24} />
                    <u>Cancel</u>
                </div>
                <div className={styles.Title}>
                    Model data:
                </div>
                <div className={styles.Upload}
                    onClick={this.onUpload}
                    >
                    <u>Submit</u>
                    <PlusSquare width={24} height={24} />
                </div>
            </div>
        );
    }

    modelFile(){
        const previewImg = this.state.model.preview;
        const previewFile = previewImg ? this.files.get(previewImg) : undefined;
        return (
            <div className={styles.ModelData}>
                <div className={styles.ModelName}>
                    Name*:
                    <input 
                        className={styles.ModelNameInput}
                        placeholder="Model name"
                        value={this.state.model.name}
                        onChange={(e)=>{
                            const c = cloneModel(this.state.model);
                            c.name = e.target.value;
                            this.setState({model: c})
                        }}
                        />
                </div>
                <div className={styles.ModelFile}>
                    .FBX*: 
                    <input
                        className={styles.ModelFileInput}
                        type="file"
                        accept=".fbx"
                        onChange={(e) => {
                            const file = e.target.files?.[0];
                            if (file) {
                                this.files.set(file.name, file);
                            }
                            const model = cloneModel(this.state.model);
                            model.url = file?.name ?? '';
                            this.setState({model});
                        }}
                        />
                </div>
                <div className={styles.ModelPreview}>
                    Preview image: 
                    <input 
                        className={styles.ModelPreviewInput}
                        type="file"
                        accept=".jpg,.jpeg,.png"
                        onChange={async (e)=>{
                            const file = e.target.files?.[0];
                            if (file) {
                                this.files.set(file.name, file);
                            }
                            const model = cloneModel(this.state.model);
                            model.preview = file?.name;
                            this.setState({model});
                        }}
                        />
                </div>
                <div className={styles.ModelPreviewImage}>
                    {previewFile && <img 
                        src={this.readImage(previewFile)}
                        />}
                </div>
            </div>
        );
    }

    modelOverlay(){
        let overlayImg = '';
        if (this.state.model.overlays) {
            if (this.state.model.parts) {
                if (this.state.model.parts.length === this.state.model.overlays.length) {}
                else {
                    overlayImg = this.state.model.overlays[this.state.model.overlays.length - 1].url;
                }
            } else {
                overlayImg = this.state.model.overlays[this.state.model.overlays.length - 1]?.url ?? '';
            }
        }
        const overlayFile = overlayImg ? this.files.get(overlayImg) : undefined;
        return (
            <div className={styles.ModelOverlay}>
                <div className={styles.ModelOverlayName}>
                    Name*: 
                    <input
                        className={styles.ModelOverlayNameInput}
                        placeholder="Overlay name"
                        onChange={(e)=>{
                            const model = cloneModel(this.state.model);
                            if (model.overlays && model.overlays.length > 0) {
                                if (model.parts && model.parts.length) {
                                    if (model.overlays.length === model.parts.length) {
                                        const overlay = {
                                            name: e.target.value,
                                            url: ''
                                        };
                                        model.overlays.push(overlay);
                                    } else {
                                        const overlay = model.overlays[model.overlays.length - 1];
                                        overlay.name = e.target.value;
                                    }
                                } else {
                                    const overlay = model.overlays[model.overlays.length - 1];
                                    overlay.name = e.target.value;
                                }
                            } else {
                                const overlay = {
                                    name: e.target.value,
                                    url: ''
                                };
                                model.overlays = [overlay];
                            }
                            this.setState({model});
                        }}
                        />
                </div>
                <div className={styles.ModelOverlayFile}>
                    Image file*:
                    <input
                        className={styles.ModelOverlayFileInput}
                        type="file"
                        accept=".jpg,.jpeg,.png"
                        onChange={async (e)=>{
                            const file = e.target.files?.[0];
                            if (file) {
                                this.files.set(file.name, file);
                            }
                            const src = file ? file.name : '';
                            const model = cloneModel(this.state.model);
                            if (model.overlays && model.overlays.length > 0) {
                                if (model.parts && model.parts.length) {
                                    if (model.overlays.length === model.parts.length) {
                                        const overlay = {
                                            name: '',
                                            url: src
                                        };
                                        model.overlays.push(overlay);
                                    } else {
                                        const overlay = model.overlays[model.overlays.length - 1];
                                        overlay.url = src;
                                    }
                                } else {
                                    const overlay = model.overlays[model.overlays.length - 1];
                                    overlay.url = src;
                                }
                                const overlay = model.overlays[model.overlays.length - 1];
                                overlay.url = src;
                            } else {
                                const overlay = {
                                    name: '',
                                    url: src
                                };
                                model.overlays = [overlay];
                            }
                            this.setState({model});
                        }}
                        />
                </div>
                <div className={styles.ModelOverlayImage}>
                    {overlayFile && <img 
                        src={this.readImage(overlayFile)}
                        />}
                </div>
            </div>
        );
    }

    modelTemplates(){
        const templateImg = this.state.templateFile;
        const templateFile = templateImg ? this.files.get(templateImg) : undefined;
        return (
            <div className={styles.ModelTemplates}>
                <div className={styles.ModelTemplateAdd}>
                    Add template:
                    <div className={styles.ModelTemplateForm}>
                        <div className={styles.ModelTemplateName}>
                            Name*:
                            <input 
                                className={styles.ModelTemplateNameInput}
                                placeholder="Template name"
                                value={this.state.templateName}
                                onChange={(e) => {this.setState({templateName: e.target.value})}}
                                />
                        </div>
                        <div className={styles.ModelTemplateFile}>
                            Image file*:
                            <input 
                                className={styles.ModelTemplateFileInput}
                                ref={this.templateInputRef}
                                type="file"
                                accept=".jpg,.jpeg,.png"
                                onChange={async (e)=>{
                                    const file = e.target.files?.[0];
                                    if (file) {
                                        this.files.set(file.name, file);
                                    }
                                    const src = file ? file.name : '';
                                    this.setState({templateFile: src});
                                }}
                                />
                        </div>
                        <div className={styles.ModelTemplateImage}>
                            {templateFile && <img 
                                src={this.readImage(templateFile)}
                                />}
                        </div>
                        <div className={styles.ModelTemplateAddButton}
                            onClick={this.addTemplate}
                            >
                            <FilePlus width={24} height={24} />
                            <u>Add</u>
                        </div>
                    </div>
                </div>
                <div className={styles.ModelTexturesGrid}>
                    {this.state.model.textures.length === 0
                    ? `No templates added`
                    : this.state.model.textures.map(
                        (texture, index) => { 
                            const {name, url} = texture;
                            const file = this.files.get(url);
                            const src = this.readImage(file!);
                            return (<ModelTextureItem 
                                key={`template-${index}`} 
                                name={name}
                                src={src}
                                index={index} 
                                onDelete={this.deleteTemplate}
                                />)})
                    }
                </div>
            </div>
        );
    }

    modelParts(){
        const partImg = this.state.partFile;
        const partFile = partImg ? this.files.get(partImg) : undefined;
        return (
            <div className={styles.ModelParts}>
                <div className={styles.ModelPartAdd}>
                    Add part:
                    <div className={styles.ModelPartForm}>
                        <div className={styles.ModelPartName}>
                            Name*:
                            <input 
                                className={styles.ModelPartNameInput}
                                placeholder="Part name"
                                value={this.state.partName}
                                onChange={(e) => {this.setState({partName: e.target.value})}}
                                />
                        </div>
                        <div className={styles.ModelPartFile}>
                            Image file*:
                            <input 
                                className={styles.ModelPartFileInput}
                                ref={this.partInputRef}
                                type="file"
                                accept=".jpg,.jpeg,.png"
                                onChange={async (e)=>{
                                    const file = e.target.files?.[0];
                                    if (file) {
                                        this.files.set(file.name, file);
                                    }
                                    const src = file ? file.name : '';
                                    this.setState({partFile: src});
                                }}
                                />
                        </div>
                        <div className={styles.ModelPartImage}>
                            {partFile && 
                            <div className={styles.ModelPartPath}
                                onClick={this.selectPath}
                                >
                                {this.state.partPath.length === 0
                                ? <u>Select path*</u>
                                : <u>Change path</u>}
                            </div>}
                            {partFile && <img 
                                src={this.readImage(partFile)}
                                />}
                        </div>
                        <div className={styles.ModelPartAddButton}
                            onClick={this.addPart}
                            >
                            <FilePlus width={24} height={24} />
                            <u>Add</u>
                        </div>
                    </div>
                </div>
                <div className={styles.ModelTexturesGrid}>
                    {this.state.model.parts && this.state.model.parts.length > 0
                    ? this.state.model.parts.map(
                        (part, index) => { 
                            const name = part.name;
                            const {url} = this.state.model.overlays![index];
                            const file = this.files.get(url);
                            const src = this.readImage(file!);
                            return (<ModelTextureItem 
                                key={`part-${index}`} 
                                index={index} 
                                src={src} 
                                name={name} 
                                onDelete={this.deletePart}
                                />)})
                    : `No parts added`}
                </div>
            </div>
        );
    }
}

class ModelTextureItem extends React.Component<{
    name: string;
    src: string;
    index: number;
    onDelete: (index: number) => void;
}> {
    render(){
        return (
            <div className={styles.ModelTextureItem}>
                <div className={styles.TextureRemove}
                    onClick={()=>{this.props.onDelete(this.props.index)}}>
                    <Trash2 width={20} height={20} />
                </div>
                <div className={styles.TextureImage} >
                    <img 
                        src={this.props.src}
                        />
                </div>
                <div className={styles.TextureName}>
                    {this.props.name}
                </div>
            </div>
        );
    }
}