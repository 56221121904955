import * as THREE from 'three';

/** 
 * OBJExporter for Three.js with MTL support
 * 
 * Based on OBJExporter from
 * https://github.com/GitHubDragonFly/GitHubDragonFly.github.io/blob/5dcb6d313aa73373d54997e616d5ade8168c408d/viewers/static/js/exporters/OBJExporter.js
 */

export default class OBJExporter {
    output = '';
    indexVertex = 0;
    indexVertexUvs = 0;
    indexNormals = 0;
    mesh_count = 0;
    line_count = 0;
    points_count = 0;
    materials: {[key: string]: THREE.Material | THREE.Material[]} = {};
    material_names: string[] = [];
    material_colors: {[key: string]: THREE.Color} = {};
    vertex = new THREE.Vector3();
    color = new THREE.Color();
    normal = new THREE.Vector3();
    uv = new THREE.Vector2();
    face: string[] = [];
    canvas?: HTMLCanvasElement;

    mtlOutput: string = "";
    textures: {name: string, ext: string, data: Uint8Array}[] = [];
    mtlNames: string[] = [];
    map_uuids: string[] = [];
    map_names: {[key: string]: string} = {};
    mtlCount: number = 1;

    parse(object: THREE.Object3D, filename: string) {
        object.traverse( ( child ) => {
            if ( (child as THREE.Mesh).isMesh === true ) {
                this.parseMesh( child as THREE.Mesh );
            }
            if ( (child as THREE.Line).isLine === true ) {
                this.parseLine( (child as THREE.Line) );
            }
            if ( (child as THREE.Points).isPoints === true ) {
                this.parsePoints( child as THREE.Points );
            }
        } );

        if (Object.keys( this.materials ).length !== 0) {
            // mtl output (Ref: https://stackoverflow.com/questions/35070048/export-a-three-js-textured-model-to-a-obj-with-mtl-file)

            this.output = 'mtllib ' + filename + '.mtl' + '\n' + this.output; // add name of the material library

            this.mtlOutput = '# MTL file - created by a modified three.js OBJExporter' + '\n';
            this.textures = [];
            this.mtlNames = [];
            this.map_uuids = [];
            this.map_names = {};
            this.mtlCount = 1;

            Object.keys( this.materials ).forEach( ( key ) => {

                const material = this.materials[ key ];
                if ( Array.isArray( material )) {
                    material.forEach( ( mtl ) => {
                        this.parseMaterial(mtl);
                    });

                } else {
                    this.parseMaterial(material);
                }
            });
            return { obj: this.output, mtl: this.mtlOutput, tex: this.textures };
        } else {
            return { obj: this.output };
        }
    }

    parseMesh( mesh: THREE.Mesh ) {
        let nbVertex = 0;
        let nbNormals = 0;
        let nbVertexUvs = 0;
        let multi_materials: {[key: number]: string} = {};
        const geometry = mesh.geometry;
        const normalMatrixWorld = new THREE.Matrix3();

        if ( geometry.isBufferGeometry !== true ) {
            throw new Error( 'OBJExporter: Geometry is not of type THREE.BufferGeometry.' );
        } 

        // shortcuts
        const groups = geometry.groups;
        const vertex_colors = geometry.getAttribute( 'color' );
        const vertices = geometry.getAttribute( 'position' );
        const normals = geometry.getAttribute( 'normal' );
        const uvs = geometry.getAttribute( 'uv' );
        const indices = geometry.getIndex(); 

        // name of the mesh object
        if (mesh.name === '') {
            mesh[ 'name' ] = 'mesh_' + this.mesh_count;
        } else {
            mesh.name = mesh.name.replace( '#', '' );
            mesh.name = mesh.name.replace( ' ', '_' );
        }

        this.output += 'o ' + mesh.name + '\n'; 

        // name of the mesh material
        if (mesh.material) {
            if (Array.isArray(mesh.material)) {
                this.materials[ 'multi_' + mesh.name ] = mesh.material;

                if ( groups !== undefined ) {
                    let mesh_group_material_count = 0;

                    for ( let i = 0, l = groups.length; i < l; i ++ ) {
                        let materialIndex = groups[i].materialIndex!;
                        
                        if (mesh.material[ materialIndex ].name === '') {
                            mesh.material[ materialIndex ][ 'name' ] = 'mesh_group_material_' + this.mesh_count + '_' + mesh_group_material_count;
                            mesh_group_material_count += 1;
                        } else if ( mesh.material[ materialIndex ].name.toUpperCase().endsWith( '.PNG' ) || mesh.material[ materialIndex ].name.toUpperCase().endsWith( '.JPG' ) ) {
                            mesh.material[ materialIndex ][ 'name' ] = mesh.material[ materialIndex ].name.substring( 0, mesh.material[ materialIndex ].name.lastIndexOf( '.' ) );
                        }

                        mesh.material[ materialIndex ].name = mesh.material[ materialIndex ].name.replace( '#', '' );
                        mesh.material[ materialIndex ].name = mesh.material[ materialIndex ].name.replace( ' ', '_' );
                        multi_materials[ groups[ i ].start ] = mesh.material[ materialIndex ].name;
                    }
                } else {
                    this.output += 'usemtl multi_' + mesh.name + '\n';
                }
            } else if (mesh.material.name) {
                if (mesh.material.name === '') {
                    mesh.material[ 'name' ] = 'mesh_material_' + this.mesh_count;
                } else if ( mesh.material.name.toUpperCase().endsWith( '.PNG' ) || mesh.material.name.toUpperCase().endsWith( '.JPG' ) ) {
                    mesh.material[ 'name' ] = mesh.material.name.substring( 0, mesh.material.name.lastIndexOf( '.' ) );
                }
    
                mesh.material.name = mesh.material.name.replace( '#', '' );
                mesh.material.name = mesh.material.name.replace( ' ', '_' );
    
                let temp_name = mesh.material.name;
    
                if (this.material_names.includes(temp_name) === false || this.material_colors[ temp_name ] !== (mesh.material as THREE.MeshBasicMaterial).color) {
                    if (this.material_colors[ temp_name ] !== (mesh.material as THREE.MeshBasicMaterial).color) { 
                        mesh.material.name = temp_name; 
                    }
    
                    this.material_names.push( mesh.material.name );
                    this.material_colors[ mesh.material.name ] = (mesh.material as THREE.MeshBasicMaterial).color;
                }
    
                this.output += 'usemtl ' + mesh.material.name + '\n';
                this.materials[ mesh.material.name ] = mesh.material;
            } else if (vertex_colors === undefined) {
                this.output += 'usemtl material' + mesh.material.id + '\n';
                this.materials[ 'material' + mesh.material.id ] = mesh.material;
            }  
        }

        // vertices
        if ( vertices !== undefined ) {
            for ( let i = 0, l = vertices.count; i < l; i ++, nbVertex ++ ) {
                this.vertex.x = vertices.getX( i );
                this.vertex.y = vertices.getY( i );
                this.vertex.z = vertices.getZ( i ); // transform the vertex to world space

                this.vertex.applyMatrix4( mesh.matrixWorld ); // transform the vertex to export format

                if ( vertex_colors ) {
                    this.output += 'v ' + this.vertex.x + ' ' + this.vertex.y + ' ' + this.vertex.z + ' ' + vertex_colors.getX( i ) + ' ' + vertex_colors.getY( i ) + ' ' + vertex_colors.getZ( i ) + '\n';
                } else {
                    this.output += 'v ' + this.vertex.x + ' ' + this.vertex.y + ' ' + this.vertex.z + '\n';
                }
            }
        } 

        // uvs
        if ( uvs !== undefined ) {
            for ( let i = 0, l = uvs.count; i < l; i ++, nbVertexUvs ++ ) {
                this.uv.x = uvs.getX( i );
                this.uv.y = uvs.getY( i ); // transform the uv to export format

                this.output += 'vt ' + this.uv.x + ' ' + this.uv.y + '\n';
            }
        } 

        // normals
        if ( normals !== undefined ) {
            normalMatrixWorld.getNormalMatrix( mesh.matrixWorld );

            for ( let i = 0, l = normals.count; i < l; i ++, nbNormals ++ ) {
                this.normal.x = normals.getX( i );
                this.normal.y = normals.getY( i );
                this.normal.z = normals.getZ( i ); // transform the normal to world space

                this.normal.applyMatrix3( normalMatrixWorld ).normalize(); // transform the normal to export format

                this.output += 'vn ' + this.normal.x + ' ' + this.normal.y + ' ' + this.normal.z + '\n';
            }
        } 

        // faces
        if ( indices !== null ) {
            for ( let i = 0, l = indices.count; i < l; i += 3 ) {
                Object.keys( multi_materials ).forEach( ( key ) => {
                    if ( parseInt( key ) === i ) {
                        this.output += 'usemtl ' + multi_materials[ i ] + '\n';
                    }
                });

                for ( let m = 0; m < 3; m ++ ) {
                    const j = indices.getX( i + m ) + 1;
                    this.face[ m ] = this.indexVertex + j + ( normals || uvs ? '/' + ( uvs ? this.indexVertexUvs + j : '' ) + ( normals ? '/' + ( this.indexNormals + j ) : '' ) : '' );
                } 

                // transform the face to export format
                this.output += 'f ' + this.face.join( ' ' ) + '\n';

            }
        } else {
            for ( let i = 0, l = vertices.count; i < l; i += 3 ) {
                Object.keys( multi_materials ).forEach( ( key ) => {
                    if ( parseInt( key ) === i ) {
                        this.output += 'usemtl ' + multi_materials[ i ] + '\n';
                    }

                });

                for ( let m = 0; m < 3; m ++ ) {
                    const j = i + m + 1;
                    this.face[ m ] = this.indexVertex + j + ( normals || uvs ? '/' + ( uvs ? this.indexVertexUvs + j : '' ) + ( normals ? '/' + ( this.indexNormals + j ) : '' ) : '' );

                } 

                // transform the face to export format
                this.output += 'f ' + this.face.join( ' ' ) + '\n';
            }
        } 

        // update index
        this.indexVertex += nbVertex;
        this.indexVertexUvs += nbVertexUvs;
        this.indexNormals += nbNormals;

        this.mesh_count += 1;
    }

    parseLine( line: THREE.Line ) {
        let nbVertex = 0;
        const geometry = line.geometry;
        const type = line.type;

        if ( geometry.isBufferGeometry !== true ) {
            throw new Error( 'THREE.OBJExporter: Geometry is not of type THREE.BufferGeometry.' );
        }

        // shortcuts
        const vertices = geometry.getAttribute( 'position' );
        
        // name of the line object
        this.output += 'o ' + line.name + '\n';

        if ( line.material && !Array.isArray(line.material) ) {
            if ( line.material.name ) {
                if ( line.material.name === '' ) line.material.name = 'line_material_' + this.line_count;
            } else {
                line.material[ 'name' ] = 'line_material_' + this.line_count;
            }

            this.materials[ line.material.name ] = line.material;
            this.output += 'usemtl ' + line.material.name + '\n';
            this.line_count += 1;
        }

        if ( vertices !== undefined ) {
            for ( let i = 0, l = vertices.count; i < l; i ++, nbVertex ++ ) {
                this.vertex.x = vertices.getX( i );
                this.vertex.y = vertices.getY( i );
                this.vertex.z = vertices.getZ( i );

                // transform the vertex to world space
                this.vertex.applyMatrix4( line.matrixWorld );

                // transform the vertex to export format
                this.output += 'v ' + this.vertex.x + ' ' + this.vertex.y + ' ' + this.vertex.z + '\n';
            }
        }

        if ( type === 'Line' ) {
            this.output += 'l ';

            for ( let j = 1, l = vertices.count; j <= l; j ++ ) {
                this.output += this.indexVertex + j + ' ';
            }

            this.output += '\n';
        }

        if ( type === 'LineSegments' ) {
            for ( let j = 1, k = j + 1, l = vertices.count; j < l; j += 2, k = j + 1 ) {
                this.output += 'l ' + ( this.indexVertex + j ) + ' ' + ( this.indexVertex + k ) + '\n';
            }

        } 

        // update index
        this.indexVertex += nbVertex;
    }

    parsePoints( points: THREE.Points ) {
        let nbVertex = 0;
        const geometry = points.geometry;

        if ( geometry.isBufferGeometry !== true ) {
            throw new Error( 'THREE.OBJExporter: Geometry is not of type THREE.BufferGeometry.' );
        }

        const vertices = geometry.getAttribute( 'position' );
        const colors = geometry.getAttribute( 'color' );
        this.output += 'o ' + points.name + '\n';

        if ( vertices !== undefined ) {
            for ( let i = 0, l = vertices.count; i < l; i ++, nbVertex ++ ) {
                this.vertex.fromBufferAttribute( vertices, i );
                this.vertex.applyMatrix4( points.matrixWorld );
                this.output += 'v ' + this.vertex.x + ' ' + this.vertex.y + ' ' + this.vertex.z;

                if ( colors !== undefined ) {
                    this.color.fromBufferAttribute( colors, i ).convertLinearToSRGB();
                    this.output += ' ' + this.color.r + ' ' + this.color.g + ' ' + this.color.b;
                }

                this.output += '\n';
            }

            this.output += 'p ';

            for ( let j = 1, l = vertices.count; j <= l; j ++ ) {
                this.output += this.indexVertex + j + ' ';
            }

            this.output += '\n';
        } 
        // update index
        this.indexVertex += nbVertex;

        if ( points.material && !Array.isArray(points.material) ) {
            if ( points.material.name ) {
                if ( points.material.name === '' ) points.material.name = 'points_material_' + this.points_count;
            } else {
                points.material[ 'name' ] = 'points_material_' + this.points_count;
            }

            this.materials[ points.material.name ] = points.material;

            this.output += 'usemtl ' + points.material.name + '\n';

            this.points_count += 1;
        }
    }

    parseMaterial( mat: THREE.Material ){
        const basic = mat as THREE.MeshBasicMaterial;
        const standart = mat as THREE.MeshStandardMaterial;
        const physics = mat as THREE.MeshPhysicalMaterial;
        const phong = mat as THREE.MeshPhongMaterial;

        // let names = this.mtlNames!;
        // let mtlOutput = this.mtlOutput!;
        // let map_uuids = this.map_uuids!;
        // let map_names = this.map_names!;
        // let textures = this.textures!;
        // let count = this.mtlCount!;
        const ext = 'png';

        let name = ( mat.name && mat.name !== '' ) ? ( ( mat.name.toUpperCase().endsWith( '.PNG' ) || mat.name.toUpperCase().endsWith( '.JPG' ) ) ? mat.name.substring(0, mat.name.lastIndexOf( '.' ) ) : mat.name ) : 'material' + mat.id;
        name = name.replace( '#', '' );
        name = name.replace( ' ', '_' );

        if ( this.mtlNames.includes( name ) === false ) {

            this.mtlNames.push( name );

            let transparency = ( mat.opacity < 1 ) ? ( 1 - mat.opacity ) : '0.0000';

            this.mtlOutput += '\n' + 'newmtl ' + name + '\n';

            this.mtlOutput += 'Tr ' + transparency + '\n';
            this.mtlOutput += 'Tf 1.0000 1.0000 1.0000\n';
            this.mtlOutput += 'illum 1\n';
            if ( phong.specular ) this.mtlOutput += 'Ks ' + phong.specular.r + ' ' + phong.specular.g + ' ' + phong.specular.b + '\n';
            if ( phong.shininess ) this.mtlOutput += 'Ns ' + phong.shininess + '\n';
            if ( basic.refractionRatio ) this.mtlOutput += 'Ni ' + basic.refractionRatio + '\n';
            if ( standart.metalness ) this.mtlOutput += 'Pm ' + standart.metalness + '\n';
            if ( standart.roughness ) this.mtlOutput += 'Pr ' + standart.roughness + '\n';
            if ( standart.displacementBias ) this.mtlOutput += 'Pdb ' + standart.displacementBias + '\n';
            if ( standart.displacementScale ) this.mtlOutput += 'Pds ' + standart.displacementScale + '\n';
            if ( basic.lightMapIntensity ) this.mtlOutput += 'Pl ' + basic.lightMapIntensity + '\n';
            if ( physics.clearcoat ) this.mtlOutput += 'Pcc ' + physics.clearcoat + '\n';
            if ( physics.clearcoatRoughness ) this.mtlOutput += 'Pccr ' + physics.clearcoatRoughness + '\n';
            if ( physics.clearcoatNormalScale ) this.mtlOutput += 'Pccns ' + physics.clearcoatNormalScale.x + physics.clearcoatNormalScale.y + '\n';
            if ( basic.reflectivity ) this.mtlOutput += 'Prfl ' + basic.reflectivity + '\n';
            if ( physics.ior ) this.mtlOutput += 'Pior ' + physics.ior + '\n';
            if ( physics.sheen ) this.mtlOutput += 'Psh ' + physics.sheen + '\n';
            if ( physics.sheenColor ) this.mtlOutput += 'Pshc ' + physics.sheenColor.r + ' ' + physics.sheenColor.g + ' ' + physics.sheenColor.b + '\n';
            if ( physics.sheenRoughness ) this.mtlOutput += 'Pshr ' + physics.sheenRoughness + '\n';
            if ( physics.specularIntensity ) this.mtlOutput += 'Psi ' + physics.specularIntensity + '\n';
            if ( physics.specularColor ) this.mtlOutput += 'Psc ' + physics.specularColor.r + ' ' + physics.specularColor.g + ' ' + physics.specularColor.b + '\n';
            if ( physics.thickness ) this.mtlOutput += 'Pth ' + physics.thickness + '\n';
            if ( physics.transmission ) this.mtlOutput += 'Ptr ' + physics.transmission + '\n';
            this.mtlOutput += basic.aoMapIntensity ? 'Ka ' + basic.aoMapIntensity + ' ' + basic.aoMapIntensity + ' ' + basic.aoMapIntensity + '\n' : 'Ka 1 1 1\n';
            this.mtlOutput += basic.color ? 'Kd ' + basic.color.r + ' ' + basic.color.g + ' ' + basic.color.b + '\n' : 'Kd 1 1 1\n';
            this.mtlOutput += standart.emissive ? 'Ke ' + standart.emissive.r + ' ' + standart.emissive.g + ' ' + standart.emissive.b + '\n' : 'Ke 0 0 0\n';

            if ( basic.map && basic.map.type === 1009 && basic.map.image ) {
                if ( this.map_uuids.includes( basic.map.uuid ) === false ) {
                    this.map_uuids.push( basic.map.uuid );
                    this.map_names[ basic.map.uuid ] = name;

                    this.textures.push( {
                        name,
                        ext,
                        data: this.imageToData( basic.map.image, ext )
                    });

                    this.mtlOutput += 'map_Kd ' + name + '.png' + '\n';
                } else {
                    this.mtlOutput += 'map_Kd ' + this.map_names[ basic.map.uuid ] + '.png' + '\n';
                }
            }

            if ( basic.specularMap && basic.specularMap.type === 1009 && basic.specularMap.image ) {
                if ( this.map_uuids.includes( basic.specularMap.uuid ) === false ) {

                    name = 'specularMap' + this.mtlCount;

                    this.map_uuids.push( basic.specularMap.uuid );
                    this.map_names[ basic.specularMap.uuid ] = name;

                    this.textures.push( {
                        name,
                        ext,
                        data: this.imageToData( basic.specularMap.image, ext )
                    });

                    this.mtlOutput += 'map_Ks ' + name + '.png' + '\n';

                } else {

                    this.mtlOutput += 'map_Ks ' + this.map_names[ basic.specularMap.uuid ] + '.png' + '\n';

                }
            }

            if ( standart.emissiveMap && standart.emissiveMap.type === 1009 && standart.emissiveMap.image ) {
                if ( this.map_uuids.includes( standart.emissiveMap.uuid ) === false ) {

                    name = 'emissiveMap' + this.mtlCount;

                    this.map_uuids.push( standart.emissiveMap.uuid );
                    this.map_names[ standart.emissiveMap.uuid ] = name;

                    this.textures.push( {
                        name,
                        ext,
                        data: this.imageToData( standart.emissiveMap.image, ext )
                    });

                    this.mtlOutput += 'map_Ke ' + name + '.png' + '\n';

                } else {

                    this.mtlOutput += 'map_Ke ' + this.map_names[ standart.emissiveMap.uuid ] + '.png' + '\n';

                }
            }

            if ( standart.bumpMap && standart.bumpMap.type === 1009 && standart.bumpMap.image ) {
                if ( this.map_uuids.includes( standart.bumpMap.uuid ) === false ) {

                    name = 'bumpMap' + this.mtlCount;

                    this.map_uuids.push( standart.bumpMap.uuid );
                    this.map_names[ standart.bumpMap.uuid ] = name;

                    this.textures.push( {
                        name,
                        ext,
                        data: this.imageToData( standart.bumpMap.image, ext )
                    });

                    if ( standart.bumpScale === 1 ) {

                        this.mtlOutput += 'map_bump ' + name + '.png' + '\n';

                    } else {

                        this.mtlOutput += 'map_bump -bm ' + standart.bumpScale + ' ' + name + '.png' + '\n';

                    }

                } else {

                    if ( standart.bumpScale === 1 ) {

                        this.mtlOutput += 'map_bump ' + this.map_names[ standart.bumpMap.uuid ] + '.png' + '\n';

                    } else {

                        this.mtlOutput += 'map_bump -bm ' + standart.bumpScale + ' ' + this.map_names[ standart.bumpMap.uuid ] + '.png' + '\n';

                    }

                }
            }

            if ( phong.lightMap && phong.lightMap.type === 1009 && phong.lightMap.image ) {
                if ( this.map_uuids.includes( phong.lightMap.uuid ) === false ) {

                    name = 'lightMap' + this.mtlCount;

                    this.map_uuids.push( phong.lightMap.uuid );
                    this.map_names[ phong.lightMap.uuid ] = name;

                    this.textures.push( {
                        name,
                        ext,
                        data: this.imageToData( phong.lightMap.image, ext )
                    });

                    this.mtlOutput += 'map_Pl ' + name + '.png' + '\n';

                } else {

                    this.mtlOutput += 'map_Pl ' + this.map_names[ phong.lightMap.uuid ] + '.png' + '\n';

                }
            }

            if ( standart.metalnessMap && standart.metalnessMap.type === 1009 && standart.metalnessMap.image ) {
                if ( this.map_uuids.includes( standart.metalnessMap.uuid ) === false ) {

                    name = 'metalnessMap' + this.mtlCount;

                    this.map_uuids.push( standart.metalnessMap.uuid );
                    this.map_names[ standart.metalnessMap.uuid ] = name;

                    this.textures.push( {
                        name,
                        ext,
                        data: this.imageToData( standart.metalnessMap.image, ext )
                    });

                    this.mtlOutput += 'map_Pm ' + name + '.png' + '\n';

                } else {

                    this.mtlOutput += 'map_Pm ' + this.map_names[ standart.metalnessMap.uuid ] + '.png' + '\n';

                }
            }

            if ( standart.roughnessMap && standart.roughnessMap.type === 1009 && standart.roughnessMap.image ) {
                if ( this.map_uuids.includes( standart.roughnessMap.uuid ) === false ) {

                    name = 'roughnessMap' + this.mtlCount;

                    this.map_uuids.push( standart.roughnessMap.uuid );
                    this.map_names[ standart.roughnessMap.uuid ] = name;

                    this.textures.push( {
                        name,
                        ext,
                        data: this.imageToData( standart.roughnessMap.image, ext )
                    });

                    this.mtlOutput += 'map_Pr ' + name + '.png' + '\n';

                } else {

                    this.mtlOutput += 'map_Pr ' + this.map_names[ standart.roughnessMap.uuid ] + '.png' + '\n';

                }
            }

            if ( standart.displacementMap && standart.displacementMap.type === 1009 && standart.displacementMap.image ) {
                if ( this.map_uuids.includes( standart.displacementMap.uuid ) === false ) {

                    name = 'displacementMap' + this.mtlCount;

                    this.map_uuids.push( standart.displacementMap.uuid );
                    this.map_names[ standart.displacementMap.uuid ] = name;

                    this.textures.push( {
                        name,
                        ext,
                        data: this.imageToData( standart.displacementMap.image, ext )
                    });

                    this.mtlOutput += 'map_Pd ' + name + '.png' + '\n';

                } else {

                    this.mtlOutput += 'map_Pd ' + this.map_names[ standart.displacementMap.uuid ] + '.png' + '\n';

                }
            }

            if ( standart.normalMap && standart.normalMap.type === 1009 && standart.normalMap.image ) {
                if ( this.map_uuids.includes( standart.normalMap.uuid ) === false ) {

                    name = 'normalMap' + this.mtlCount;

                    this.map_uuids.push( standart.normalMap.uuid );
                    this.map_names[ standart.normalMap.uuid ] = name;

                    this.textures.push( {
                        name,
                        ext,
                        data: this.imageToData( standart.normalMap.image, ext )
                    });

                    this.mtlOutput += 'norm ' + name + '.png' + '\n';

                } else {

                    this.mtlOutput += 'norm ' + this.map_names[ standart.normalMap.uuid ] + '.png' + '\n';

                }
            }

            if ( standart.alphaMap && standart.alphaMap.type === 1009 && standart.alphaMap.image ) {
                if ( this.map_uuids.includes( standart.alphaMap.uuid ) === false ) {

                    name = 'alphaMap' + this.mtlCount;

                    this.map_uuids.push( standart.alphaMap.uuid );
                    this.map_names[ standart.alphaMap.uuid ] = name;

                    this.textures.push( {
                        name,
                        ext,
                        data: this.imageToData( standart.alphaMap.image, ext )
                    });

                    this.mtlOutput += 'map_d ' + name + '.png' + '\n';

                } else {

                    this.mtlOutput += 'map_d ' + this.map_names[ standart.alphaMap.uuid ] + '.png' + '\n';

                }
            }

            if ( standart.aoMap && standart.aoMap.type === 1009 && standart.aoMap.image ) {
                if ( this.map_uuids.includes( standart.aoMap.uuid ) === false ) {

                    name = 'ambientMap' + this.mtlCount;

                    this.map_uuids.push( standart.aoMap.uuid );
                    this.map_names[ standart.aoMap.uuid ] = name;

                    this.textures.push( {
                        name,
                        ext,
                        data: this.imageToData( standart.aoMap.image, ext )
                    });

                    this.mtlOutput += 'map_Ka ' + name + '.png' + '\n';

                } else {

                    this.mtlOutput += 'map_Ka ' + this.map_names[ standart.aoMap.uuid ] + '.png' + '\n';

                }
            }

            if ( physics.clearcoatMap && physics.clearcoatMap.type === 1009 && physics.clearcoatMap.image ) {
                if ( this.map_uuids.includes( physics.clearcoatMap.uuid ) === false ) {

                    name = 'clearcoatMap' + this.mtlCount;

                    this.map_uuids.push( physics.clearcoatMap.uuid );
                    this.map_names[ physics.clearcoatMap.uuid ] = name;

                    this.textures.push( {
                        name,
                        ext,
                        data: this.imageToData( physics.clearcoatMap.image, ext )
                    });

                    this.mtlOutput += 'map_Pccm ' + name + '.png' + '\n';

                } else {

                    this.mtlOutput += 'map_Pccm ' + this.map_names[ physics.clearcoatMap.uuid ] + '.png' + '\n';

                }
            }

            if ( physics.clearcoatNormalMap && physics.clearcoatNormalMap.type === 1009 && physics.clearcoatNormalMap.image ) {
                if ( this.map_uuids.includes( physics.clearcoatNormalMap.uuid ) === false ) {

                    name = 'clearcoatNormalMap' + this.mtlCount;

                    this.map_uuids.push( physics.clearcoatNormalMap.uuid );
                    this.map_names[ physics.clearcoatNormalMap.uuid ] = name;

                    this.textures.push( {
                        name,
                        ext,
                        data: this.imageToData( physics.clearcoatNormalMap.image, ext )
                    });

                    this.mtlOutput += 'map_Pccnm ' + name + '.png' + '\n';

                } else {

                    this.mtlOutput += 'map_Pccnm ' + this.map_names[ physics.clearcoatNormalMap.uuid ] + '.png' + '\n';

                }
            }

            if ( physics.clearcoatRoughnessMap && physics.clearcoatRoughnessMap.type === 1009 && physics.clearcoatRoughnessMap.image ) {
                if ( this.map_uuids.includes( physics.clearcoatRoughnessMap.uuid ) === false ) {

                    name = 'clearcoatRoughnessMap' + this.mtlCount;

                    this.map_uuids.push( physics.clearcoatRoughnessMap.uuid );
                    this.map_names[ physics.clearcoatRoughnessMap.uuid ] = name;

                    this.textures.push( {
                        name,
                        ext,
                        data: this.imageToData( physics.clearcoatRoughnessMap.image, ext )
                    });

                    this.mtlOutput += 'map_Pccrm ' + name + '.png' + '\n';

                } else {

                    this.mtlOutput += 'map_Pccrm ' + this.map_names[ physics.clearcoatRoughnessMap.uuid ] + '.png' + '\n';

                }
            }

            if ( physics.sheenColorMap && physics.sheenColorMap.type === 1009 && physics.sheenColorMap.image ) {
                if ( this.map_uuids.includes( physics.sheenColorMap.uuid ) === false ) {

                    name = 'sheenColorMap' + this.mtlCount;

                    this.map_uuids.push( physics.sheenColorMap.uuid );
                    this.map_names[ physics.sheenColorMap.uuid ] = name;

                    this.textures.push( {
                        name,
                        ext,
                        data: this.imageToData( physics.sheenColorMap.image, ext )
                    });

                    this.mtlOutput += 'map_Pshcm ' + name + '.png' + '\n';

                } else {

                    this.mtlOutput += 'map_Pshcm ' + this.map_names[ physics.sheenColorMap.uuid ] + '.png' + '\n';

                }
            }

            if ( physics.sheenRoughnessMap && physics.sheenRoughnessMap.type === 1009 && physics.sheenRoughnessMap.image ) {
                if ( this.map_uuids.includes( physics.sheenRoughnessMap.uuid ) === false ) {

                    name = 'sheenRoughnessMap' + this.mtlCount;

                    this.map_uuids.push( physics.sheenRoughnessMap.uuid );
                    this.map_names[ physics.sheenRoughnessMap.uuid ] = name;

                    this.textures.push( {
                        name,
                        ext,
                        data: this.imageToData( physics.sheenRoughnessMap.image, ext )
                    });

                    this.mtlOutput += 'map_Pshrm ' + name + '.png' + '\n';

                } else {

                    this.mtlOutput += 'map_Pshrm ' + this.map_names[ physics.sheenRoughnessMap.uuid ] + '.png' + '\n';

                }
            }

            if ( physics.specularIntensityMap && physics.specularIntensityMap.type === 1009 && physics.specularIntensityMap.image ) {
                if ( this.map_uuids.includes( physics.specularIntensityMap.uuid ) === false ) {

                    name = 'specularIntensityMap' + this.mtlCount;

                    this.map_uuids.push( physics.specularIntensityMap.uuid );
                    this.map_names[ physics.specularIntensityMap.uuid ] = name;

                    this.textures.push( {
                        name,
                        ext,
                        data: this.imageToData( physics.specularIntensityMap.image, ext )
                    });

                    this.mtlOutput += 'map_Psim ' + name + '.png' + '\n';

                } else {

                    this.mtlOutput += 'map_Psim ' + this.map_names[ physics.specularIntensityMap.uuid ] + '.png' + '\n';

                }
            }

            if ( physics.specularColorMap && physics.specularColorMap.type === 1009 && physics.specularColorMap.image ) {
                if ( this.map_uuids.includes( physics.specularColorMap.uuid ) === false ) {

                    name = 'specularColorMap' + this.mtlCount;

                    this.map_uuids.push( physics.specularColorMap.uuid );
                    this.map_names[ physics.specularColorMap.uuid ] = name;

                    this.textures.push( {
                        name,
                        ext,
                        data: this.imageToData( physics.specularColorMap.image, ext )
                    });

                    this.mtlOutput += 'map_Pscm ' + name + '.png' + '\n';

                } else {

                    this.mtlOutput += 'map_Pscm ' + this.map_names[ physics.specularColorMap.uuid ] + '.png' + '\n';

                }
            }

            if ( physics.thicknessMap && physics.thicknessMap.type === 1009 && physics.thicknessMap.image ) {
                if ( this.map_uuids.includes( physics.thicknessMap.uuid ) === false ) {

                    name = 'thicknessMap' + this.mtlCount;

                    this.map_uuids.push( physics.thicknessMap.uuid );
                    this.map_names[ physics.thicknessMap.uuid ] = name;

                    this.textures.push( {
                        name,
                        ext,
                        data: this.imageToData( physics.thicknessMap.image, ext )
                    });

                    this.mtlOutput += 'map_Pthm ' + name + '.png' + '\n';

                } else {

                    this.mtlOutput += 'map_Pthm ' + this.map_names[ physics.thicknessMap.uuid ] + '.png' + '\n';

                }
            }

            if ( physics.transmissionMap && physics.transmissionMap.type === 1009 && physics.transmissionMap.image ) {
                if ( this.map_uuids.includes( physics.transmissionMap.uuid ) === false ) {

                    name = 'transmissionMap' + this.mtlCount;

                    this.map_uuids.push( physics.transmissionMap.uuid );
                    this.map_names[ physics.transmissionMap.uuid ] = name;

                    this.textures.push( {
                        name,
                        ext,
                        data: this.imageToData( physics.transmissionMap.image, ext )
                    });

                    this.mtlOutput += 'map_Ptrm ' + name + '.png' + '\n';

                } else {

                    this.mtlOutput += 'map_Ptrm ' + this.map_names[ physics.transmissionMap.uuid ] + '.png' + '\n';

                }
            }

            this.mtlCount += 1;

        }

    }

    // the following functions were adopted from ColladaExporter.js

    imageToData( image: HTMLImageElement | HTMLCanvasElement, ext: string ) {
        if (!this.canvas) {
            this.canvas = document.createElement("canvas");
        }

        const ctx = this.canvas.getContext('2d');
        this.canvas.width = image.width;
        this.canvas.height = image.height;

        // this seems to work fine for exporting TGA images as PNG
        // if ( image.data && image.data.constructor === Uint8Array ) {

        //     let imgData = ctx.createImageData( image.width, image.height );

        //     for (let i = 0; i < imgData.data.length; i += 4) {

        //         imgData.data[ i + 0 ] = image.data[ i + 0 ];
        //             imgData.data[ i + 1 ] = image.data[ i + 1 ];
        //             imgData.data[ i + 2 ] = image.data[ i + 2 ];
        //             imgData.data[ i + 3 ] = image.data[ i + 3 ];

        //     }

        //     ctx.putImageData( imgData, 0, 0 );

        // } else {

            ctx!.drawImage( image, 0, 0 );

        // }

        // Get the base64 encoded data
        const base64data = this.canvas.toDataURL( `image/${ext}`, 1 ).replace( /^data:image\/(png|jpg);base64,/, '' );

        // Convert to a uint8 array
        return this.base64ToBuffer( base64data );
    }

    base64ToBuffer( str: string ) {
        const b = atob( str );
        const buf = new Uint8Array( b.length );

        for ( let i = 0, l = buf.length; i < l; i ++ ) {
            buf[ i ] = b.charCodeAt( i );
        }

        return buf;
    }
}
