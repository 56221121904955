type EventHandler = (...args: any[]) => void;

export default class EventEmitter {
    private events: Record<string, EventHandler[]> = {};
    private onceEvents: Record<string, EventHandler[]> = {};

    on(eventName: string, handler: EventHandler): void {
        if (!this.events[eventName]) {
            this.events[eventName] = [];
        }
        this.events[eventName].push(handler);
    }

    once(eventName: string, handler: EventHandler): void {
        if (!this.onceEvents[eventName]) { 
            this.onceEvents[eventName] = []; 
        }
        this.onceEvents[eventName].push(handler);
    }

    off(eventName: string, handler: EventHandler): void {
        const handlers = this.events[eventName];
        if (handlers) {
            this.events[eventName] = handlers.filter(existingHandler => existingHandler !== handler);
        }
        const onceHandlers = this.onceEvents[eventName];
        if (onceHandlers) {
            this.onceEvents[eventName] = onceHandlers.filter(existingHandler => existingHandler !== handler);
        }
    }

    emit(eventName: string, ...args: any[]): void {
        const handlers = this.events[eventName];
        if (handlers) {
            handlers.forEach(handler => handler(...args));
        }
        const onceHandlers = this.onceEvents[eventName];
        if (onceHandlers) {
            onceHandlers.forEach(handler => handler(...args));
            this.onceEvents[eventName] = [];
        }
    }
}