import EventEmitter from "./EventEmitter";
import { emptyModel, ModelInterface, Models } from "./Types";

export default class ModelManager extends EventEmitter {
    static _instance?: ModelManager;

    static instance(){
        if (!this._instance) {
            this._instance = new ModelManager();
        }
        return this._instance;
    }

    serverUrl: string = '';

    isBusy: boolean = false;
    isError: boolean = false;

    models: ModelInterface[] = [];
    rawModels: ModelInterface[] = [];

    getModelById(id: number) {
        const model = this.models.filter(model => model.id === id)[0];
        return model ? model : null;
    }
    
    getRawModelById(id: number) {
        const model = this.rawModels.filter(model => model.id === id)[0];
        return model ? model : null;
    }

    setServer(url: string){
        this.serverUrl = url;
        this.reload();
    }

    patchUrl(url: string) {
        if (url.startsWith('./')) {
            return this.serverUrl + url.slice(2);
        }
        return url;
    }

    async loadById(id: number){
        if (this.serverUrl === '') { return null;}
        const url = `${this.serverUrl}${id}`;

        try {
            const response = await fetch(url);

            if (!response.ok) {
                console.error(`Failed to retrieve response from: ${url}. Status: ${response.status}`);
                return null;
            }

            const item: ModelInterface = await response.json();
            const model: ModelInterface = {
                id: item.id,
                name: item.name,
                preview: item.preview && this.patchUrl(item.preview),
                /** Change URL to absolute for the textures */
                textures: item.textures.map((texture)=>({
                    name: texture.name,
                    url: this.patchUrl(texture.url)
                })),
                /** Change URL to absolute for the model file */
                url: this.patchUrl(item.url),
                /** Change URL to absolute for the overlay images */
                overlays: item.overlays?.map((overlay)=>({
                    name: overlay.name,
                    url: this.patchUrl(overlay.url)
                })),
                parts: item.parts
            };
            
            return model;
        } catch (error) {
            console.error("Failed to fetch", error);
            return null;
        }
    }

    /** Reload models list from server */
    async reload(){
        this.models = [];
        if (this.serverUrl === '') { return ;}
        this.emit("reload-start");
        this.isBusy = true;
        this.isError = false;
        const url = `${this.serverUrl}list`;
        try {
            const response = await fetch(url);

            if (!response.ok) {
                console.error(`Failed to retrieve response from: ${url}. Status: ${response.status}`);

                this.isError = true;
                this.isBusy = false;
                this.emit("reload-finish");

                return;
            }

            const list: Models = await response.json();

            this.rawModels = list.models;

            /** Parse response */
            this.models = list.models.map((item) => ({
                id: item.id,
                name: item.name,
                preview: item.preview && this.patchUrl(item.preview),
                /** Change URL to absolute for the textures */
                textures: item.textures.map((texture)=>({
                    name: texture.name,
                    url: this.patchUrl(texture.url)
                })),
                /** Change URL to absolute for the model file */
                url: this.patchUrl(item.url),
                /** Change URL to absolute for the overlay images */
                overlays: item.overlays?.map((overlay)=>({
                    name: overlay.name,
                    url: this.patchUrl(overlay.url)
                })),
                parts: item.parts
            }))
        } catch (error) {
            this.isError = true;
            console.error("Failed to fetch", error);
        }
        this.isBusy = false;
        this.emit("reload-finish");
    }

    async deleteModel(id: number){
        const token = localStorage.getItem('token');
        if (!token) { return; }
        const url = `${this.serverUrl}admin/models/${id}`;
        this.emit('reload-start');
        const response = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const message = await response.json();

        if (response.ok) {
            console.log(message.message);
            this.models = this.models.filter(model => model.id !== id);
        } else {
            console.log(message.error);
        }
        this.emit('reload-finish');
    }
}