import React from 'react';
import { XSquare } from 'react-feather';
import styles from './Popup.module.css';

interface PopupProps {
    onClose: () => void;
    show: boolean;
    children: React.ReactNode;
}

class Popup extends React.Component<PopupProps> {

    onKeyDown = (event: KeyboardEvent) => {
        if (!this.props.show) { return; }

        if (event.key === 'Escape') {
            this.props.onClose();
        }
    };

    componentDidMount(): void {
        document.addEventListener("keydown", this.onKeyDown);
    }
    
    componentWillUnmount(): void {
        document.removeEventListener("keydown", this.onKeyDown);
    }


    render() {
        const { onClose, show, children } = this.props;
        const showHideClassName = `${styles.modal} ${show ? styles.display : styles.hidden}`;

        return (
            <div className={showHideClassName}>
                <section className={styles.main}>
                    {/* <button 
                        className={styles.close}
                        onClick={onClose}
                        >Close</button> */}
                        <div className={styles.close}
                            onClick={onClose}>
                            <XSquare width={26} height={26}/>
                        </div>
                    {children}
                </section>
            </div>
        );
    }
}

export default Popup;