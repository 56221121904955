import { TextStyleConfig } from "../2d/TextStyle";
import ClippingCanvas from "../canvas/ClippingCanvas";
import DrawableElement from "../canvas/DrawableElement";
import EventEmitter from "./EventEmitter";
import { PartInterface } from "./Types";

export default class UndoRedo extends EventEmitter {
    history: State[][] = [];
    position: number = -1;

    parts?: PartInterface[];
    canvas: ClippingCanvas;

    constructor(canvas: ClippingCanvas, parts?: PartInterface[]){
        super();

        this.canvas = canvas;
        this.parts = parts;
    }

    canUndo(){
        return (this.position > 0);
    }

    canRedo(){
        return (this.position < this.history.length - 1);
    }

    undo(){
        if (this.position > 0) {
            this.position--;
            // this.restore(this.history[this.position], this.history[this.position - 1], this.history[this.position + 1]);
            this.restore(this.history[this.position]);
        }
    }
    
    redo(){
        if (this.position < this.history.length - 1) {
            this.position++;
            this.restore(this.history[this.position]);
            // this.restore(this.history[this.position], this.history[this.position - 1], this.history[this.position + 1]);
        }
    }

    save(contentOf?: DrawableElement<HTMLElement>){
        this.position++;
        if (this.position < this.history.length) {
            this.history.splice(this.position);
        }
        if (this.position > 20) {
            this.position--;
            this.history.shift();
        }

        const states: State[] = [];
        let removed = !!contentOf;

        this.canvas.layers.forEach((layer) => {
            const state: State = {
                drawable: layer,
                name: layer.name ?? 'Layer',
                index: this.canvas.layers.indexOf(layer),
                x: layer.x,
                y: layer.y,
                width: layer.width,
                height: layer.height,
                originWidth: layer.originWidth,
                originHeight: layer.originHeight,
                rotation: layer.rotation,
                alpha: layer.alpha,
                part: layer.pathId
            };

            if (layer === contentOf) {
                removed = false;
                if (layer.isCanvas()) {
                    state.data = (layer.element as HTMLCanvasElement).toDataURL();
                } else if (layer.isImage()) {
                    state.src = (layer.element as HTMLImageElement).src;
                } else if (layer.isText()) {
                    state.text = layer.text!;
                    state.textStyle = layer.textStyle!.config()
                }
            } else {
                const prev = this.history[this.position - 1]?.find((state) => state.drawable === layer);
                if (prev) {
                    state.data = prev.data;
                    state.src = prev.src;
                    state.text = prev.text;
                    state.textStyle = prev.textStyle;
                }
            }

            states.push(state);
        });
        
        if (removed && this.history[this.position - 1]) {
            const state = this.history[this.position - 1].find((state) => state.drawable === contentOf);
            if (!state) { console.log("WTF") }
            else {
                if (contentOf!.isCanvas()) {
                    state.data = (contentOf!.element as HTMLCanvasElement).toDataURL();
                } else if (contentOf!.isImage()) {
                    state.src = (contentOf!.element as HTMLImageElement).src;
                } else if (contentOf!.isText()) {
                    state.text = contentOf!.text!;
                    state.textStyle = contentOf!.textStyle!.config()
                }
            }
        }

        this.history.push(states);
        // console.log(this.position, this.history);
    }

    restore(states: State[]) {
        for (let i = 0; i < this.canvas.layers.length; ++i) {
            const layer = this.canvas.layers[i];
            const has = states.findIndex((state) => state.drawable === layer);
            if (has === -1) {
                this.canvas.removeLayer(i);
                i--;
            }
        }
        states.forEach((state) => {
            this.restoreState(state);
        });
        this.emit("restore");
    }

    restoreState(state: State, prev?: State, next?: State){
        if (state.data) {
            const canvas = state.drawable.element as HTMLCanvasElement;
            canvas.width = state.originWidth;
            canvas.height = state.originHeight;

            const image = new Image();
            image.onload = ()=>{
                const context = state.drawable.getContext();
                context?.clearRect(0, 0, canvas.width, canvas.height);
                context?.drawImage(image, 0, 0);
                this.canvas.update();
            }
            image.src = state.data;
        } else if (state.src) {

        } else if (state.text || state.textStyle) {
            state.drawable.textStyle?.update(state.textStyle!);
            state.drawable.setText(state.text!);
        }

        state.drawable.name = state.name;
        state.drawable.visible = true;
        state.drawable.x = state.x;
        state.drawable.y = state.y;
        state.drawable.width = state.width;
        state.drawable.height = state.height;
        state.drawable.rotation = state.rotation;
        state.drawable.alpha = state.alpha;

        state.drawable.setPath(state.part, this.parts?.[state.part]?.path);

        const index = this.canvas.layers.indexOf(state.drawable);
        let needMove = true;
        if (index === -1) {
            this.canvas.restoreLayer(state.drawable, state.index);
            needMove = false;
        }
        
        if (state.index !== index && state.index !== -1 && index !== -1) {
            if (needMove) { this.canvas.moveTo(index, state.index); }
        }
    }
}

interface State {
    drawable: DrawableElement<HTMLElement>;
    index: number;
    name: string;
    created?: boolean;
    removed?: number;
    data?: string;
    src?: string;
    text?: string;
    textStyle?: TextStyleConfig;
    x: number;
    y: number;
    width: number;
    height: number;
    originWidth: number;
    originHeight: number;
    rotation: number;
    alpha: number;
    part: number;
};
